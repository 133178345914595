import React, { useEffect } from "react";
import PropTypes from "prop-types";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Backdrop from "@mui/material/Backdrop";

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary" sx={{
          fontWeight: 600,
          fontSize: "0.9rem",
          color: "#FFFFFF",
        }}>
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};

export default function ProgressBackdrop({
  backdropopen,
  progressPercentage,
  backdropmessage
}) {

  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 2 }}
      open={backdropopen}
    >

      <Box display="flex" flexDirection="column" alignItems="center">
        {/* <CircularProgress color="inherit" /> */}
        <CircularProgressWithLabel value={progressPercentage} color="inherit"
        />
        <Typography
          variant="h5" // Increase the font size
          sx={{
            mt: 2,
            fontWeight: "bold", // Make the font bold
            textShadow: "2px 2px 4px rgba(0, 0, 0, 0.15)", // Add shadow for more prominence
            color: "#FFFFFF", // Use a different color for better contrast
          }}
        >
          {/* {Loading, please wait...} */}
          {backdropmessage ? backdropmessage : "Loading, please wait..."}
        </Typography>
      </Box>
    </Backdrop>
  );
}
