import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  Divider,
} from "@mui/material";
import React, { useState } from "react";

const ImportDialog = ({
  dialogopen,
  setDialogopen,
  importData,
  setimportData,
}) => {
  const [csvdata, setcsvData] = useState([]);
  const [finalSubmission, setfinalSubmission] = useState(false);

  const cleanData = (row) => {
    // Define a regular expression to match text within double quotes
    const regex = /"([^"]*)"/g;

    // Array to hold the matched substrings
    const matches = [];

    // Replace the matched substrings with placeholders and store the matches
    const modifiedString = row.replace(regex, (match, group) => {
      matches.push(group);
      return `{{${matches.length - 1}}}`;
    });

    // Split the modified string by commas
    const splitData = modifiedString.split(",");

    // Replace the placeholders with the original matched substrings
    const result = splitData.map((item) => {
      if (item.startsWith("{{") && item.endsWith("}}")) {
        const index = parseInt(item.slice(2, -2));
        return matches[index];
      }
      return item;
    });

    return result;
  };

  const onhandleAddDataClick = () => {
    setfinalSubmission(true);
  };

  const handlefinalSubmission = () => {
    const csvDatalines = csvdata.split("\n");

    const temp = [];
    csvDatalines.map((row) => {
      temp.push(cleanData(row));
    });

    const keys = temp[0];

    const values = temp.slice(1);

    // Remove empty arrays and header row
    const validData = values.filter((row) => row.length > 1);

    // Create an object from the array
    const res = validData.map((subArray) => {
      const obj = {};
      keys.forEach((key, index) => {
        // Validate if value is present and assign it
        if (subArray[index]) {
          obj[key] = subArray[index];
        } else {
          // If value is missing, assign an empty string or perform other validation
          obj[key] = ""; // Default value
        }
      });
      return obj;
    });

    setimportData(res);

    setDialogopen(false);
    setfinalSubmission(false);
  };

  const onhandlechange = (event) => {
    setcsvData(event.target.value);
  };

  return (
    <React.Fragment>
      <Dialog
        fullWidth={true}
        maxWidth={"lg"}
        scroll="paper"
        onClose={() => setDialogopen(false)}
        open={dialogopen}
      >
        <DialogTitle>Import Manual Feed Data</DialogTitle>
        <Divider />

        <DialogContent>
          <DialogContentText>
            Paste your csv file content here
          </DialogContentText>
          <br />
          <TextField
            autoFocus
            variant="outlined"
            required
            placeholder="Please enter your data here"
            margin="dense"
            id="csv-data"
            fullWidth
            minRows={5}
            multiline
            disabled={finalSubmission}
            onChange={onhandlechange}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            sx={{ color: "white", backgroundColor: "#a9a7a7" }}
            onClick={() => {
              setDialogopen(false);
              setfinalSubmission(false);
            }}
          >
            Cancel
          </Button>
          {/* Double confirmation */}

          {finalSubmission ? (
            <Button
              variant="contained"
              className="primary"
              onClick={handlefinalSubmission}
            >
              Final Submit
            </Button>
          ) : (
            <Button
              variant="contained"
              className="primary"
              onClick={onhandleAddDataClick}
            >
              Add data
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default ImportDialog;
