import React from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  CardHeader,
  Chip,
  CardMedia,
  TableContainer,
  TableBody,
  TableCell,
  Table,
  TableRow,
} from "@mui/material";
import noevent from "../../assets/images/noevent.png";

const SpecialStatusTable = ({ event }) => {
  const rows = [
    { label: "Principle", value: event.principleCount },
    { label: "Operations", value: event.operationsCount },
    { label: "Site Lead", value: event.siteLeadCount },
    { label: "OperationsVVM", value: event.operationsVVMCount },
    { label: "Exec Prot", value: event.execProtCount },
    { label: "Hospitality", value: event.hospitalityCount },
    { label: "Tracked Phone", value: event.trackedPhoneCount },
    { label: "GSS", value: event.gssCount },
  ];

  // Split rows into chunks of 3
  const chunkedRows = [];
  for (let i = 0; i < rows.length; i += 2) {
    chunkedRows.push(rows.slice(i, i + 2));
  }

  return (
    <>
      <Grid item sx={{ padding: 0, margin: 0 }}>
        <TableContainer sx={{ padding: 0, margin: 0 }}>
          <Table
            sx={{
              "& .MuiTableCell-root": { borderBottom: "none" },
              width: "100%",
              padding: 0,
              margin: 0,
            }}
            size="small"
          >
            <TableBody>
              {chunkedRows.map((chunk, index) => (
                <TableRow key={index}>
                  {chunk.map((row, subIndex) => (
                    <TableCell key={subIndex} sx={{ padding: "8px" }}>
                      <Typography>
                        {row.label}: {row.value}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </>
  );
};

const StatusTable = ({ event }) => {
  const rows = [
    { label: "Client", value: event.clientCount },
    { label: "Consumer", value: event.consumerCount },
    { label: "Staff", value: event.staffCount },
    { label: "Contractor", value: event.contractorCount },
    { label: "Employee", value: event.employeeCount },
    { label: "Unknown", value: event.unknownCount },
  ];

  // Split rows into chunks of 3
  const chunkedRows = [];
  for (let i = 0; i < rows.length; i += 3) {
    chunkedRows.push(rows.slice(i, i + 3));
  }

  return (
    <>
      <Grid item sx={{ padding: 0, margin: 0 }}>
        <TableContainer sx={{ padding: 0, margin: 0 }}>
          <Table
            sx={{
              "& .MuiTableCell-root": { borderBottom: "none" },
              width: "100%",
              padding: 0,
              margin: 0,
            }}
            size="small"
          >
            <TableBody>
              {chunkedRows.map((chunk, index) => (
                <TableRow key={index}>
                  {chunk.map((row, subIndex) => (
                    <TableCell key={subIndex} sx={{ padding: "8px" }}>
                      <Typography>
                        {row.label}: {row.value}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </>
  );
};

const EventScheduleStack = ({ events, label }) => {
  const { text, textcolor, bgColor } = label;

  return (
    <>
      {events.length <= 0 ? (
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Card sx={{ minWidth: 300 }} elevation={0}>
            <CardMedia
              component="img"
              sx={{ height: 150, width: "auto", margin: "0 auto" }}
              image={noevent}
              alt="No event"
            />
            <CardContent sx={{ textAlign: "center" }}>
              <Typography fontSize={12}>
                {text === "Current"
                  ? "No current events"
                  : text === "Upcoming"
                  ? "No upcoming events"
                  : "No past events"}
              </Typography>
              <Typography fontSize={12}>
                Select different date/time to view {text} event
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ) : (
        events.map((event, index) => {
          return (
            <Grid
              key={index}
              item
              xs={12}
              sm={12}
              md={6}
              lg={4}
              xl={3}
              // spacing={2}
            >
              <Card
                elevation={2}
                key={index}
                sx={{
                  minWidth: 300,
                  margin: "0px",
                  padding: "0px",
                }}
              >
                <CardHeader
                  sx={{ margin: "0px", padding: "10px" }}
                  action={
                    <Chip
                      label={text}
                      sx={{
                        backgroundColor: bgColor,
                        color: textcolor,
                      }}
                    />
                  }
                />
                <CardContent sx={{ margin: "0px", padding: "5px" }}>
                  <Grid container direction={"column"}>
                    <Grid item flexWrap={"nowrap"}>
                      <Typography fontWeight={"bold"}>
                        Event Name: {event.eventName ? event.eventName : "NA"}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography>StartDate: {event.startDateTime}</Typography>
                      <Typography>EndDate: {event.endDateTime}</Typography>
                    </Grid>
                    <Grid item>
                      <Typography>
                        Location:{" "}
                        {event.locationName ? event.locationName : "NA"}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography>
                        Total No. of People:{" "}
                        {parseInt(event.clientCount, 10) +
                          parseInt(event.consumerCount, 10) +
                          parseInt(event.employeeCount, 10) +
                          parseInt(event.contractorCount, 10) +
                          parseInt(event.unknownCount, 10) +
                          parseInt(event.staffCount, 10)}
                      </Typography>
                    </Grid>
                    <StatusTable event={event} />

                    <SpecialStatusTable event={event} />
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          );
        })
      )}
    </>
  );
};

export default EventScheduleStack;
