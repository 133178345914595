import { Box, Grid, Stack } from "@mui/material";
import React, { useState } from "react";
import loginBg from "../assets/images/loginBg.png";
import logo from "../assets/images/logo.png";
import poweredByFractalIcon from "../assets/images/PoweredbyfractalLoginIcon.png";

const UserAuthentication = ({
  loginComponent: LoginComponent,
  signupComponent: SignupComponent,
  forgotPasswordComponent: ForgotPasswordComponent,
}) => {
  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      sx={{
        backgroundImage: `url(${loginBg})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        height: "100%",
        width: "100%",
        display: "flex",
      }}
    >
      {/* Website - LOGO  */}
      <Grid item sx={{ width: "100%", height: "10%" }}>
        <Stack direction="row" sx={{ justifyContent: "flex-start" }}>
          <Box sx={{ margin: "5px", padding: "20px" }}>
            <img src={logo} alt="Logo" />
          </Box>
        </Stack>
      </Grid>

      {/* Login Component */}
      <Grid
        item
        sx={{
          height: "75%",
          width: "100%",
          display: "flex",
          alignContent: "center",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          autoComplete="off"
          component="form"
          sx={{
            width: "500px",
            height: "540px",
            backgroundColor: "white",
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {LoginComponent && <LoginComponent />}
          {SignupComponent && <SignupComponent />}
          {ForgotPasswordComponent && <ForgotPasswordComponent />}
        </Box>
      </Grid>

      {/* powered by fractal */}
      <Grid item sx={{ width: "100%", height: "10%" }}>
        <Stack direction="row" sx={{ justifyContent: "flex-end" }}>
          <Box sx={{ margin: "5px", padding: "20px" }}>
            <img src={poweredByFractalIcon} alt="poweredByFractalIcon" />
          </Box>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default UserAuthentication;
