import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import SubHeaderFilter from "../components/common/SubHeaderFilter/SubHeaderFilter";
import GoogleMapView from "../components/Overview/GoogleMapView";
import FilterDrawer from "../components/Overview/FilterDrawer";
import TabularView from "../components/Overview/TabularView";
import MenuIcon from "@mui/icons-material/Menu";
import { useDispatch } from "react-redux";
import { setParameters } from "../redux/parametersSlice";
import { setParameters2 } from "../redux/parameters2Slice";
import { useSelector } from "react-redux";
import {
  Tab,
  Tabs,
  Typography,
  Box,
  Grid,
  Stack,
  Avatar,
  Snackbar,
  Alert,
} from "@mui/material";
import { useLoadScript } from "@react-google-maps/api";
import eventMapIcon from "../assets/images/eventMapIcon.png";
import hotelMapIcon from "../assets/images/hotelMapIcon.png";
import {
  fetchOverviewGMapOnloadData,
  fetchOverviewTabularDataByParameters,
  fetchOverviewGMapDataByParameters,
  fetchEventTypeFilters,
  fetchFirstTimeOverviewTabularDataByParameters,
} from "../utils/parentservices";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}

const libraries = ["places", "drawing", "geometry"];

const Overview = () => {
  const dispatch = useDispatch();
  const [tabindex, setTabIndex] = useState(0);
  const [isHeaderFilterDateChange, setIsHeaderFilterDateChange] =
    useState(false);
  const [openRightDrawer, setOpenRightDrawer] = useState(false);

  // mapRef for zooming and panning map
  const [mapRef, setMapRef] = React.useState();

  const [tabularData, setTabularData] = useState([]);
  const [gMapMarkerList, setGMapMarkerList] = useState([]);
  const [autocomplete, setAutocomplete] = React.useState(null);
  const [markersClickedSummary, setmarkersClickedSummary] = useState([]);

  const [snackOpen, setsnackOpen] = useState(false);
  const [snackMessage, setsnackMessage] = useState({
    message: "",
    severity: "success",
  });

  const [isPagechanged, setispagechanged] = useState(false);
  const [progressPercentage, setProgressPercentage] = useState(0);

  const [filterParameters, setFilterParameters] = useState({
    radiusvalue: 0,
    eventlocationChecked: false,
    hotellocationChecked: false,
    eventTypeValue: [],
    profileValue: [],
    startDate: null,
    endDate: null,
    startTime: null,
    endTime: null,
    location: [],
    isAllDay: false,
    lastfreshDate: null,
    lastfreshTime: "21:30 IST",
    gmapLocation: null,
    lat: [],
    lng: [],
    prId: [],
  });
  const [backdropopen, setbackdropopen] = useState(false);
  const reduxParameter = useSelector((state) => state.parameters);
  const [isAutoRefresh, setIsAutoRefresh] = useState(true);
  const [eventTypeList, seteventTypeList] = useState([]);

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setsnackOpen(false);
  };

  useEffect(() => {
    const getData = async () => {
      setbackdropopen(true);
      const token = localStorage.getItem("idToken");
      const resp = await fetchEventTypeFilters(token);
      let lastRefreshObj = null;
      if (
        resp?.data?._result &&
        resp?.data?._code === 200 &&
        !resp?.data?._isError
      ) {
        const { eventList, refreshLogsDetails } = resp?.data?._result;
        if (refreshLogsDetails && refreshLogsDetails.length > 0) {
          lastRefreshObj = refreshLogsDetails[0];
          // setFilterParameters({
          //   ...filterParameters,
          //   lastfreshDate: lastRefreshObj.lastRefreshDateTime,
          // });
          setIsAutoRefresh(lastRefreshObj.isAutoRefresh === 1 ? true : false);
          filterParameters.lastfreshDate = lastRefreshObj.lastRefreshDateTime;
        }

        seteventTypeList(eventList?.map((item) => item.label));
        setFilterParameters({ ...filterParameters });
        // Get current date and time
        const currentDateObj = new Date();
        // Add 30 minutes
        const dateTimeAfterObj = new Date(
          currentDateObj.getTime() + 30 * 60 * 1000
        );

        // Subtract 30 minutes
        const dateTimeBeforeObj = new Date(
          currentDateObj.getTime() - 30 * 60 * 1000
        );

        // Format dates and times
        const formatDate = (dateObj) => {
          const year = dateObj.getFullYear();
          const month = String(dateObj.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
          const day = String(dateObj.getDate()).padStart(2, "0");
          const hours = String(dateObj.getHours()).padStart(2, "0");
          const minutes = String(dateObj.getMinutes()).padStart(2, "0");
          const seconds = String(dateObj.getSeconds()).padStart(2, "0");

          return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        };

        let parameters = null;
        // console.log("reduxParameter", reduxParameter);
        if (reduxParameter) {
          setFilterParameters({
            ...filterParameters,
            startDate: reduxParameter.startDate,
            endDate: reduxParameter.endDate,
            startTime: reduxParameter.startTime,
            endTime: reduxParameter.endTime,
            lastfreshDate: lastRefreshObj.lastRefreshDateTime
              ? lastRefreshObj.lastRefreshDateTime
              : reduxParameter.lastfreshDate,
            isAllDay: reduxParameter.isAllDay,
          });
          parameters = {
            ...filterParameters,
          };
          parameters = {
            ...parameters,
            startDate: reduxParameter.startDate,
            endDate: reduxParameter.endDate,
            startTime: reduxParameter.startTime,
            endTime: reduxParameter.endTime,
            lastfreshDate: lastRefreshObj.lastRefreshDateTime
              ? lastRefreshObj.lastRefreshDateTime
              : formatDate(currentDateObj),
            isAllDay: reduxParameter.isAllDay,
          };
          //console.log("Inside IF reduxParameter", reduxParameter);
        } else {
          setFilterParameters({
            ...filterParameters,
            startDate: formatDate(currentDateObj),
            endDate: formatDate(currentDateObj),
            startTime: formatDate(dateTimeBeforeObj),
            endTime: formatDate(dateTimeAfterObj),
            lastfreshDate: lastRefreshObj.lastRefreshDateTime
              ? lastRefreshObj.lastRefreshDateTime
              : formatDate(currentDateObj),
            isAllDay: false,
          });
          parameters = {
            ...filterParameters,
          };
          parameters = {
            ...parameters,
            startDate: formatDate(currentDateObj),
            endDate: formatDate(currentDateObj),
            startTime: formatDate(dateTimeBeforeObj),
            endTime: formatDate(dateTimeAfterObj),
            lastfreshDate: lastRefreshObj.lastRefreshDateTime
              ? lastRefreshObj.lastRefreshDateTime
              : formatDate(currentDateObj),
            isAllDay: false,
          };
        }

        dispatch(setParameters({ ...parameters }));
        dispatch(setParameters2({ ...parameters }));

        // Fixed here for destroy error :- We can't call async directoly on useEffect
        const fetchOverViewData = async () => {
          await fetchOverviewGMapOnloadDataByParameters(parameters, "onLoad");
        };

        fetchOverViewData();
      }
    };
    getData();
  }, []);

  const onApplyButtonClick = async (dataFromChild) => {
    let parameters = {
      ...filterParameters,
    };
    parameters = {
      ...parameters,
      radiusvalue: dataFromChild.radiusvalue,
      eventlocationChecked: dataFromChild.eventlocationChecked,
      hotellocationChecked: dataFromChild.hotellocationChecked,
      eventTypeValue: dataFromChild.eventTypeValue,
      profileValue: dataFromChild.profileValue,
    };
    setFilterParameters({
      ...filterParameters,
      radiusvalue: dataFromChild.radiusvalue,
      eventlocationChecked: dataFromChild.eventlocationChecked,
      hotellocationChecked: dataFromChild.hotellocationChecked,
      eventTypeValue: dataFromChild.eventTypeValue,
      profileValue: dataFromChild.profileValue,
    });
    await fetchOverviewDataByFilters(parameters);
  };
  const onResetButtonClick = async () => {
    const updatedData = {
      radiusvalue: 0,
      eventlocationChecked: false,
      hotellocationChecked: false,
      eventTypeValue: [],
      profileValue: [],
      startDate: filterParameters.startDate,
      endDate: filterParameters.endDate,
      startTime: filterParameters.startTime,
      endTime: filterParameters.endTime,
      location: filterParameters.location,
      isAllDay: filterParameters.isAllDay,
      lastfreshDate: filterParameters.lastfreshDate,
      lastfreshTime: filterParameters.lastfreshTime,
      gmapLocation: null,
      lat: [],
      lng: [],
      prId: [],
    };
    setFilterParameters({ ...updatedData });
    // Get current date and time
    const currentDateObj = new Date();
    // Add 30 minutes
    const dateTimeAfterObj = new Date(
      currentDateObj.getTime() + 30 * 60 * 1000
    );

    // Subtract 30 minutes
    const dateTimeBeforeObj = new Date(
      currentDateObj.getTime() - 30 * 60 * 1000
    );

    // Format dates and times

    let parameters = {
      ...filterParameters,
    };
    parameters = {
      ...parameters,
      radiusvalue: 0,
      eventlocationChecked: false,
      hotellocationChecked: false,
      eventTypeValue: [],
      profileValue: [],
      gmapLocation: null,
      lat: [],
      lng: [],
    };

    dispatch(setParameters({ ...parameters }));
    dispatch(setParameters2({ ...parameters }));
    const filteredCustomMarkers = gMapMarkerList.filter(
      (item) => !item.isCustomSearch
    );
    setGMapMarkerList(filteredCustomMarkers);

    // Fixed here for destroy error :- We can't call async directoly on useEffect
    const fetchOverViewData = async () => {
      await fetchOverviewGMapOnloadDataByParameters(parameters, "reset");
    };
    fetchOverViewData();
  };

  const onDateSelectionChange = async (dataFromChild) => {
    let parameters = {
      ...filterParameters,
    };
    let flag = false;
    if (
      filterParameters.startDate === dataFromChild.startDate ||
      filterParameters.endDate === dataFromChild.endDate ||
      filterParameters.startTime === dataFromChild.startTime ||
      filterParameters.endTime === dataFromChild.endTime ||
      filterParameters.location === dataFromChild.location
    ) {
      parameters = {
        ...parameters,
        startDate: dataFromChild.startDate,
        endDate: dataFromChild.endDate,
        startTime: dataFromChild.startTime,
        endTime: dataFromChild.endTime,
        location: dataFromChild.location,
        isAllDay: dataFromChild.isAllDay,
      };
      setFilterParameters({
        ...filterParameters,
        startDate: dataFromChild.startDate,
        endDate: dataFromChild.endDate,
        startTime: dataFromChild.startTime,
        endTime: dataFromChild.endTime,
        location: dataFromChild.location,
        isAllDay: dataFromChild.isAllDay,
      });
      dispatch(setParameters({ ...parameters }));
      dispatch(setParameters2({ ...parameters }));
      flag = true;
      setIsHeaderFilterDateChange(true);
    }

    if (tabindex === 0) {
      if (flag) {
        await fetchOverviewDataByFilters(parameters);
      }
    } else {
      await fetchOverviewTabularData(parameters);
    }
  };

  // useEffect(() => {
  //   dispatch(setParameters({ ...filterParameters, ...markersClickedSummary }));
  // }, [markersClickedSummary]);

  const toggleDrawer = (newOpen) => () => {
    setOpenRightDrawer(newOpen);
  };

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyAaZkA6akAsX1LfqpWe3oKGUL7Wd9v9-d4",
    libraries: libraries,
  });

  const handleChange = async (event, newValue) => {
    setTabIndex(newValue);
    if (newValue === 1) {
      await fetchOverviewTabularData(filterParameters);
    } else if (newValue === 0 && isHeaderFilterDateChange === true) {
      setispagechanged(true);
      await fetchOverviewDataByFilters(filterParameters);
    }
    setIsHeaderFilterDateChange(false);
  };

  const setGmapData = async (gMapOnloadData, reset) => {
    let maplist = [];
    let icon_info = null;

    gMapOnloadData.map((obj) => {
      if (
        obj.locationTypeName === "Hotel" ||
        obj.locationTypeName === "Restaurant"
      ) {
        icon_info = {
          icon: hotelMapIcon, // "http://maps.google.com/mapfiles/ms/icons/purple-dot.png",
          color: "purple",
          radius_circle_options: {
            strokeColor: "#474B8D",
            fillColor: "#474B8D",
          },
        };
      }
      // else if (
      //   obj.locationTypeName === "Event Site" ||
      //   obj.locationTypeName === "Meeting Space"
      // )
      else {
        icon_info = {
          icon: eventMapIcon,
          color: "blue",
          radius_circle_options: {
            strokeColor: "#3D76FF",
            fillColor: "#3D76FF",
          },
        };
      }

      let totalPeople =
        parseInt(obj.clientCount, 10) +
        parseInt(obj.consumerCount, 10) +
        parseInt(obj.employeeCount, 10) +
        parseInt(obj.contractorCount, 10) +
        parseInt(obj.unknownCount, 10) +
        parseInt(obj.staffCount, 10);
      maplist.push({
        isCustomSearch: false,
        empID: obj.empID,
        prId: obj.Id,
        lat: parseFloat(obj.geoLocationLat),
        lng: parseFloat(obj.geoLocationLong),
        icon: icon_info,
        info: {
          locationTypeName: obj.locationTypeName,
          locationName: obj.locationName,
          eventTypeName: obj.eventTypeName,
          eventName: obj.eventName,
          totalPeople: totalPeople,

          peopleCount: [
            { Client: parseInt(obj.clientCount, 10) },
            { Consumer: parseInt(obj.consumerCount, 10) },
            { Employee: parseInt(obj.employeeCount, 10) },
            { Contractor: parseInt(obj.contractorCount, 10) },
            { Unknown: parseInt(obj.unknownCount, 10) },
            { Staff: parseInt(obj.staffCount, 10) },
            { Principle: parseInt(obj.principleCount, 10) },
            { Operations: parseInt(obj.operationsCount, 10) },
            { "Site Lead": parseInt(obj.siteLeadCount, 10) },
            { OperationsVVM: parseInt(obj.operationsVVMCount, 10) },
            { "Exec Prot": parseInt(obj.execProtCount, 10) },
            { Hospitality: parseInt(obj.hospitalityCount, 10) },
            { "Tracked Phone": parseInt(obj.trackedPhoneCount, 10) },
            { GSS: parseInt(obj.gssCount, 10) },
          ],
          endDateTime: obj.endDateTime,
          startDateTime: obj.startDateTime,
        },
        circle: {
          radius:
            totalPeople >= 50
              ? totalPeople >= 80
                ? totalPeople * 5
                : totalPeople * 7
              : totalPeople * 15,
          options: {
            strokeColor: icon_info.radius_circle_options.strokeColor, // Border color of the circle
            strokeOpacity: 0.8, // Border opacity
            strokeWeight: 2, // Border width
            fillColor: icon_info.radius_circle_options.fillColor, // Fill color of the circle
            fillOpacity: 0.5, // Fill opacity
          },
        },
      });
    });

    //
    if (reset !== "reset") {
      if (gMapMarkerList && gMapMarkerList.length > 0) {
        let customLoc = gMapMarkerList.filter((item) => {
          return item.isCustomSearch === true;
        });
        maplist = [...maplist, ...customLoc];
      }
    }

    setGMapMarkerList(maplist);
  };

  async function fetchOverviewDataByFilters(paramters) {
    setbackdropopen(true);
    try {
      const paramter = {
        inputParam: {
          ...paramters,
        },
      };
      const token = localStorage.getItem("idToken");
      const response = await fetchOverviewGMapDataByParameters(paramter, token);
      if (
        response?.data?._result &&
        response?.data?._code === 200 &&
        !response?.data?._isError
      ) {
        const { gMapData } = response?.data?._result;

        await setGmapData(gMapData, "filter");
      }
    } catch (error) {
      console.error("Error:", error);
      // Handle error as needed
    }
    setbackdropopen(false);
  }

  async function fetchOverviewGMapOnloadDataByParameters(paramters, reset) {
    setbackdropopen(true);
    try {
      const paramter = {
        inputParam: {
          ...paramters,
        },
      };
      const token = localStorage.getItem("idToken");

      // getData();
      const response = await fetchOverviewGMapOnloadData(paramter, token);

      if (
        response?.data?._result &&
        response?.data?._code === 200 &&
        !response?.data?._isError
      ) {
        const { gMapOnloadData } = response?.data?._result;
        await setGmapData(gMapOnloadData, reset);
      }
    } catch (error) {
      console.error("Error:", error);
      // Handle error as needed
    }
    setbackdropopen(false);
  }

  const handlePercentChange = (val) => {
    setProgressPercentage(val);
  };

  async function fetchOverviewTabularData(parameters) {
    try {
      let lat = [];
      let lng = [];
      markersClickedSummary.forEach((item) => {
        lat.push(item.lat);
        lng.push(item.lng);
      });
      const paramter = {
        startDate: parameters.startDate,
        endDate: parameters.endDate,
        startTime: parameters.startTime,
        endTime: parameters.endTime,
        location: filterParameters.location,
        eventlocationChecked: filterParameters.eventlocationChecked,
        hotellocationChecked: filterParameters.hotellocationChecked,
        eventTypeValue: filterParameters.eventTypeValue,
        profileValue: filterParameters.profileValue,
        lat: [...lat],
        lng: [...lng],
        startLimit: 0,
      };
      setbackdropopen(true);

      let shouldContinue = true;
      let dataList = [];
      const token = localStorage.getItem("idToken");
      let counter = 0;
      let totalCount = 0;
      let progressPerc = 0;
      let currentPerc = 0;
      setProgressPercentage(0);

      while (shouldContinue) {
        let response = null;
        if (counter === 0) {
          const param = {
            inputParam: {
              ...paramter,
            },
          };

          response = await fetchFirstTimeOverviewTabularDataByParameters(
            param,
            token
          );
          counter = counter + 1;
        } else if (counter < 30) {
          paramter.startLimit =
            counter === 1
              ? paramter.startLimit + 10000 + 1
              : paramter.startLimit + 10000;
          const param = {
            inputParam: {
              ...paramter,
            },
          };

          response = await fetchOverviewTabularDataByParameters(param, token);
          counter = counter + 1;
        }
        if (
          response?.data?._result &&
          response?.data?._code === 200 &&
          !response?.data?._isError
        ) {
          let overviewTabularDataList = [];
          let chunkCount = 0;

          if (counter === 1 || counter === 0) {
            const { tabularList, count, tabularTotalCount } =
              response?.data?._result;
            chunkCount = count;
            totalCount = tabularTotalCount[0].total_count;

            overviewTabularDataList = [...tabularList];
          } else {
            const { tabularList, count } = response?.data?._result;
            chunkCount = count;

            overviewTabularDataList = [...tabularList];
          }
          if (totalCount <= 0) {
            currentPerc = 0;
          } else {
            currentPerc = (10000 / totalCount) * 100;
          }

          progressPerc =
            parseInt(progressPerc + currentPerc) > 100
              ? 100
              : parseInt(progressPerc + currentPerc);

          handlePercentChange(progressPerc);

          //cnt = cnt - 1;
          if (chunkCount === 0) {
            shouldContinue = false;
          } else if (chunkCount > 0 && chunkCount < 10000) {
            dataList.push(overviewTabularDataList);
            shouldContinue = false;
          } else {
            dataList.push(overviewTabularDataList);
          }
        } else {
          shouldContinue = false;
        }
      }
      let parentList = [];
      if (dataList && dataList.length > 0) {
        // Iterate over each sublist in dataList and push objects into parentList
        for (const key in dataList) {
          if (dataList.hasOwnProperty(key)) {
            parentList.push(...dataList[key]);
          }
        }

        const temp = parentList.map((item) => {
          const obj = {};
          for (const key in item) {
            obj[key] = item[key];
          }
          return obj;
        });

        // setTabularData([]);
        setTabularData(temp);
      }
    } catch (error) {
      console.error("Error:", error);
      // Handle error as needed
    }
    setbackdropopen(false);
  }

  const onLoadAutocomplete = (autoC) => {
    setAutocomplete(autoC);
  };

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();
    } else {
      console.log("Autocomplete is not loaded yet!");
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
      }}
    >
      {/* Custom search component  */}

      <SubHeaderFilter
        onDateSelectionChange={onDateSelectionChange}
        filterParameters={filterParameters}
        loadError={loadError}
        isLoaded={isLoaded}
        eventTypeListData={eventTypeList}
        isAutoRefresh={isAutoRefresh}
      />

      <Box
        sx={{
          width: "100%",
          height: "90%",
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
          sx={{
            borderBottom: 1,
            borderColor: "divider",
          }}
        >
          <Grid item>
            <Box
              sx={{
                width: "100%",
                height: "100%",
                flexGrow: 1,
                display: { xs: "none", md: "flex" },
              }}
            >
              <Tabs
                value={tabindex}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab label="Map" {...a11yProps(0)} />
                <Tab label="Tabular View" {...a11yProps(1)} />
              </Tabs>
            </Box>
          </Grid>
          {tabindex === 0 ? (
            <Grid item sx={{ marginRight: "20px" }}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={4}
                sx={{
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                  padding: "7px",
                }}
              >
                <Grid
                  container
                  // spacing={2}
                  sx={{
                    backgroundColor: "#F7F7FF",
                    // margin: "15px",
                    paddingBottom: "5px",
                    borderRadius: "15px",
                  }}
                >
                  <Grid item>
                    <Stack
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      // spacing={2}
                      sx={{ marginLeft: "8px" }}
                    >
                      <Grid item sx={{ marginRight: "15px" }}>
                        Legends:
                      </Grid>
                      <Grid item>
                        <Avatar
                          sx={{ width: 30, height: 35 }}
                          alt="event icon"
                          src={eventMapIcon}
                        />
                      </Grid>
                      <Grid item sx={{ marginLeft: "5px" }}>
                        Event
                      </Grid>
                      <Grid item>
                        <Avatar
                          alt="hotel icon"
                          src={hotelMapIcon}
                          sx={{ width: 30, height: 35, marginLeft: "20px" }}
                        />
                      </Grid>
                      <Grid item sx={{ marginLeft: "5px" }}>
                        Hotel
                      </Grid>
                      <Grid item sx={{ paddingTop: "4px", marginLeft: "25px" }}>
                        <MenuIcon
                          sx={{
                            color: "#0D54FF",
                            cursor: "pointer",
                            marginRight: "15px",
                          }}
                          onClick={toggleDrawer(true)}
                        />
                      </Grid>
                    </Stack>
                    <FilterDrawer
                      openRightDrawer={openRightDrawer}
                      toggleDrawer={toggleDrawer}
                      isLoaded={isLoaded}
                      loadError={loadError}
                      onApplyFnClick={onApplyButtonClick}
                      onResetFnClick={onResetButtonClick}
                      onLoadAutocomplete={onLoadAutocomplete}
                      onPlaceChanged={onPlaceChanged}
                      markersClickedSummary={markersClickedSummary}
                      setGMapMarkerList={setGMapMarkerList}
                      gMapMarkerList={gMapMarkerList}
                      filterParameters={filterParameters}
                      setFilterParameters={setFilterParameters}
                      mapRef={mapRef}
                      setMapRef={setMapRef}
                    />
                  </Grid>
                </Grid>
              </Stack>
            </Grid>
          ) : null}
        </Stack>

        <CustomTabPanel value={tabindex} index={0}>
          <GoogleMapView
            isLoaded={isLoaded}
            loadError={loadError}
            gMapMarkerList={gMapMarkerList}
            setGMapMarkerList={setGMapMarkerList}
            filterParameters={filterParameters}
            setmarkersClickedSummary={setmarkersClickedSummary}
            backdropopen={backdropopen}
            setbackdropopen={setbackdropopen}
            mapRef={mapRef}
            setMapRef={setMapRef}
            isPagechanged={isPagechanged}
          />
        </CustomTabPanel>

        <CustomTabPanel value={tabindex} index={1}>
          <TabularView
            data={tabularData}
            setData={setTabularData}
            filterParameters={filterParameters}
            backdropopen={backdropopen}
            setbackdropopen={setbackdropopen}
            progressPercentage={progressPercentage}
          />
        </CustomTabPanel>
      </Box>

      <Snackbar
        open={snackOpen}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackMessage.severity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackMessage.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Overview;
