import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Menu,
  MenuItem,
} from "@mui/material";
import { GridRowModes } from "@mui/x-data-grid";
import { Select } from "@mui/material";
import { createSvgIcon } from "@mui/material/utils";
import {
  DataGrid,
  GridRowEditStopReasons,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  useGridApiContext,
} from "@mui/x-data-grid";
import { styled } from "@mui/material/styles";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import CloseIcon from "@mui/icons-material/Close";
import { REACT_APP_ADMIN_POOL_NAME } from "../../config/config";
import Button, { ButtonProps } from "@mui/material/Button";
import CancelIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import { exportToCSV } from "../../utils/exportCSV";
import moment from "moment";

const StyledBox = styled(Box)({
  height: "80vh",
  width: "100%",
  overflowX: "auto", // Enable horizontal scroll
});

const AlertDialog = ({
  open,
  setOpen,
  finalSubmission,
  setfinalSubmission,
  onMultipleRowCancelclick,
  setsaveClicked,
}) => {
  const handleClose = () => {
    setOpen(false);
    onMultipleRowCancelclick();
  };

  const handleAgree = () => {
    setsaveClicked(true);
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure to make final changes ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            sx={{ color: "white", backgroundColor: "#a9a7a7" }}
            onClick={handleClose}
          >
            Disagree
          </Button>
          <Button
            onClick={handleAgree}
            variant="contained"
            className="primary"
            autoFocus
          >
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

const CustomExportButton = ({
  rows,
  columnHeader,
  setsnackOpen,
  setsnackMessage,
}) => {
  const apiRef = useGridApiContext();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const ExportIcon = createSvgIcon(
    <path d="M19 12v7H5v-7H3v7c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-7h-2zm-6 .67l2.59-2.58L17 11.5l-5 5-5-5 1.41-1.41L11 12.67V3h2z" />,
    "SaveAlt"
  );

  const exportFilteredRows = () => {
    const filtered_rows = apiRef.current.state.visibleRowsLookup;
    const fil_rows_ids = [];
    for (var key in filtered_rows) {
      if (filtered_rows[key] === true) {
        fil_rows_ids.push(key);
      }
    }

    const selectRowsCSV = rows.filter((item) => fil_rows_ids.includes(item.id));
    let exportPromise = new Promise((resolve, reject) => {
      resolve(
        exportToCSV(
          selectRowsCSV,
          columnHeader,
          "allocation_export_summary_data_"
        )
      );
    });
    exportPromise
      .then((res) => {
        handleMenuClose();
        setTimeout(async function () {
          setsnackOpen(true);
          setsnackMessage({
            message: "Export has started, Please check the browser.",
            severity: "success",
          });
        }, 1000);
      })
      .catch((err) => {});
  };

  const exportAllRows = () => {
    const allRows = apiRef.current.getAllRowIds();

    const selectRowsCSV = rows.filter((item) => allRows.includes(item.id));
    let exportPromise = new Promise((resolve, reject) => {
      resolve(
        exportToCSV(
          selectRowsCSV,
          columnHeader,
          "allocation_export_summary_data_"
        )
      );
    });
    exportPromise
      .then((res) => {
        handleMenuClose();
        setTimeout(async function () {
          setsnackOpen(true);
          setsnackMessage({
            message: "Export has started, Please check the browser.",
            severity: "success",
          });
        }, 1000);
      })
      .catch((err) => {});
  };

  const exportSelectedRows = () => {
    const selectedRowIds = apiRef.current.getSelectedRows();
    const rowIds = Array.from(selectedRowIds.keys());
    const selectRowsCSV = rows.filter((item) => rowIds.includes(item.id));

    let exportPromise = new Promise((resolve, reject) => {
      resolve(
        exportToCSV(
          selectRowsCSV,
          columnHeader,
          "allocation_export_summary_data_"
        )
      );
    });
    exportPromise
      .then((res) => {
        handleMenuClose();
        setTimeout(async function () {
          setsnackOpen(true);
          setsnackMessage({
            message: "Export has started, Please check the browser.",
            severity: "success",
          });
        }, 1000);
      })
      .catch((err) => {});
  };

  return (
    <>
      <Button startIcon={<ExportIcon />} onClick={handleMenuOpen}>
        Export Records
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={exportAllRows}>Export All Rows</MenuItem>
        <MenuItem onClick={exportFilteredRows}>Export Filtered Rows</MenuItem>
        <MenuItem onClick={exportSelectedRows}>Export Selected Rows</MenuItem>
      </Menu>
    </>
  );
};

const CustomEmergencyExport = ({ rows, setsnackOpen, setsnackMessage }) => {
  const apiRef = useGridApiContext();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const ExportIcon = createSvgIcon(
    <path d="M19 12v7H5v-7H3v7c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-7h-2zm-6 .67l2.59-2.58L17 11.5l-5 5-5-5 1.41-1.41L11 12.67V3h2z" />,
    "SaveAlt"
  );

  const columnHeaderForEmergency = [
    {
      field: "personID",
      headerName: "personID",
      sortable: true,
      width: 150,
      editable: false,
    },
  ];

  const exportFilteredRows = () => {
    const filtered_rows = apiRef.current.state.visibleRowsLookup;
    const fil_rows_ids = [];
    for (var key in filtered_rows) {
      if (filtered_rows[key] === true) {
        fil_rows_ids.push(key);
      }
    }

    const selectRowsCSV = rows.filter((item) => fil_rows_ids.includes(item.id));

    // Create a Set of unique personId values
    const uniquePersonIdsSet = new Set(
      selectRowsCSV.map((item) => item.personID)
    );

    // Convert the Set to an array of objects with personId property
    const uniquePersonIds = Array.from(uniquePersonIdsSet).map((id) => ({
      personID: id,
    }));

    let exportPromise = new Promise((resolve, reject) => {
      resolve(
        exportToCSV(
          uniquePersonIds,
          columnHeaderForEmergency,
          "allocation_emergency_report_"
        )
      );
    });
    exportPromise
      .then((res) => {
        handleMenuClose();
        setTimeout(async function () {
          setsnackOpen(true);
          setsnackMessage({
            message: "Export has started, Please check the browser.",
            severity: "success",
          });
        }, 1000);
      })
      .catch((err) => {});
  };

  const exportAllRows = () => {
    const allRows = apiRef.current.getAllRowIds();
    const selectRowsCSV = rows.filter((item) => allRows.includes(item.id));

    // Create a Set of unique personId values
    const uniquePersonIdsSet = new Set(
      selectRowsCSV.map((item) => item.personID)
    );

    // Convert the Set to an array of objects with personId property
    const uniquePersonIds = Array.from(uniquePersonIdsSet).map((id) => ({
      personID: id,
    }));

    let exportPromise = new Promise((resolve, reject) => {
      resolve(
        exportToCSV(
          uniquePersonIds,
          columnHeaderForEmergency,
          "allocation_emergency_report_"
        )
      );
    });
    exportPromise
      .then((res) => {
        handleMenuClose();
        setTimeout(async function () {
          setsnackOpen(true);
          setsnackMessage({
            message: "Export has started, Please check the browser.",
            severity: "success",
          });
        }, 1000);
      })
      .catch((err) => {});
  };

  const exportSelectedRows = () => {
    const selectedRowIds = apiRef.current.getSelectedRows();
    const rowIds = Array.from(selectedRowIds.keys());

    const selectRowsCSV = rows.filter((item) => rowIds.includes(item.id));

    // Create a Set of unique personId values
    const uniquePersonIdsSet = new Set(
      selectRowsCSV.map((item) => item.personID)
    );

    // Convert the Set to an array of objects with personId property
    const uniquePersonIds = Array.from(uniquePersonIdsSet).map((id) => ({
      personID: id,
    }));

    let exportPromise = new Promise((resolve, reject) => {
      resolve(
        exportToCSV(
          uniquePersonIds,
          columnHeaderForEmergency,
          "allocation_emergency_report_"
        )
      );
    });
    exportPromise
      .then((res) => {
        handleMenuClose();
        setTimeout(async function () {
          setsnackOpen(true);
          setsnackMessage({
            message: "Export has started, Please check the browser.",
            severity: "success",
          });
        }, 1000);
      })
      .catch((err) => {});
  };

  return (
    <>
      <Button startIcon={<ExportIcon />} onClick={handleMenuOpen}>
        Export Emergency Records
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={exportAllRows}>Export All Rows</MenuItem>
        <MenuItem onClick={exportFilteredRows}>Export Filtered Rows</MenuItem>
        <MenuItem onClick={exportSelectedRows}>Export Selected Rows</MenuItem>
      </Menu>
    </>
  );
};

function EditToolbar(props) {
  const {
    selectedRows,
    setRows,
    setRowModesModel,
    startEditing,
    saveChanges,
    preViewChanges,
    cancelChanges,
    saveRowsClicked,
    previewClicked,
    groupName,
    columnHeader,
    selectRowsCSV,
    rows,
    setsnackOpen,
    setsnackMessage,
    setEditedModesData,
    editedModesData,
  } = props;
  let isEditDoneFlag =
    Object.entries(editedModesData).length > 0 ? false : true;
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      {localStorage.getItem("userRole") === REACT_APP_ADMIN_POOL_NAME ? (
        <>
          <Button
            color="primary"
            startIcon={<EditIcon />}
            onClick={() => startEditing(selectedRows)}
            disabled={selectedRows.length <= 0}
          >
            Edit Rows
          </Button>
          <Button
            color="primary"
            startIcon={<RemoveRedEyeIcon />}
            disabled={isEditDoneFlag || saveRowsClicked === true}
            onClick={() => preViewChanges(selectedRows)}
          >
            Preview changes
          </Button>
          <Button
            color="primary"
            disabled={
              isEditDoneFlag ||
              previewClicked === false ||
              saveRowsClicked === true
            }
            startIcon={<SaveIcon />}
            onClick={() => saveChanges(selectedRows)}
          >
            Save changes
          </Button>
          <Button
            color="primary"
            disabled={isEditDoneFlag || saveRowsClicked === true} //selectedRows.length <= 0
            startIcon={<CancelIcon />}
            onClick={() => cancelChanges(selectedRows)}
          >
            Cancel changes
          </Button>
        </>
      ) : null}

      <CustomExportButton
        rows={rows}
        setsnackOpen={setsnackOpen}
        setsnackMessage={setsnackMessage}
        columnHeader={columnHeader}
      />
      {localStorage.getItem("userRole") === REACT_APP_ADMIN_POOL_NAME ? (
        <CustomEmergencyExport
          rows={rows}
          setsnackOpen={setsnackOpen}
          setsnackMessage={setsnackMessage}
        />
      ) : null}
    </GridToolbarContainer>
  );
}

const TabularDataEdit = ({
  rows = [],
  setRows,
  columns = [],
  selectedRows,
  setSelectedRows,
  selectRowsCSV,
  setSelectedRowsSCV,
  isGirdEditable = false,
  // rowModesModel = [],
  // setRowModesModel,
  // processRowUpdate,
  // handleProcessRowUpdateError,
  finalSubmission,
  setfinalSubmission,
  processSavedRowUpdate,
  savedEditedRows,
  setsavedEditedRows,
  refreshGridData,
  guestList,
  eventList,
  userClassList,
  specialStatusList,
  columnHeader,
  setsnackOpen,
  setsnackMessage,
}) => {
  const [rowSelectionModel, setRowSelectionModel] = React.useState([]);
  // const { user } = useAuth();
  const groupName = localStorage.getItem("userRole");

  const [open, setOpen] = React.useState(false);
  const [saveClicked, setsaveClicked] = useState(false);
  const [rowModesModel, setRowModesModel] = React.useState({});
  const [saveRowsClicked, setsaveRowsClicked] = React.useState(false);
  const [previewClicked, setPreviewClicked] = React.useState(false);

  const guestListIds = guestList.map((item) => item.personID);
  const eventListIds = eventList.map((item) => item.eventId);
  const userClassListLables = userClassList.map((item) => item.class);
  const specialStatusListLabels = specialStatusList.map(
    (item) => item.specialStatus
  );
  const [editedModesData, setEditedModesData] = React.useState({});

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const startEditing = (ids) => {
    setsaveRowsClicked(false);
    setPreviewClicked(false);
    const newModes = {};
    ids.forEach((id) => {
      newModes[id] = { mode: GridRowModes.Edit };
    });
    setEditedModesData((oldModel) => ({ ...oldModel, ...newModes }));

    setRowModesModel((oldModel) => ({ ...oldModel, ...newModes }));
  };

  const saveChanges = (ids) => {
    const newModes = {};
    ids.forEach((id) => {
      newModes[id] = { mode: GridRowModes.View };
    });
    setEditedModesData((oldModel) => ({ ...oldModel, ...newModes }));
    setRowModesModel((oldModel) => ({ ...oldModel, ...newModes }));
    setsaveRowsClicked(true);

    // Save changes callback function
    processSavedRowUpdate(savedEditedRows);
  };

  const preViewChanges = (ids) => {
    const newModes = {};
    ids.forEach((id) => {
      newModes[id] = { mode: GridRowModes.View };
    });
    setEditedModesData((oldModel) => ({ ...oldModel, ...newModes }));
    setRowModesModel((oldModel) => ({ ...oldModel, ...newModes }));
    setPreviewClicked(true);
  };

  const cancelChanges = (ids) => {
    setRows(rows);
    const newModes = {};
    ids.forEach((id) => {
      newModes[id] = { mode: GridRowModes.View, ignoreModifications: true };
    });
    setEditedModesData({});
    setRowModesModel((oldModel) => ({ ...oldModel, ...newModes }));

    refreshGridData();
  };

  // This function updates the row data, including changes to the join date and age when the role changes.
  const processRowUpdate = (newRow, oldRow) => {
    const updatedRow = { ...newRow };

    // If the role changes, update the join date and age.
    if (newRow.personID !== oldRow.personID) {
      onSelectDataChange(guestColumns, updatedRow, "person", newRow.personID);
    }

    if (newRow.eventId !== oldRow.eventId) {
      onSelectDataChange(eventColumns, updatedRow, "event", newRow.eventId);
    }

    if (newRow.class !== oldRow.class) {
      onSelectDataChange([], updatedRow, "class", newRow.class);
    }

    if (newRow.specialStatus !== oldRow.specialStatus) {
      onSelectDataChange([], updatedRow, "specialStatus", newRow.specialStatus);
    }

    setsavedEditedRows((prev) => [...prev, updatedRow]);

    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleSelectionModelChange = (newSelection) => {
    const select_rows = newSelection.map((id) =>
      rows.find((row) => {
        return row.id === id;
      })
    );
    setSelectedRows(newSelection);
    setSelectedRowsSCV(select_rows);
  };

  const guestColumns = [
    "personID",
    "firstName",
    "middleName",
    "lastName",
    "gender",
    "primaryMobilePhoneNumber",
    "primaryEmailAddress",
    "GSSFlag",
    "principleFlag",
    "hasDisability",
    "localLanguageProficiency",
    "lastCheckedHotel",
    "lastCheckedHotelTime",
    "lastCheckedLocation",
    "lastCheckedLocationTime",
  ];

  const eventColumns = [
    "guestEventType",
    "eventId",
    "eventTypeName",
    "eventName",
    "sport",
    "fromLocation",
    "locationTypeName",
    "locationName",
    "geoLocationLat",
    "geoLocationLong",
    "toLocation",
    "toLocationTypeName",
    "toLocationName",
    "itineraryDate",
    "startDateTime",
    "endDateTime",
  ];

  const onSelectDataChange = async (columns, updatedRow, changetype, id) => {
    if (changetype === "person") {
      // Find the guest with the matching ID
      const guest = guestList.find((item) => item.personID === id);

      if (guest) {
        // Loop through each column and update the row
        columns.forEach((col) => {
          if (guest[col] !== undefined) {
            updatedRow[col] = guest[col];
          }
        });
      }
    } else if (changetype === "event") {
      // Find the guest with the matching ID
      const event = eventList.find((item) => item.eventId === id);

      if (event) {
        // Loop through each column and update the row
        columns.forEach((col) => {
          if (event[col] !== undefined) {
            updatedRow[col] = event[col];
          }
        });
      }
    } else if (changetype === "class") {
      updatedRow["class"] = id;
    } else if (changetype === "specialStatus") {
      updatedRow["specialStatus"] = id;
    }
  };

  const onLoadHiddenColumns = {
    dataSource: false,
    primaryMobilePhoneNumber: false,
    primaryEmailAddress: false,
    GSSFlag: false,
    principleFlag: false,
    locationTypeName: false,
    geoLocationLat: false,
    geoLocationLong: false,
    hasDisability: false,
    localLanguageProficiency: false,
    invitedBycontactdetails: false,
    updatedDateTime: false,
    last_checked_hotel: false,
    last_checked_hotel_time: false,
    last_checked_location: false,
    last_checked_location_time: false,
    modifiedBy: false,
    modifiedDateTime: false,
  };

  useEffect(() => {
    if (saveClicked) {
      processSavedRowUpdate(savedEditedRows);

      // Reset states after save
      setsaveClicked(false);
      setfinalSubmission(true);
    }
  }, [saveClicked]);
  return (
    <Box
      sx={{
        height: "80vh",
        overflowX: "auto",
      }}
    >
      <StyledBox>
        <DataGrid
          // hiding select all records
          sx={{
            ".MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root": {
              display: selectedRows.length === 0 ? "none" : "block",
            },
          }}
          getRowId={(row) => (row.id ? row.id : row.id)}
          rows={rows}
          columns={columns}
          initialState={{
            columns: {
              columnVisibilityModel: onLoadHiddenColumns,
            },
          }}
          editMode="row"
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStop={handleRowEditStop}
          onRowSelectionModelChange={(rowId) =>
            handleSelectionModelChange(rowId)
          }
          // onCellEditCommit={handleCellEditStart}
          processRowUpdate={(newRow, oldRow) =>
            processRowUpdate(newRow, oldRow)
          }
          checkboxSelection
          disableRowSelectionOnClick
          // className={classes.hideSelectAllCheckbox}
          hideFooterPagination
          paginationMode="server"
          slots={{
            toolbar: EditToolbar,
          }}
          slotProps={{
            toolbar: {
              selectedRows,
              setRows,
              setRowModesModel,
              startEditing,
              saveChanges,
              preViewChanges,
              cancelChanges,
              saveRowsClicked,
              previewClicked,
              groupName,
              columnHeader,
              selectRowsCSV,
              rows,
              setsnackOpen,
              setsnackMessage,
              setEditedModesData,
              editedModesData,
            },
          }}
          onCellDoubleClick={(params, event) =>
            (event.defaultMuiPrevented = true)
          } // Prevent edit on double-click
          disableSelectionOnClick
        />
      </StyledBox>

      <AlertDialog
        open={open}
        setOpen={setOpen}
        finalSubmission={finalSubmission}
        setfinalSubmission={setfinalSubmission}
        onMultipleRowCancelclick={cancelChanges}
        setsaveClicked={setsaveClicked}
      />
    </Box>
  );
};

export default TabularDataEdit;
