import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import MainLayout from "./components/common/MainLayout";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import theme from "./theme";
import OverviewIcon from "../src/assets/images/overviewIcon.png";
import EventScheduleIcon from "../src/assets/images/EventScheduleIcon.png";
import AllocationIcon from "../src/assets/images/AllocationIcon.png";
import AdminDashboardIcon from "../src/assets/images/AdminDashboardIcon.png";

const pages = {
  "Command-Team": [
    {
      pageName: "Overview",
      path: "/",
      icon: OverviewIcon,
    },
    {
      pageName: "Event Schedule",
      path: "/event-schedule",
      icon: EventScheduleIcon,
    },
    {
      pageName: "Allocation",
      path: "/allocation",
      icon: AllocationIcon,
    },
    {
      pageName: "Admin Dashboard",
      path: "/dashboard",
      icon: AdminDashboardIcon,
    },
  ],
  "Onsite-Team": [
    {
      pageName: "Overview",
      path: "/",
      icon: OverviewIcon,
    },
    {
      pageName: "Event Schedule",
      path: "/event-schedule",
      icon: EventScheduleIcon,
    },
    {
      pageName: "Allocation",
      path: "/allocation",
      icon: AllocationIcon,
    },
  ],
};

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        {/* <AuthProvider> */}
        <MainLayout
          pages={pages}
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
          }}
        />
        {/* </AuthProvider> */}
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
