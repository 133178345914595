import React, { useState } from "react";
import { Grid, Snackbar, Alert } from "@mui/material";
import { exportToCSV } from "../../utils/exportCSV";
import { generateHeadercolumns } from "../../utils/utils.js";
import BackDrop from "../common/BackDrop.js";
import TabularData2 from "../common/TabularData2.js";
import ProgressBackdrop from "../common/ProgressBackdrop.js";

const TabularView = ({
  data,
  setData,
  filterParameters,
  backdropopen,
  progressPercentage,
}) => {
  const [snackOpen, setsnackOpen] = useState(false);
  const [snackMessage, setsnackMessage] = useState({
    message: "",
    severity: "success",
  });
  const [selectRows, setSelectedRows] = useState([]);

  // Create the ColumnHeaders

  let columnHeader = [];
  const isEditable = false;
  React.useMemo(() => {
    const list = generateHeadercolumns(data, false, [], [], [], []);
    columnHeader.push(...list);
  });

  const onClick = () => {
    if (selectRows.length <= 0) {
      setsnackOpen(true);
      setsnackMessage({
        message: "Please Select atleast 1 Row!!",
        severity: "info",
      });
    } else {
      let exportPromise = new Promise((resolve, reject) => {
        resolve(exportToCSV(selectRows, columnHeader, "tabular_export_"));
      });
      exportPromise
        .then((res) => {
          setTimeout(async function () {
            setsnackOpen(true);
            setsnackMessage({
              message: "Export has started, Please check the browser.",
              severity: "success",
            });
          }, 1000);
        })
        .catch((err) => {});
    }
  };
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setsnackOpen(false);
  };

  const onClickEmergencyReport = async () => {
    const columnHeaderForEmergency = [
      {
        field: "personID",
        headerName: "personID",
        sortable: true,
        width: 150,
        editable: false,
      },
    ];

    if (selectRows.length <= 0) {
      setsnackOpen(true);
      setsnackMessage({
        message: "Please Select atleast 1 Row!!",
        severity: "info",
      });
    } else {
      // Create a Set of unique personId values
      const uniquePersonIdsSet = new Set(
        selectRows.map((item) => item.personID)
      );

      // Convert the Set to an array of objects with personId property
      const uniquePersonIds = Array.from(uniquePersonIdsSet).map((id) => ({
        personID: id,
      }));

      let exportPromise = new Promise((resolve, reject) => {
        resolve(
          exportToCSV(
            uniquePersonIds,
            columnHeaderForEmergency,
            "tabular_emergency_report_"
          )
        );
      });
      exportPromise
        .then((res) => {
          setTimeout(async function () {
            setsnackOpen(true);
            setsnackMessage({
              message: "Export has started, Please check the browser.",
              severity: "success",
            });
          }, 1000);
        })
        .catch((err) => {});
    }
  };

  return (
    <Grid
      container
      spacing={2}
      direction="column"
      sx={{
        width: "100%",
        height: "100%",
        flexGrow: 1,
        display: { xs: "none", md: "flex" },
      }}
    >
      <Grid
        container
        direction={"row"}
        justifyContent={"flex-end"}
        spacing={0.5}
        sx={{
          marginTop: "25px",
          flexGrow: 1,
          display: { xs: "none", md: "flex" },
        }}
      >
        <Grid item>
          {/* <Button
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
            }}
            variant="contained"
            className="primary"
            onClick={onClick}
          >
            Export CSV
          </Button> */}
        </Grid>
        {/* {localStorage.getItem("userRole") ===
        process.env.REACT_APP_ADMIN_POOL_NAME ? (
          <Grid item>
            <Button
              sx={{
                flexGrow: 1,
                display: { xs: "none", md: "flex" },
              }}
              variant="contained"
              className="primary"
              onClick={onClickEmergencyReport}
            >
              Emergency Export
            </Button>
          </Grid>
        ) : null} */}
      </Grid>
      <Grid
        item
        sx={{
          flexGrow: 1,
          width: "-webkit-fill-available",
          // width: "95%",
          // overflowX: "auto", // Enable horizontal scroll
        }}
      >
        <TabularData2
          rows={data}
          setRows={setData}
          columns={columnHeader}
          selectRows={selectRows}
          setSelectedRows={setSelectedRows}
          setsnackOpen={setsnackOpen}
          setsnackMessage={setsnackMessage}
          columnHeader={columnHeader}
        />
      </Grid>
      <Snackbar
        open={snackOpen}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackMessage.severity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackMessage.message}
        </Alert>
      </Snackbar>
      {/* <BackDrop backdropopen={backdropopen} /> */}
      <ProgressBackdrop
        progressPercentage={progressPercentage}
        backdropopen={backdropopen}
      />
    </Grid>
  );
};

export default TabularView;
