import React, { useEffect, useState } from "react";

import { Button, Grid, Stack, Snackbar, Alert } from "@mui/material";
import { postAllocationFeed } from "../../utils/parentservices";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ImportDialog from "./ImportDialog";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import {
  convertDBDateTime,
  generateHeadercolumns,
  validateAllocationImportData,
  validationUIEditData,
} from "../../utils/utils";
// import { useAuth } from "../../utils/AuthContext";
import BackDrop from "../common/BackDrop";
import TabularDataEdit from "../common/TabularDataEdit.js";
import { exportToCSV } from "../../utils/exportCSV.js";
import ProgressBackdrop from "../common/ProgressBackdrop.js";

const ManualFeed = ({
  manualFeedData,
  manualfeedCount,
  setmanualFeedData,
  filterParameters,
  onDetailsUpdateFetchLatestManualEventsData,
  backdropopen,
  guestList,
  eventList,
  userClassList,
  specialStatusList,
  progressPercentage,
}) => {
  const [rows, setRows] = useState([]);
  const [importData, setimportData] = useState([]);
  const [dialogopen, setDialogopen] = useState(false);
  const [selectRows, setSelectedRows] = useState([]);
  const [snackOpen, setsnackOpen] = useState(false);
  const [errorRecords, seterrorRecords] = useState([]);
  const [snackMessage, setsnackMessage] = useState({
    message: "",
    severity: "success",
  });
  const [rowModesModel, setRowModesModel] = React.useState({});
  const [finalSubmission, setfinalSubmission] = useState(false);

  const [savedEditedRows, setsavedEditedRows] = useState([]);
  const [selectRowsCSV, setSelectedRowsSCV] = useState([]);

  let columnHeader = [];
  const isEditable = true;
  const list = generateHeadercolumns(
    manualFeedData,
    isEditable,
    guestList,
    eventList,
    userClassList,
    specialStatusList
  );
  columnHeader.push(...list);

  const onClickExportCSV = (clickType) => {
    if (clickType === "exportData") {
      if (selectRowsCSV.length <= 0) {
        setsnackOpen(true);
        setsnackMessage({
          message: "Please Select atleast 1 Row",
          severity: "info",
        });
      } else {
        let exportPromise = new Promise((resolve, reject) => {
          resolve(
            exportToCSV(selectRowsCSV, columnHeader, "manual_feed_export_")
          );
        });
        exportPromise
          .then((res) => {
            setTimeout(async function () {
              setsnackOpen(true);
              setsnackMessage({
                message: "Export has started, Please check the browser.",
                severity: "success",
              });
            }, 1000);
          })
          .catch((err) => {});
      }
    }

    if (clickType === "errorData") {
      const colHeader = columnHeader.concat([
        {
          field: "error_message",
          headerName: "error_message",
          width: 150,
          editable: "true",
        },
      ]);

      exportToCSV(errorRecords, colHeader, "import_error_");
      setsnackOpen(true);
      setsnackMessage({
        message: "Incorrect Import records exported!!",
        severity: "success",
      });
      seterrorRecords([]);
    }
  };

  // Import Data post request
  useEffect(() => {
    const postManualImportData = async () => {
      // const validated_data = validateImportData(importData);
      const data = validateAllocationImportData(
        importData,
        localStorage.getItem("userName")
      );

      const validated_data = data[0];
      const errorrows = data[1];

      seterrorRecords((prev) => [...prev, ...errorrows]);

      if (validated_data.length <= 0) {
        setsnackOpen(true);
        setsnackMessage({
          message: "Please check error logs!!",
          severity: "error",
        });
        return;
      }
      const token = localStorage.getItem("idToken");
      const response = await postAllocationFeed(validated_data, token);

      if (
        response?.data?._result &&
        response?.data?._code === 200 &&
        !response?.data?._isError
      ) {
        const resp = response?.data?._result;

        // if you have right privaligies
        if (resp && resp.statusCode && resp.success) {
          setsnackOpen(true);
          setsnackMessage({
            message: "Details Updated successfully!!",
            severity: "success",
          });
        }
        // No right privaligies
        else if (resp && resp.statusCode && resp.success === false) {
          setsnackOpen(true);
          setsnackMessage({
            message: resp?.message ? resp.message : "Error!!",
            severity: "error",
          });
        } else {
          console.error("Something went wrong!");
        }
      } else if (response?.data?._code === 500) {
        setsnackOpen(true);
        setsnackMessage({
          message: "Error occurred while uploading data!!",
          severity: "error",
        });
      } else {
        setsnackOpen(true);
        setsnackMessage({
          message: "Something went wrong!!",
          severity: "error",
        });
      }
    };

    if (importData.length > 0) {
      postManualImportData();
    }

    // fetch again from db
    // onDetailsUpdateFetchLatestManualEventsData(filterParameters);

    refreshGridData();
  }, [importData]);

  const handleProcessRowUpdateError = React.useCallback((error) => {
    console.log("error", error);
  }, []);

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setsnackOpen(false);
  };

  const processSavedRowUpdate = async (newRows) => {
    const validated_data = [];

    newRows.map((item) => {
      item.itineraryDate = convertDBDateTime(item);

      const data = validationUIEditData(
        [item],
        localStorage.getItem("userName")
      );

      const temp = data[0];
      const errorrows = data[1];

      seterrorRecords((prev) => [...prev, ...errorrows]);
      validated_data.push(...temp);
    });

    if (validated_data.length <= 0) {
      setsnackOpen(true);
      setsnackMessage({
        message: "Please check error logs!!",
        severity: "error",
      });
      return handleProcessRowUpdateError("Please check error logs!");
    }
    const token = localStorage.getItem("idToken");
    const response = await postAllocationFeed(validated_data, token);

    if (
      response?.data?._result &&
      response?.data?._code === 200 &&
      !response?.data?._isError
    ) {
      const resp = response?.data?._result;

      // if you have right privaligies
      if (resp && resp.statusCode && resp.success) {
        setsnackOpen(true);
        setsnackMessage({
          message: "Details Updated successfully!!",
          severity: "success",
        });
      }

      // No right privaligies
      else if (resp && resp.statusCode && resp.success === false) {
        setsnackOpen(true);
        setsnackMessage({
          message: resp?.message ? resp.message : "Error!!",
          severity: "error",
        });
      }
    } else if (response?.data?._code === 500) {
      setsnackOpen(true);
      setsnackMessage({
        message: "Error occurred while uploading data!!",
        severity: "error",
      });
    } else {
      setsnackOpen(true);
      setsnackMessage({
        message: response?._message,
        severity: "error",
      });
    }

    onDetailsUpdateFetchLatestManualEventsData(filterParameters);
    setsavedEditedRows([]);
  };

  const refreshGridData = async () => {
    await onDetailsUpdateFetchLatestManualEventsData(filterParameters);
  };

  return (
    <Grid
      container
      spacing={2}
      direction="column"
      sx={{ width: "100%", height: "100%" }}
    >
      <Grid item sx={{ marginTop: "10px", width: "100%" }}>
        <Stack direction="row" spacing={2} justifyContent={"flex-end"}>
          {localStorage.getItem("userRole") ===
          process.env.REACT_APP_ADMIN_POOL_NAME ? (
            <>
              <Button
                component="span"
                className="primary"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                startIcon={<CloudUploadIcon />}
                onClick={() => setDialogopen(true)}
              >
                Import Data
              </Button>
              <ImportDialog
                dialogopen={dialogopen}
                setDialogopen={setDialogopen}
                importData={importData}
                setimportData={setimportData}
              />
            </>
          ) : null}

          {/* <Button
            variant="contained"
            className="primary"
            onClick={() => onClickExportCSV("exportData")}
          >
            {"Export CSV"}
          </Button> */}
          {errorRecords.length > 0 ? (
            <DownloadForOfflineIcon
              style={{ color: "red", cursor: "pointer", fontSize: "30" }}
              onClick={() => onClickExportCSV("errorData")}
            />
          ) : null}
        </Stack>
      </Grid>
      <Grid item sx={{ flexGrow: 1, width: "-webkit-fill-available" }}>
        <TabularDataEdit
          rows={manualFeedData}
          setRows={setmanualFeedData}
          columns={columnHeader}
          selectedRows={selectRows}
          setSelectedRows={setSelectedRows}
          selectRowsCSV={selectRowsCSV}
          setSelectedRowsSCV={setSelectedRowsSCV}
          isGirdEditable={true}
          rowModesModel={rowModesModel}
          setRowModesModel={setRowModesModel}
          handleProcessRowUpdateError={handleProcessRowUpdateError}
          finalSubmission={finalSubmission}
          setfinalSubmission={setfinalSubmission}
          processSavedRowUpdate={processSavedRowUpdate}
          savedEditedRows={savedEditedRows}
          setsavedEditedRows={setsavedEditedRows}
          refreshGridData={refreshGridData}
          // allocation drop down filters
          guestList={guestList}
          eventList={eventList}
          userClassList={userClassList}
          specialStatusList={specialStatusList}
          setsnackOpen={setsnackOpen}
          setsnackMessage={setsnackMessage}
          columnHeader={columnHeader}
        />
      </Grid>
      <Snackbar
        open={snackOpen}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackMessage.severity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackMessage.message}
        </Alert>
      </Snackbar>
      {/* <BackDrop backdropopen={backdropopen} /> */}
      <ProgressBackdrop
        backdropopen={backdropopen}
        progressPercentage={progressPercentage}
      />
    </Grid>
  );
};

export default ManualFeed;
