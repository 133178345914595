import {
  Box,
  Grid,
  Button,
  TextField,
  Typography,
  InputAdornment,
  Stack,
  Link,
  Snackbar,
  Alert,
} from "@mui/material";
import React, { useState } from "react";
import PersonIcon from "@mui/icons-material/Person";
import LockIcon from "@mui/icons-material/Lock";
import { useNavigate } from "react-router-dom";
import BackDrop from "../common/BackDrop";
import ReCAPTCHA from "react-google-recaptcha";
import { awsloginuser } from "../../utils/parentservices";

const LoginUser = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [captchaToken, setCaptchaToken] = useState(null);
  const [passwordErr, setPasswordErr] = useState("");
  const [usernameErr, setUsernameErr] = useState("");
  const [recaptchaErr, setRecaptchaErr] = useState("");
  const [loginErr, setloginErr] = useState("");
  const [snackOpen, setsnackOpen] = useState(false);
  const [recaptchaFlag, setRecaptchaFlag] = useState(false);
  const [snackMessage, setsnackMessage] = useState({
    message: "",
    severity: "success",
  });
  const [backdropopen, setbackdropopen] = useState(false);

  const navigate = useNavigate();

  const validation = () => {
    return new Promise((resolve, reject) => {
      if (password === "" && username === "" && !recaptchaFlag) {
        setPasswordErr("Password is required!");
        setUsernameErr("Username is required!");
        setRecaptchaErr("Please select recaptcha");
        resolve({
          password: "Password is required",
          username: "Username is required",
          recaptcha: "Please select recaptcha",
        });
      } else if (password === "") {
        setPasswordErr("Password is required");
        resolve({ password: "Password is required", username: "" });
      } else if (username === "") {
        setUsernameErr("Username is required");
        resolve({
          password: "",
          username: "Username is required!",
          recaptcha: "",
        });
      } else if (!recaptchaFlag) {
        setRecaptchaErr("Please select recaptcha");
        resolve({
          recaptcha: "Please select recaptcha",
          username: "",
          password: "",
        });
      } else {
        resolve({ password: "", username: "", recaptcha: "" });
      }
      reject("");
    });
  };

  const onRecaptchaChange = (token) => {
    setRecaptchaFlag(!recaptchaFlag);
    setCaptchaToken(token);
  };

  const onClickLogin = async () => {
    setbackdropopen(true);
    try {
      setPasswordErr("");
      setUsernameErr("");
      setRecaptchaErr("");
      setloginErr("");
      const res = await validation();

      if (res.username === "" && res.password === "" && res.recaptcha === "") {
        try {
          setloginErr("");
          const paramters = {
            username,
            password,
            captchaToken,
          };
          const response = await awsloginuser(paramters);
          if (
            response?.data?._result &&
            response?.data?._code === 200 &&
            !response?.data?._isError
          ) {
            const resp = response?.data?._result;
            if (resp && resp.statusCode && resp.success) {
              const cognito_body = resp.body.AuthenticationResult;
              try {
                localStorage.setItem(
                  "userRole",
                  resp?.userGroup ? resp?.userGroup : null
                );
              } catch {
                localStorage.setItem("userRole", "");
              }
              const loginDate = new Date();
              const loginDateTime = new Date(loginDate.getTime());
              localStorage.setItem("userName", resp?.userName);
              localStorage.setItem("idToken", cognito_body.IdToken);
              localStorage.setItem("loginDateTime", loginDateTime);

              setsnackOpen(true);
              setsnackMessage({
                message: "SignIn successful!! Redirecting to Homepage... ",
                severity: "success",
              });

              // Delay navigation to allow snackbar to be visible
              setTimeout(() => {
                navigate("/");
              }, 2000); // Adjust the delay as needed (2 seconds in this case)
            } else {
              setloginErr(response?.data?._result.message);
            }
          } else {
            setloginErr("Something went wrong! Please try again later");
          }
        } catch (e) {
          setloginErr(e);
        }
      } else {
        setPasswordErr(res.password);
        setUsernameErr(res.username);
        setRecaptchaErr(res.recaptcha);
      }
    } catch (err) {
      console.log(err);
    }
    setbackdropopen(false);
  };

  const newUserRegistration = () => {
    navigate("/signup");
  };

  const forgotPassword = () => {
    navigate("/forgot-password");
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setsnackOpen(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent the default form submission
      onClickLogin();
    }
  };

  return (
    <Box sx={{ width: "350px", height: "350px", marginTop: "-90px" }}>
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={1}
        sx={{ width: "100%" }}
      >
        {/* Welcome text */}
        <Grid item sx={{ width: "100%" }}>
          <Typography variant="h5" fontWeight="bold">
            Welcome
          </Typography>
        </Grid>

        {/*  Username */}
        <Grid item sx={{ width: "100%" }}>
          <Grid container spacing={1}>
            <Grid item>
              <Typography variant="body1" fontWeight="bold">
                Username
              </Typography>
            </Grid>
            <Grid item sx={{ width: "88%" }}>
              <TextField
                fullWidth
                id="fullWidth"
                placeholder="Username"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PersonIcon />
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => setUsername(e.target.value)}
                onKeyDown={handleKeyDown}
              ></TextField>
            </Grid>
          </Grid>
        </Grid>

        {/* Password  */}
        <Grid item sx={{ width: "100%" }}>
          <Grid container spacing={1}>
            <Grid item>
              <Typography variant="body1" fontWeight="bold">
                Password
              </Typography>
            </Grid>
            <Grid item sx={{ width: "88%" }} component="form">
              <TextField
                fullWidth
                placeholder="Password"
                id="fullWidth"
                type="password"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockIcon />
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => setPassword(e.target.value)}
                onKeyDown={handleKeyDown}
              ></TextField>
            </Grid>
          </Grid>
        </Grid>

        {/* ReCAPTCHA  */}
        <Grid item sx={{ width: "100%" }}>
          <Grid container spacing={1}>
            <Grid item>
              <Typography variant="body1">
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_RE_CAPTCHA_KEY}
                  //onChange={(val) => setRecaptchaFlag(val)}
                  onChange={onRecaptchaChange}
                />
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        {/* Login Button */}
        <Grid item sx={{ width: "100%" }}>
          <Button
            variant="contained"
            className="primary"
            sx={{ width: "88%" }}
            disabled={!recaptchaFlag}
            onClick={onClickLogin}
          >
            Login
          </Button>
        </Grid>

        {/* New user registartion */}
        <Grid item sx={{ width: "100%" }}>
          <Typography variant="body2">
            <Link sx={{ cursor: "pointer" }} onClick={newUserRegistration}>
              SignUp
            </Link>
          </Typography>
          <Typography variant="body2">
            <Link sx={{ cursor: "pointer" }} onClick={forgotPassword}>
              Forgot Password
            </Link>
          </Typography>
        </Grid>
        <hr />
        {loginErr && <Typography sx={{ color: "red" }}>{loginErr}</Typography>}
        {usernameErr && (
          <Typography sx={{ color: "red" }}>{usernameErr}</Typography>
        )}
        {passwordErr && (
          <Typography sx={{ color: "red" }}>{passwordErr}</Typography>
        )}
        {recaptchaErr && (
          <Typography sx={{ color: "red" }}>{recaptchaErr}</Typography>
        )}
      </Grid>
      <Snackbar
        open={snackOpen}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackMessage.severity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackMessage.message}
        </Alert>
      </Snackbar>
      <BackDrop
        backdropopen={backdropopen}
        backdropmessage={"Signing In, please wait..."}
      />
    </Box>
  );
};

export default LoginUser;
