import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Menu,
  MenuItem,
} from "@mui/material";
import { GridRowModes } from "@mui/x-data-grid";
import {
  DataGrid,
  GridRowEditStopReasons,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  useGridApiContext,
} from "@mui/x-data-grid";
import { styled } from "@mui/material/styles";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import { REACT_APP_ADMIN_POOL_NAME } from "../../config/config";
import moment from "moment";
import { createSvgIcon } from "@mui/material/utils";
import { exportToCSV } from "../../utils/exportCSV";

const StyledBox = styled(Box)({
  height: "80vh",
  width: "100%",
  overflowX: "auto", // Enable horizontal scroll
});

const AlertDialog = ({
  open,
  setOpen,
  finalSubmission,
  setfinalSubmission,
  onMultipleRowCancelclick,
  setsaveClicked,
}) => {
  const handleClose = () => {
    setOpen(false);
    onMultipleRowCancelclick();
  };

  const handleAgree = () => {
    setsaveClicked(true);
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure to make final changes ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            sx={{ color: "white", backgroundColor: "#a9a7a7" }}
            onClick={handleClose}
          >
            Disagree
          </Button>
          <Button
            onClick={handleAgree}
            variant="contained"
            className="primary"
            autoFocus
          >
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

const CustomExportButton = ({
  rows,
  columnHeader,
  setsnackOpen,
  setsnackMessage,
}) => {
  const apiRef = useGridApiContext();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const ExportIcon = createSvgIcon(
    <path d="M19 12v7H5v-7H3v7c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-7h-2zm-6 .67l2.59-2.58L17 11.5l-5 5-5-5 1.41-1.41L11 12.67V3h2z" />,
    "SaveAlt"
  );

  const exportFilteredRows = () => {
    const filtered_rows = apiRef.current.state.visibleRowsLookup;
    const fil_rows_ids = [];
    for (var key in filtered_rows) {
      if (filtered_rows[key] === true) {
        fil_rows_ids.push(key);
      }
    }

    const selectRowsCSV = rows.filter((item) => fil_rows_ids.includes(item.id));

    let timestamp = moment().format();
    const filename = "tabular_export_data_" + timestamp + ".csv";
    let exportPromise = new Promise((resolve, reject) => {
      resolve(exportToCSV(selectRowsCSV, columnHeader, filename));
    });
    exportPromise
      .then((res) => {
        setTimeout(async function () {
          handleMenuClose();
          setsnackOpen(true);
          setsnackMessage({
            message: "Export has started, Please check the browser.",
            severity: "success",
          });
        }, 1000);
      })
      .catch((err) => {});
  };

  const exportAllRows = () => {
    const allRows = apiRef.current.getAllRowIds();

    const selectRowsCSV = rows.filter((item) => allRows.includes(item.id));

    let exportPromise = new Promise((resolve, reject) => {
      resolve(exportToCSV(selectRowsCSV, columnHeader, "tabular_export_data_"));
    });
    exportPromise
      .then((res) => {
        setTimeout(async function () {
          handleMenuClose();
          setsnackOpen(true);
          setsnackMessage({
            message: "Export has started, Please check the browser.",
            severity: "success",
          });
        }, 1000);
      })
      .catch((err) => {});
  };

  const exportSelectedRows = () => {
    const selectedRowIds = apiRef.current.getSelectedRows();
    const rowIds = Array.from(selectedRowIds.keys());

    const selectRowsCSV = rows.filter((item) => rowIds.includes(item.id));
    let exportPromise = new Promise((resolve, reject) => {
      resolve(exportToCSV(selectRowsCSV, columnHeader, "tabular_export_data_"));
    });
    exportPromise
      .then((res) => {
        setTimeout(async function () {
          handleMenuClose();
          setsnackOpen(true);
          setsnackMessage({
            message: "Export has started, Please check the browser.",
            severity: "success",
          });
        }, 1000);
      })
      .catch((err) => {});
  };

  return (
    <>
      <Button startIcon={<ExportIcon />} onClick={handleMenuOpen}>
        Export Records
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={exportAllRows}>Export All Rows</MenuItem>
        <MenuItem onClick={exportFilteredRows}>Export Filtered Rows</MenuItem>
        <MenuItem onClick={exportSelectedRows}>Export Selected Rows</MenuItem>
      </Menu>
    </>
  );
};

const CustomEmergencyExport = ({ rows, setsnackOpen, setsnackMessage }) => {
  const apiRef = useGridApiContext();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const ExportIcon = createSvgIcon(
    <path d="M19 12v7H5v-7H3v7c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-7h-2zm-6 .67l2.59-2.58L17 11.5l-5 5-5-5 1.41-1.41L11 12.67V3h2z" />,
    "SaveAlt"
  );

  const columnHeaderForEmergency = [
    {
      field: "personID",
      headerName: "personID",
      sortable: true,
      width: 150,
      editable: false,
    },
  ];

  const exportFilteredRows = () => {
    const filtered_rows = apiRef.current.state.visibleRowsLookup;
    const fil_rows_ids = [];
    for (var key in filtered_rows) {
      if (filtered_rows[key] === true) {
        fil_rows_ids.push(key);
      }
    }

    const selectRowsCSV = rows.filter((item) => fil_rows_ids.includes(item.id));

    // Create a Set of unique personId values
    const uniquePersonIdsSet = new Set(
      selectRowsCSV.map((item) => item.personID)
    );

    // Convert the Set to an array of objects with personId property
    const uniquePersonIds = Array.from(uniquePersonIdsSet).map((id) => ({
      personID: id,
    }));

    let exportPromise = new Promise((resolve, reject) => {
      resolve(
        exportToCSV(
          uniquePersonIds,
          columnHeaderForEmergency,
          "tabular_emergency_report_"
        )
      );
    });
    exportPromise
      .then((res) => {
        setTimeout(async function () {
          handleMenuClose();
          setsnackOpen(true);
          setsnackMessage({
            message: "Export has started, Please check the browser.",
            severity: "success",
          });
        }, 1000);
      })
      .catch((err) => {});
  };

  const exportAllRows = () => {
    const allRows = apiRef.current.getAllRowIds();

    const selectRowsCSV = rows.filter((item) => allRows.includes(item.id));

    // Create a Set of unique personId values
    const uniquePersonIdsSet = new Set(
      selectRowsCSV.map((item) => item.personID)
    );

    // Convert the Set to an array of objects with personId property
    const uniquePersonIds = Array.from(uniquePersonIdsSet).map((id) => ({
      personID: id,
    }));

    let exportPromise = new Promise((resolve, reject) => {
      resolve(
        exportToCSV(
          uniquePersonIds,
          columnHeaderForEmergency,
          "tabular_emergency_report_"
        )
      );
    });
    exportPromise
      .then((res) => {
        setTimeout(async function () {
          handleMenuClose();
          setsnackOpen(true);
          setsnackMessage({
            message: "Export has started, Please check the browser.",
            severity: "success",
          });
        }, 1000);
      })
      .catch((err) => {});
  };

  const exportSelectedRows = () => {
    const selectedRowIds = apiRef.current.getSelectedRows();
    const rowIds = Array.from(selectedRowIds.keys());

    const selectRowsCSV = rows.filter((item) => rowIds.includes(item.id));

    const uniquePersonIdsSet = new Set(
      selectRowsCSV.map((item) => item.personID)
    );

    // Convert the Set to an array of objects with personId property
    const uniquePersonIds = Array.from(uniquePersonIdsSet).map((id) => ({
      personID: id,
    }));

    let exportPromise = new Promise((resolve, reject) => {
      resolve(
        exportToCSV(
          uniquePersonIds,
          columnHeaderForEmergency,
          "tabular_emergency_report_"
        )
      );
    });
    exportPromise
      .then((res) => {
        setTimeout(async function () {
          handleMenuClose();
          setsnackOpen(true);
          setsnackMessage({
            message: "Export has started, Please check the browser.",
            severity: "success",
          });
        }, 1000);
      })
      .catch((err) => {});
  };

  return (
    <>
      <Button startIcon={<ExportIcon />} onClick={handleMenuOpen}>
        Export Emergency Records
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={exportAllRows}>Export All Rows</MenuItem>
        <MenuItem onClick={exportFilteredRows}>Export Filtered Rows</MenuItem>
        <MenuItem onClick={exportSelectedRows}>Export Selected Rows</MenuItem>
      </Menu>
    </>
  );
};

const TabularData2 = ({
  rows = [],
  setRows,
  columns = [],
  selectRows,
  setSelectedRows,
  isGirdEditable = false,
  rowModesModel = [],
  setRowModesModel,
  processRowUpdate,
  handleProcessRowUpdateError,
  finalSubmission,
  setfinalSubmission,
  setsnackOpen,
  setsnackMessage,
  columnHeader,
}) => {
  const [rowSelectionModel, setRowSelectionModel] = React.useState([]);
  // const { user } = useAuth();

  const [open, setOpen] = React.useState(false);
  const [saveClicked, setsaveClicked] = useState(false);

  const onRowsSelectionHandler = (rowId) => {
    const selectedRowsData = rowId.map((id) =>
      rows.find((row) => {
        return row.id === id;
      })
    );

    setSelectedRows(selectedRowsData);
    setRowSelectionModel(rowId);
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const onLoadHiddenColumns = {
    dataSource: false,
    primaryMobilePhoneNumber: false,
    primaryEmailAddress: false,
    GSSFlag: false,
    principleFlag: false,
    locationTypeName: false,
    geoLocationLat: false,
    geoLocationLong: false,
    hasDisability: false,
    localLanguageProficiency: false,
    invitedBycontactdetails: false,
    updatedDateTime: false,
    last_checked_hotel: false,
    last_checked_hotel_time: false,
    last_checked_location: false,
    last_checked_location_time: false,
    modifiedBy: false,
    modifiedDateTime: false,
  };

  const onMultipleEditrowClick = () => {
    if (selectRows.length > 0) {
      const selectedRowIds = selectRows.map((row) => row.id || row);

      const newModesModel = selectedRowIds.reduce((acc, rowId) => {
        acc[rowId] = { mode: GridRowModes.Edit };
        return acc;
      }, {});

      setRowModesModel({
        ...rowModesModel,
        ...newModesModel,
      });
    } else {
      alert("Please select any row");
    }
  };

  const onMultipleRowsaveclick = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (saveClicked === true) {
      const selectedRowIds = selectRows.map((row) => row.id || row);

      const newModesModel = selectedRowIds.reduce((acc, rowId) => {
        acc[rowId] = { mode: GridRowModes.View };

        return acc;
      }, {});

      setRowModesModel({
        ...rowModesModel,
        ...newModesModel,
      });

      setsaveClicked(false);
      setfinalSubmission(true);
    }
  }, [saveClicked]);

  const onMultipleRowCancelclick = () => {
    const selectedRowIds = selectRows.map((row) => row.id || row);

    const newModesModel = selectedRowIds.reduce((acc, rowId) => {
      acc[rowId] = { mode: GridRowModes.View, ignoreModifications: true };

      return acc;
    }, {});

    setRowModesModel({
      ...rowModesModel,
      ...newModesModel,
    });
  };

  function CustomToolbar({ rows, columnHeader }) {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <CustomExportButton
          rows={rows}
          setsnackOpen={setsnackOpen}
          setsnackMessage={setsnackMessage}
          columnHeader={columnHeader}
        />
        {isGirdEditable === true ? (
          localStorage.getItem("userRole") === REACT_APP_ADMIN_POOL_NAME ? (
            <>
              <Button startIcon={<EditIcon />} onClick={onMultipleEditrowClick}>
                Edit Rows
              </Button>

              <Button
                disabled={selectRows.length <= 0}
                startIcon={<SaveIcon />}
                onClick={onMultipleRowsaveclick}
              >
                Save Rows
              </Button>

              <Button
                startIcon={<CloseIcon />}
                onClick={onMultipleRowCancelclick}
              >
                Cancel Rows
              </Button>
            </>
          ) : null
        ) : localStorage.getItem("userRole") === REACT_APP_ADMIN_POOL_NAME ? (
          <>
            <CustomEmergencyExport
              rows={rows}
              setsnackOpen={setsnackOpen}
              setsnackMessage={setsnackMessage}
            />
          </>
        ) : null}
      </GridToolbarContainer>
    );
  }

  return (
    <Box
      sx={{
        height: "80vh",

        overflowX: "auto",
      }}
    >
      {isGirdEditable === true ? (
        <StyledBox>
          <DataGrid
            // autoHeight // adjusting height of grid
            getRowId={(row) => (row.id ? row.id : row.id)}
            rows={rows}
            columns={columns}
            initialState={{
              columns: {
                columnVisibilityModel: onLoadHiddenColumns,
              },
            }}
            checkboxSelection
            editMode="row"
            disableRowSelectionOnClick
            onRowSelectionModelChange={(rowId) => {
              onRowsSelectionHandler(rowId);
            }}
            rowSelectionModel={rowSelectionModel}
            rowModesModel={rowModesModel}
            onRowModesModelChange={handleRowModesModelChange}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            onProcessRowUpdateError={handleProcessRowUpdateError}
            hideFooterPagination
            slots={{
              toolbar: CustomToolbar,
            }}
            onCellEditStart={(params, event) =>
              (event.defaultMuiPrevented = true)
            }
            onCellDoubleClick={(params, event) =>
              (event.defaultMuiPrevented = true)
            } // Prevent edit on double-click
            disableSelectionOnClick
          />
        </StyledBox>
      ) : (
        <StyledBox>
          <DataGrid
            sx={{
              ".MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root": {
                display: selectRows.length === 0 ? "none" : "block",
              },
            }}
            getRowId={(row) => (row.id ? row.id : row.id)}
            rows={rows}
            autoWidth
            columns={columns}
            initialState={{
              columns: {
                columnVisibilityModel: onLoadHiddenColumns,
              },
            }}
            checkboxSelection
            rowHeight={40}
            disableRowSelectionOnClick
            onRowSelectionModelChange={(rowId) => onRowsSelectionHandler(rowId)}
            rowSelectionModel={rowSelectionModel}
            hideFooterPagination
            slots={{
              toolbar: CustomToolbar,
            }}
            slotProps={{
              toolbar: { rows, columnHeader },
            }}
            rowsPerPageOptions={[]}
            paginationMode="server"
            scrollbarSize={20}
            // hideFooter // for hiding pagination footer
            onCellEditStart={(params, event) =>
              (event.defaultMuiPrevented = true)
            }
            onCellDoubleClick={(params, event) =>
              (event.defaultMuiPrevented = true)
            } // Prevent edit on double-click
            disableSelectionOnClick
          />
        </StyledBox>
      )}
      <AlertDialog
        open={open}
        setOpen={setOpen}
        finalSubmission={finalSubmission}
        setfinalSubmission={setfinalSubmission}
        onMultipleRowCancelclick={onMultipleRowCancelclick}
        setsaveClicked={setsaveClicked}
      />
    </Box>
  );
};

export default TabularData2;
