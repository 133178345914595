// src/theme.js
import { createTheme } from "@mui/material/styles";

// Override or create new styles, colors, palettes...
const theme = createTheme({
  typography: {
    fontFamily: "Visa Dialect, Inter, Roboto, Helvetica, Arial, sans-serif",
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
  palette: {
    primary: {
      main: "#1A1F71", // Change the color of the Navbar
    },
  },
  components: {
    MuiButton: {
      // variants: [
      //   {
      //     props: { variant: "primary" },
      //     style: {
      //       backgroundColor: "#0D54FF", // Set the background color for primary buttons
      //       color: "white", // Set the text color for primary buttons
      //     },
      //   },
      //   {
      //     props: { variant: "secondary" },
      //     style: {
      //       backgroundColor: "#1A1F715", // Set the background color for secondary buttons
      //       color: "white", // Set the text color for secondary buttons
      //     },
      //   },
      // ],
      styleOverrides: {
        root: {
          "&.primary": {
            backgroundColor: "#0D54FF", // Set the background color for primary buttons
            color: "white", // Set the text color for primary buttons
          },
          "&.secondary": {
            backgroundColor: "#1A1F715", // Set the background color for secondary buttons
            color: "white", // Set the text color for secondary buttons
          },
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: "#2A2E69", // Set the background color for the drawer
          color: "#ffffff", // Set the text color for the drawer
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: "black", // Set the default text color for typography
        },
      },
    },
  },
});

export default theme;
