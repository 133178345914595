import React, { useState, useEffect } from "react";
import {
  Grid,
  Stack,
  Card,
  Paper,
  Box,
  Divider,
  Snackbar,
  Alert,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import EventScheduleStack from "../components/EventSchedule/EventScheduleStack";
import SubHeaderFilter from "./../components/common/SubHeaderFilter/SubHeaderFilter";
import {
  fetchEventScheduleOnloadData,
  fetchEventScheduleDataByParameters,
  fetchEventTypeFilters,
} from "../utils/parentservices";
import BackDrop from "../components/common/BackDrop";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setParameters } from "../redux/parametersSlice";
import { setParameters2 } from "../redux/parameters2Slice";

const Item = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  border: "1px solid black",
}));

const EventSchedule = () => {
  const dispatch = useDispatch();
  const [datalist, setDataList] = useState([]);
  const [currentEvents, setcurrentEvents] = useState([]);
  const [upcomingEvents, setupcomingEvents] = useState([]);
  const [pastEvents, setpastEvents] = useState([]);
  const [backdropopen, setbackdropopen] = useState(false);
  const [isAutoRefresh, setIsAutoRefresh] = useState(true);
  const [eventTypeList, seteventTypeList] = useState([]);

  const [snackOpen, setsnackOpen] = useState(false);
  const [snackMessage, setsnackMessage] = useState({
    message: "",
    severity: "success",
  });

  const [filterParameters, setFilterParameters] = useState({
    startDate: null,
    endDate: null,
    startTime: null,
    endTime: null,
    eventType: [],
    isAllDay: false,
  });

  const currentEventLabelColor = {
    text: "Current",
    textcolor: "#34C06D",
    bgColor: "#E7F7ED",
  };

  const upcomingEventLabelColor = {
    text: "Upcoming",
    textcolor: "#F7B600",
    bgColor: "#FCF6E4",
  };

  const PastEventLabelColor = {
    text: "Past",
    textcolor: "#FF5A5A",
    bgColor: "#FCE4E4",
  };

  const reduxParameter = useSelector((state) => state.parameters);

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setsnackOpen(false);
  };

  // for first time - on load
  useEffect(() => {
    const getData = async () => {
      setbackdropopen(true);
      const token = localStorage.getItem("idToken");
      const resp = await fetchEventTypeFilters(token);
      let lastRefreshObj = null;
      if (
        resp?.data?._result &&
        resp?.data?._code === 200 &&
        !resp?.data?._isError
      ) {
        const { eventList, refreshLogsDetails } = resp?.data?._result;

        if (refreshLogsDetails && refreshLogsDetails.length > 0) {
          lastRefreshObj = refreshLogsDetails[0];

          setIsAutoRefresh(lastRefreshObj.isAutoRefresh === 1 ? true : false);
          filterParameters.lastfreshDate = lastRefreshObj.lastRefreshDateTime;
        }

        seteventTypeList(eventList?.map((item) => item.label));
        setFilterParameters({ ...filterParameters });

        // Get current date and time
        const currentDateObj = new Date();
        // Add 30 minutes
        const dateTimeAfterObj = new Date(
          currentDateObj.getTime() + 30 * 60 * 1000
        );

        // Subtract 30 minutes
        const dateTimeBeforeObj = new Date(
          currentDateObj.getTime() - 30 * 60 * 1000
        );

        // Format dates and times
        const formatDate = (dateObj) => {
          const year = dateObj.getFullYear();
          const month = String(dateObj.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
          const day = String(dateObj.getDate()).padStart(2, "0");
          const hours = String(dateObj.getHours()).padStart(2, "0");
          const minutes = String(dateObj.getMinutes()).padStart(2, "0");
          const seconds = String(dateObj.getSeconds()).padStart(2, "0");

          return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        };
        let parameters = null;
        if (reduxParameter) {
          setFilterParameters({
            ...filterParameters,
            startDate: reduxParameter.startDate,
            endDate: reduxParameter.endDate,
            startTime: reduxParameter.startTime,
            endTime: reduxParameter.endTime,
            lastfreshDate: lastRefreshObj.lastRefreshDateTime
              ? lastRefreshObj.lastRefreshDateTime
              : reduxParameter.lastfreshDate,
            isAllDay: reduxParameter.isAllDay,
          });
          parameters = {
            ...filterParameters,
          };
          parameters = {
            ...parameters,
            startDate: reduxParameter.startDate,
            endDate: reduxParameter.endDate,
            startTime: reduxParameter.startTime,
            endTime: reduxParameter.endTime,
            isAllDay: reduxParameter.isAllDay,
          };
        } else {
          setFilterParameters({
            ...filterParameters,
            startDate: formatDate(currentDateObj),
            endDate: formatDate(currentDateObj),
            startTime: formatDate(dateTimeBeforeObj),
            endTime: formatDate(dateTimeAfterObj),
            lastfreshDate: lastRefreshObj.lastRefreshDateTime
              ? lastRefreshObj.lastRefreshDateTime
              : formatDate(currentDateObj),
            isAllDay: false,
          });
          parameters = {
            ...filterParameters,
          };
          parameters = {
            ...parameters,
            startDate: formatDate(currentDateObj),
            endDate: formatDate(currentDateObj),
            startTime: formatDate(dateTimeBeforeObj),
            endTime: formatDate(dateTimeAfterObj),
            isAllDay: false,
          };
        }

        // Fixed here for destroy error :- We can't call async directoly on useEffect

        fetchDataOnLoad(parameters);
      }
    };
    getData();
  }, []);

  const fetchDataOnLoad = async (parameters) => {
    const parameter = {
      inputParam: {
        ...parameters,
      },
    };

    try {
      //setbackdropopen(true);
      const token = localStorage.getItem("idToken");
      const response = await fetchEventScheduleOnloadData(parameter, token);
      if (
        response?.data?._result &&
        response?.data?._code === 200 &&
        !response?.data?._isError
      ) {
        const scheduleData = response.data._result.eventScheduleList;
        const eventType = response.data._result.eventTypeList;

        setDataList(scheduleData);

        setData(scheduleData);
      } else {
      }
    } catch (error) {
      console.log("Error EventSchedule onLoad : ", error);
    }
    setbackdropopen(false);
  };

  // Custom Filter logic , for any further change in date-time filter
  const onDateSelectionChange = async (dataFromChild) => {
    let parameters = {
      ...filterParameters,
    };
    parameters = {
      ...parameters,
      startDate: dataFromChild.startDate,
      endDate: dataFromChild.endDate,
      startTime: dataFromChild.startTime,
      endTime: dataFromChild.endTime,
      eventType: dataFromChild.eventType,
      isAllDay: dataFromChild.isAllDay,
    };
    setFilterParameters({
      ...filterParameters,
      startDate: dataFromChild.startDate,
      endDate: dataFromChild.endDate,
      startTime: dataFromChild.startTime,
      endTime: dataFromChild.endTime,
      eventType: dataFromChild.eventType,
      isAllDay: dataFromChild.isAllDay,
    });
    dispatch(setParameters({ ...parameters }));
    dispatch(setParameters2({ ...parameters }));

    await fetcheventScheduleDataByFilters(parameters);
  };

  const setData = (scheduleData) => {
    // setting upcoming events
    setupcomingEvents(
      scheduleData.filter((item) => {
        return item.schedule === "upcoming";
      })
    );

    // setting past events
    setpastEvents(
      scheduleData.filter((item) => {
        return item.schedule === "past";
      })
    );

    // setting current events
    setcurrentEvents(
      scheduleData.filter((item) => {
        return item.schedule === "current";
      })
    );
  };

  const fetcheventScheduleDataByFilters = async (parameters) => {
    const parameter = {
      inputParam: {
        ...parameters,
      },
    };

    try {
      setbackdropopen(true);
      const token = localStorage.getItem("idToken");
      const response = await fetchEventScheduleDataByParameters(
        parameter,
        token
      );
      if (
        response?.data?._result &&
        response?.data?._code === 200 &&
        !response?.data?._isError
      ) {
        const scheduleData = response.data._result.eventScheduleList;

        setDataList(scheduleData);

        setData(scheduleData);
      } else {
        console.log("No data");
      }
    } catch (error) {
      console.log("Error EventSchedule Paramemetrs : ", error);
    }
    setbackdropopen(false);
  };

  return (
    <Box sx={{ height: "100vh", width: "100%" }}>
      <SubHeaderFilter
        onDateSelectionChange={onDateSelectionChange}
        filterParameters={filterParameters}
        eventSchedule={true}
        eventTypeListData={eventTypeList || []}
        isAutoRefresh={isAutoRefresh}
      />
      <hr />
      <Stack spacing={3} sx={{ width: "100%", height: "100%" }}>
        <Grid
          container
          spacing={2}
          sx={{ height: "33%", display: "flex", overflow: "auto" }}
        >
          <EventScheduleStack
            events={currentEvents}
            label={currentEventLabelColor}
          />
        </Grid>
        <Divider sx={{ borderBottomWidth: 2, borderColor: "black" }} />
        <Grid
          container
          spacing={2}
          sx={{ height: "33%", display: "flex", overflow: "auto" }}
        >
          <EventScheduleStack
            events={upcomingEvents}
            label={upcomingEventLabelColor}
          />
        </Grid>
        <Divider sx={{ borderBottomWidth: 2, borderColor: "black" }} />
        <Grid
          container
          spacing={2}
          sx={{ height: "33%", display: "flex", overflow: "auto" }}
        >
          <EventScheduleStack events={pastEvents} label={PastEventLabelColor} />
        </Grid>
      </Stack>

      <Snackbar
        open={snackOpen}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackMessage.severity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackMessage.message}
        </Alert>
      </Snackbar>

      <BackDrop backdropopen={backdropopen} />
    </Box>
  );
};

export default EventSchedule;
