import axios from "axios";
import CryptoJS from "crypto-js";
// Encrypt

export const encryptDataFn = async (data) => {
  try {
    // Encrypt
    let ciphertext = CryptoJS.AES.encrypt(
      JSON.stringify(data),
      process.env.REACT_APP_API_DATA_ENCRYPT_KEY
    ).toString();
    return ciphertext;
  } catch (error) {
    console.error("encryptDataFn Error:", error);
  }
};
// Decrypt
export const decryptDataFn = async (encryptData) => {
  try {
    // Decrypt
    let bytes = CryptoJS.AES.decrypt(
      encryptData,
      process.env.REACT_APP_API_DATA_ENCRYPT_KEY
    );
    let decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
  } catch (error) {
    console.error("decryptDataFn Error:", error);
  }
};

const backURL = async () => {
  const domainName = `${window.location.protocol}//${window.location.hostname}`;
  if (domainName === "http://localhost") {
    return "http://localhost:3003/";
  } else if (domainName === "https://visa-vers.fractal.ai") {
    return "https://visa-vers-backend.fractal.ai/";
  } else if (domainName === "https://dev-visa-vers.fractal.ai") {
    return "https://dev-visa-backend.fractal.ai/";
  }
};

const validateResponse = async (resp) => {
  if (
    resp?.data?._result &&
    resp?.data?._code === 200 &&
    !resp?.data?._isError
  ) {
    const responseObj = JSON.parse(resp.data._result);

    const decryptdata = await decryptDataFn(responseObj.encryptData);
    if (decryptdata && decryptdata.success && decryptdata.statusCode === 200) {
      const obj = {
        data: {
          _isError: false,
          _code: 200,
          _message: "success",
          _result: decryptdata.data,
        },
      };
      return obj;
    } else if (
      decryptdata &&
      !decryptdata.success &&
      decryptdata.statusCode === 500 &&
      decryptdata.message === "Token is expired"
    ) {
      localStorage.removeItem("idToken");
      localStorage.removeItem("userRole");
      localStorage.removeItem("userName");
      localStorage.removeItem("_grecaptcha");
      localStorage.removeItem("loginDateTime");
      // Redirect to the login page
      window.location.href = "/login";
    } else if (
      decryptdata &&
      !decryptdata.success &&
      decryptdata.statusCode === 500 &&
      decryptdata.message === "Error decoding token headers."
    ) {
      localStorage.removeItem("idToken");
      // localStorage.removeItem("refreshToken");
      localStorage.removeItem("userRole");
      localStorage.removeItem("userName");
      localStorage.removeItem("_grecaptcha");
      localStorage.removeItem("loginDateTime");
      // Redirect to the login page
      window.location.href = "/login";
    } else {
      const obj = {
        data: {
          _isError: false,
          _code: 200,
          _message: "success",
          _result: decryptdata?.data,
        },
      };
      return obj;
    }
  } else if (resp?.data?._code === 500 && resp?.data?._isError) {
    return resp;
  }
};

const validateResponse2 = async (resp) => {
  if (
    resp?.data?._result &&
    resp?.data?._code === 200 &&
    !resp?.data?._isError
  ) {
    const responseObj = resp.data._result;

    if (responseObj && responseObj.success && responseObj.statusCode === 200) {
      const obj = {
        data: {
          _isError: false,
          _code: 200,
          _message: "success",
          _result: responseObj.data,
        },
      };
      return obj;
    } else if (
      responseObj &&
      !responseObj.success &&
      responseObj.statusCode === 500 &&
      responseObj.message === "Token is expired"
    ) {
      localStorage.removeItem("idToken");
      localStorage.removeItem("userRole");
      localStorage.removeItem("userName");
      localStorage.removeItem("_grecaptcha");
      localStorage.removeItem("loginDateTime");
      // Redirect to the login page
      window.location.href = "/login";
    } else if (
      responseObj &&
      !responseObj.success &&
      responseObj.statusCode === 500 &&
      responseObj.message === "Error decoding token headers."
    ) {
      localStorage.removeItem("idToken");

      localStorage.removeItem("userRole");
      localStorage.removeItem("userName");
      localStorage.removeItem("_grecaptcha");
      localStorage.removeItem("loginDateTime");
      // Redirect to the login page
      window.location.href = "/login";
    } else {
      const obj = {
        data: {
          _isError: false,
          _code: 200,
          _message: "success",
          _result: responseObj?.data,
        },
      };
      return obj;
    }
  } else if (resp?.data?._code === 500 && resp?.data?._isError) {
    return resp;
  }
};

export const fetchFiltersList = async (token) => {
  const BACK_END_URL = await backURL();
  return axios
    .post(
      BACK_END_URL + "fetch-filters-list",
      {},
      {
        headers: {
          Authorization: `${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then(async (response) => {
      return await validateResponse(response);
    })
    .catch((error) => {
      console.log("Error ->", error);
    });
};

export const fetchAllocationManualFeed = async (param, token) => {
  const BACK_END_URL = await backURL();
  const encryptedData = await encryptDataFn(param);
  return axios
    .post(
      BACK_END_URL + "fetch-allocation-manual-feed",
      JSON.stringify({ encryptedData }),
      {
        headers: {
          Authorization: `${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then(async (response) => {
      return await validateResponse2(response);
    })
    .catch((error) => {
      console.log("Error ->", error);
    });
};

export const fetchFirstTimeAllocationManualFeed = async (param, token) => {
  const BACK_END_URL = await backURL();
  const encryptedData = await encryptDataFn(param);
  return axios
    .post(
      BACK_END_URL + "fetch-first-time-allocation-manual-feed",
      JSON.stringify({ encryptedData }),
      {
        headers: {
          Authorization: `${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then(async (response) => {
      return await validateResponse2(response);
    })
    .catch((error) => {
      console.log("Error ->", error);
    });
};

export const fetchAllocationEventFeed = async (param, token) => {
  const BACK_END_URL = await backURL();
  const encryptedData = await encryptDataFn(param);
  return axios
    .post(
      BACK_END_URL + "fetch-allocation-event-feed",
      JSON.stringify({ encryptedData }),
      {
        headers: {
          Authorization: `${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then(async (response) => {
      return await validateResponse2(response);
    })
    .catch((error) => {
      console.error("Error ->", error);
    });
};

export const fetchConflictsReport = async (param, token) => {
  const BACK_END_URL = await backURL();
  const encryptedData = await encryptDataFn(param);
  return axios
    .post(
      BACK_END_URL + "fetch-conflicts-report",
      JSON.stringify({ encryptedData }),
      {
        headers: {
          Authorization: `${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then(async (response) => {
      // return response;
      return await validateResponse(response);
    })
    .catch((error) => {
      console.error("Error ->", error);
    });
};

export const fetchEmergencyReport = async (param, token) => {
  const BACK_END_URL = await backURL();
  const encryptedData = await encryptDataFn(param);
  return axios
    .post(
      BACK_END_URL + "fetch-emergency-report",
      JSON.stringify({ encryptedData }),
      {
        headers: {
          Authorization: `${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then(async (response) => {
      return await validateResponse(response);
    })
    .catch((error) => {
      console.log("Error ->", error);
    });
};

export const postAllocationFeed = async (param, token) => {
  const BACK_END_URL = await backURL();
  const encryptedData = await encryptDataFn(param);
  return axios
    .post(
      BACK_END_URL + "post-allocation-feed-data",
      JSON.stringify({ encryptedData }),
      {
        headers: {
          Authorization: `${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then(async (response) => {
      return await validateResponse(response);
    })
    .catch((error) => {
      console.log("Error ->", error);
    });
};

export const fetchOverviewTabularDataByParameters = async (param, token) => {
  const BACK_END_URL = await backURL();
  const encryptedData = await encryptDataFn(param);
  return axios
    .post(
      BACK_END_URL + "fetch-overview-tabular-data",
      JSON.stringify({ encryptedData }),
      {
        headers: {
          Authorization: `${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then(async (response) => {
      return await validateResponse2(response);
    })
    .catch((error) => {
      console.log("Error ->", error);
    });
};

export const fetchFirstTimeOverviewTabularDataByParameters = async (
  param,
  token
) => {
  const BACK_END_URL = await backURL();
  const encryptedData = await encryptDataFn(param);
  return axios
    .post(
      BACK_END_URL + "fetch-first-time-overview-tabular-data",
      JSON.stringify({ encryptedData }),
      {
        headers: {
          Authorization: `${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then(async (response) => {
      return await validateResponse2(response);
    })
    .catch((error) => {
      console.log("Error ->", error);
    });
};

export const fetchOverviewGMapOnloadData = async (param, token) => {
  const BACK_END_URL = await backURL();
  const encryptedData = await encryptDataFn(param);

  return axios
    .post(
      BACK_END_URL + "fetch-overview-gmap-onload-data",
      JSON.stringify({ encryptedData }),
      {
        headers: {
          Authorization: `${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then(async (response) => {
      return await validateResponse(response);
    })
    .catch((error) => {
      console.log("Error ->", error);
    });
};

export const fetchOverviewGMapDataByParameters = async (param, token) => {
  const BACK_END_URL = await backURL();
  const encryptedData = await encryptDataFn(param);
  return axios
    .post(
      BACK_END_URL + "fetch-overview-gmap-data-by-filters",
      JSON.stringify({ encryptedData }),
      {
        headers: {
          Authorization: `${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then(async (response) => {
      return await validateResponse(response);
    })
    .catch((error) => {
      console.log("Error ->", error);
    });
};

export const fetchAutocompleteLocationsData = async (param, token) => {
  const BACK_END_URL = await backURL();
  const encryptedData = await encryptDataFn(param);
  return axios
    .post(
      BACK_END_URL + "fetch-autocomplete-locations-data",
      JSON.stringify({ encryptedData }),
      {
        headers: {
          Authorization: `${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then(async (response) => {
      return await validateResponse(response);
    })
    .catch((error) => {
      console.log("Error ->", error);
    });
};

export const fetchEventScheduleOnloadData = async (param, token) => {
  const BACK_END_URL = await backURL();
  const encryptedData = await encryptDataFn(param);
  return axios
    .post(
      BACK_END_URL + "fetch-event-schedule-onload-data",
      JSON.stringify({ encryptedData }),
      {
        headers: {
          Authorization: `${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then(async (response) => {
      return await validateResponse(response);
    })
    .catch((error) => {
      console.log("Error ->", error);
    });
};

export const fetchEventTypeFilters = async (token) => {
  const BACK_END_URL = await backURL();
  return axios
    .post(
      BACK_END_URL + "fetch-event-schedule-eventType-filters",
      {},
      {
        headers: {
          Authorization: `${token}`,
          "Content-Type": "application/json",
        },
      }
    )

    .then(async (response) => {
      return await validateResponse(response);
    })
    .catch((error) => {
      console.error("Error ->", error);
    });
};

export const fetchEventScheduleDataByParameters = async (param, token) => {
  const BACK_END_URL = await backURL();
  const encryptedData = await encryptDataFn(param);
  return axios
    .post(
      BACK_END_URL + "fetch-event-schedule-data-by-filters",
      JSON.stringify({ encryptedData }),
      {
        headers: {
          Authorization: `${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then(async (response) => {
      return await validateResponse(response);
      // return response;
    })
    .catch((error) => {
      console.log("Error ->", error);
    });
};

export const trigeerGmrIntegrationOnRefresh = async (param, token) => {
  const BACK_END_URL = await backURL();
  //const encryptedData = await encryptDataFn(param);
  return axios
    .post(
      BACK_END_URL + "get-gmr-visa-data",
      // JSON.stringify({ encryptedData }),
      //param,
      {},
      {
        headers: {
          Authorization: `${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then(async (response) => {
      return await validateResponse(response);
      // return response;
    })
    .catch((error) => {
      console.log("Error ->", error);
    });
};

export const fetchAllocationDropdownList = async (token) => {
  const BACK_END_URL = await backURL();
  return (
    axios
      //.get(BACK_END_URL + "fetch-allocation-drop-down-list")
      .post(
        BACK_END_URL + "fetch-allocation-drop-down-list",
        {},
        {
          headers: {
            Authorization: `${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then(async (response) => {
        return await validateResponse(response);
      })
      .catch((error) => {
        console.log("Error ->", error);
      })
  );
};

// AWS cognito
export const awsloginuser = async (param) => {
  const BACK_END_URL = await backURL();
  const encryptedData = await encryptDataFn(param);
  return axios
    .post(BACK_END_URL + "login-user", JSON.stringify({ encryptedData }), {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then(async (response) => {
      return await validateResponse(response);
    })
    .catch((error) => {
      console.log("Error ->", error);
    });
};

export const awssignupuser = async (param) => {
  const BACK_END_URL = await backURL();
  const encryptedData = await encryptDataFn(param);
  return axios
    .post(
      BACK_END_URL + "signup-user",
      JSON.stringify({ encryptedData }),

      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then(async (response) => {
      return await validateResponse(response);
    })
    .catch((error) => {
      console.log("Error ->", error);
    });
};

export const awsAdminChangesRole = async (param, token) => {
  const BACK_END_URL = await backURL();
  const encryptedData = await encryptDataFn(param);
  return axios
    .post(
      BACK_END_URL + "add-user-to-group",
      JSON.stringify({ encryptedData }),
      {
        headers: {
          Authorization: `${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then(async (response) => {
      return await validateResponse(response);
      // return response;
    })
    .catch((error) => {
      console.log("Error ->", error);
    });
};

export const awsconfirmuser = async (param) => {
  const BACK_END_URL = await backURL();
  const encryptedData = await encryptDataFn(param);
  return axios
    .post(
      BACK_END_URL + "confirm-user",
      JSON.stringify({ encryptedData }),

      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then(async (response) => {
      return await validateResponse(response);
    })
    .catch((error) => {
      console.log("Error ->", error);
    });
};

export const awsforgetpassword = async (param) => {
  const BACK_END_URL = await backURL();
  const encryptedData = await encryptDataFn(param);
  return axios
    .post(BACK_END_URL + "forget-password", JSON.stringify({ encryptedData }), {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then(async (response) => {
      return await validateResponse(response);
    })
    .catch((error) => {
      console.log("Error ->", error);
    });
};

export const awsforgetpasswordconfirmation = async (param) => {
  const BACK_END_URL = await backURL();
  const encryptedData = await encryptDataFn(param);
  return axios
    .post(
      BACK_END_URL + "forget-password-confirmation",
      JSON.stringify({ encryptedData }),
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then(async (response) => {
      return await validateResponse(response);
    })
    .catch((error) => {
      console.log("Error ->", error);
    });
};

export const awsListOfUsersForDashboard = async (param, token) => {
  const BACK_END_URL = await backURL();
  const encryptedData = await encryptDataFn(param);
  return axios
    .post(BACK_END_URL + "get-users-list", JSON.stringify({ encryptedData }), {
      headers: {
        Authorization: `${token}`,
        "Content-Type": "application/json",
      },
    })
    .then(async (response) => {
      return await validateResponse(response);
    })
    .catch((error) => {
      console.log("Error ->", error);
    });
};

export const awsSignOut = async (param, token) => {
  const BACK_END_URL = await backURL();
  const encryptedData = await encryptDataFn(param);
  return axios
    .post(BACK_END_URL + "aws-sign-out", JSON.stringify({ encryptedData }), {
      headers: {
        Authorization: `${token}`,
        "Content-Type": "application/json",
      },
    })
    .then(async (response) => {
      return await validateResponse(response);
    })
    .catch((error) => {
      console.log("Error ->", error);
    });
};

export const fetchFirstTimeAllocationEventFeed = async (param, token) => {
  const BACK_END_URL = await backURL();
  const encryptedData = await encryptDataFn(param);
  return axios
    .post(
      BACK_END_URL + "fetch-first-time-allocation-event-feed",
      JSON.stringify({ encryptedData }),
      {
        headers: {
          Authorization: `${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then(async (response) => {
      return await validateResponse2(response);
    })
    .catch((error) => {
      console.error("Error ->", error);
    });
};
