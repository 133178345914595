import {
  Box,
  Grid,
  Button,
  TextField,
  Typography,
  InputAdornment,
  Link,
  Snackbar,
  Alert,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import PersonIcon from "@mui/icons-material/Person";
import LockIcon from "@mui/icons-material/Lock";
import { awssignupuser, awsconfirmuser } from "../../utils/parentservices.js";
import { REACT_APP_RE_CAPTCHA_KEY } from "../../config/config.js";
import BackDrop from "../common/BackDrop.js";
import ReCAPTCHA from "react-google-recaptcha";

const SignupUser = () => {
  const navigate = useNavigate();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [captchaToken, setCaptchaToken] = useState(null);
  const [captchaToken2, setCaptchaToken2] = useState(null);
  const [passwordErr, setPasswordErr] = useState("");
  const [usernameErr, setUsernameErr] = useState("");
  const [recaptchaErr, setRecaptchaErr] = useState("");
  const [recaptchaErr2, setRecaptchaErr2] = useState("");
  const [code, setCode] = useState("");
  const [showConfirm, setShowConfirm] = useState(false);
  const [signupErr, setsignupErr] = useState("");
  const [snackOpen, setsnackOpen] = useState(false);
  const [recaptchaFlag, setRecaptchaFlag] = useState(false);
  const [recaptchaFlag2, setRecaptchaFlag2] = useState(false);
  const [snackMessage, setsnackMessage] = useState({
    message: "",
    severity: "success",
  });
  const [backdropopen, setbackdropopen] = useState(false);

  const validation = () => {
    return new Promise((resolve, reject) => {
      if (password === "" && username === "" && !recaptchaFlag) {
        setPasswordErr("Password is required!");
        setUsernameErr("Username is required!");
        setRecaptchaErr("Please select recaptcha");
        resolve({
          password: "Password is required",
          username: "Username is required",
          recaptcha: "Please select recaptcha",
        });
      } else if (password === "") {
        setPasswordErr("Password is required!");
        resolve({
          password: "Password is required",
          username: "",
        });
      } else if (password.length < 8) {
        setPasswordErr("Password must be greater than 8 characters!");
        resolve({
          password: "Password must be greater than 8 characters!",
        });
      } else if (username.length <= 3) {
        setUsernameErr("Username must be atleast 3 characters!");
        resolve({
          password: "",
          username: "Username length must be greater than 3 charecters",
        });
      } else if (!recaptchaFlag) {
        setRecaptchaErr("Please select recaptcha");
        resolve({
          recaptcha: "Please select recaptcha",
        });
      } else {
        resolve({
          password: "",
          username: "",
          recaptcha: "",
        });
      }
      reject("");
    });
  };

  const onRecaptchaChange = (token) => {
    setRecaptchaFlag(!recaptchaFlag);
    setCaptchaToken(token);
  };

  const onRecaptchaChange2 = (token) => {
    setRecaptchaFlag2(!recaptchaFlag2);
    setCaptchaToken2(token);
  };

  const handleSignup = async (e) => {
    setPasswordErr("");
    setUsernameErr("");
    setRecaptchaErr("");
    setsignupErr("");
    try {
      setbackdropopen(true);
      const res = await validation();
      if (res.username === "" && res.password === "" && res.recaptcha === "") {
        try {
          setsignupErr("");

          const param = {
            username: username,
            captchaToken: captchaToken,
            password: password,
          };
          const response = await awssignupuser(param);
          if (
            response?.data?._result &&
            response?.data?._code === 200 &&
            !response?.data?._isError
          ) {
            const resp = response?.data?._result;
            if (resp && resp.statusCode && resp.success) {
              setsnackOpen(true);
              setsnackMessage({
                message: "Code sent, Please connect with admin!!",
                severity: "success",
              });
              setShowConfirm(true);
            } else if (resp && resp.statusCode && !resp.success) {
              setsignupErr(resp.message);
            } else {
              setsignupErr("Please try after sometime!");
            }
          } else {
            setsignupErr("Something went wrong! Please try again later");
          }
        } catch (e) {
          setsignupErr(e);
        }
      } else {
        setPasswordErr(res.password);
        setUsernameErr(res.username);
        setRecaptchaErr(res.recaptcha);
      }
      setbackdropopen(false);
    } catch (err) {
      console.log(err);
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setsnackOpen(false);
  };

  const handleConfirmUser = async (e) => {
    if (code.length < 0 || !recaptchaFlag2) {
      if (code.length < 0) {
        setsnackOpen(true);
        setsnackMessage({
          message: "Please enter confirmation code",
          severity: "info",
        });
      }
      if (!recaptchaFlag2) {
        setsnackOpen(true);
        setsnackMessage({
          message: "Please select recaptcha",
          severity: "info",
        });
        setRecaptchaErr2("Please select recaptcha");
      }
    } else {
      setbackdropopen(true);
      try {
        const param = {
          username: username,
          confirmationCode: code,
          captchaToken: captchaToken2,
        };
        const response = await awsconfirmuser(param);
        if (
          response?.data?._result &&
          response?.data?._code === 200 &&
          !response?.data?._isError
        ) {
          const resp = response?.data?._result;
          if (resp && resp.statusCode && resp.success) {
            setsnackOpen(true);
            setsnackMessage({
              message: "Successfully registered!! Redirecting to login page...",
              severity: "success",
            });

            // Delay navigation to allow snackbar to be visible
            setTimeout(() => {
              navigate("/login");
            }, 2000); // Adjust the delay as needed (2 seconds in this case)
          } else if (resp && resp.statusCode && !resp.success) {
            setsnackOpen(true);
            setsnackMessage({
              message: resp.message,
              severity: "error",
            });
          } else {
            setsnackOpen(true);
            setsnackMessage({
              message: "Please try after sometime!!",
              severity: "error",
            });
          }
        }
      } catch (error) {
        setsignupErr(error);
      }
    }
    setbackdropopen(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent the default form submission
      handleSignup();
    }
  };

  const handleKeyDownConfirm = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent the default form submission
      handleConfirmUser();
    }
  };

  return (
    <Box sx={{ width: "350px", height: "400px", marginTop: "-90px" }}>
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={1}
        sx={{ width: "100%" }}
      >
        {/* Welcome text */}
        <Grid item sx={{ width: "100%" }}>
          <Typography variant="h5" fontWeight="bold">
            Sign Up
          </Typography>
        </Grid>

        {/*  Username */}
        <Grid item sx={{ width: "100%" }}>
          <Grid container spacing={1}>
            <Grid item>
              <Typography variant="body1" fontWeight="bold">
                Username
              </Typography>
            </Grid>
            <Grid item sx={{ width: "88%" }}>
              <TextField
                fullWidth
                id="fullWidth"
                placeholder="Firstname.Lastname"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PersonIcon />
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => setUsername(e.target.value)}
                onKeyDown={handleKeyDown}
              ></TextField>
            </Grid>
          </Grid>
        </Grid>

        {/* Password  */}
        <Grid item sx={{ width: "100%" }}>
          <Grid container spacing={1}>
            <Grid item>
              <Typography variant="body1" fontWeight="bold">
                Password
              </Typography>
            </Grid>
            <Grid item sx={{ width: "88%" }} component="form">
              <TextField
                fullWidth
                placeholder="Password"
                id="fullWidth"
                type="password"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockIcon />
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => setPassword(e.target.value)}
                onKeyDown={handleKeyDown}
              ></TextField>
            </Grid>
          </Grid>
        </Grid>
        {/* ReCAPTCHA  */}
        {!showConfirm && (
          <Grid item sx={{ width: "100%" }}>
            <Grid container spacing={1}>
              <Grid item>
                <Typography variant="body1">
                  <ReCAPTCHA
                    sitekey={REACT_APP_RE_CAPTCHA_KEY}
                    //onChange={(val) => setRecaptchaFlag(val)}
                    onChange={onRecaptchaChange}
                  />
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        )}

        {/* SIgnup Button */}
        <Grid item sx={{ width: "100%" }}>
          <Button
            variant="contained"
            className="primary"
            sx={{ width: "88%" }}
            disabled={!recaptchaFlag}
            onClick={handleSignup}
          >
            SignUp
          </Button>
        </Grid>

        <Grid
          item
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            typography: "body1",
            "& > :not(style) ~ :not(style)": {
              ml: 2,
            },
            width: "100%",
          }}
          // onClick={(event) => event.preventDefault()}
        >
          <Link href="/" underline="hover">
            {"Already have an account ?"}
          </Link>
        </Grid>

        {showConfirm && (
          <Grid item sx={{ width: "100%" }}>
            <Grid
              container
              direction={"row"}
              spacing={1}
              alignItems={"center"}
              justifyContent={"center"}
            >
              {/* ReCAPTCHA  */}
              <Grid item sx={{ width: "100%" }}>
                <Grid container>
                  <Grid item>
                    <Typography variant="body1">
                      <ReCAPTCHA
                        sitekey={REACT_APP_RE_CAPTCHA_KEY}
                        //onChange={(val) => setRecaptchaFlag2(val)}
                        onChange={onRecaptchaChange2}
                      />
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item sx={{ width: "50%" }}>
                <TextField
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                  label="Confirmation code"
                  onKeyDown={handleKeyDownConfirm}
                />
              </Grid>
              <Grid item sx={{ width: "50%" }}>
                <Button
                  variant="contained"
                  className="primary"
                  sx={{ width: "100%" }}
                  disabled={!recaptchaFlag2}
                  onClick={handleConfirmUser}
                >
                  Confirm User
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
        <hr />
        {signupErr && (
          <Typography sx={{ color: "red" }}>{signupErr}</Typography>
        )}
        {usernameErr && (
          <Typography sx={{ color: "red" }}>{usernameErr}</Typography>
        )}
        {passwordErr && (
          <Typography sx={{ color: "red" }}>{passwordErr}</Typography>
        )}
        {recaptchaErr && (
          <Typography sx={{ color: "red" }}>{recaptchaErr}</Typography>
        )}
        {recaptchaErr2 && (
          <Typography sx={{ color: "red" }}>{recaptchaErr2}</Typography>
        )}
      </Grid>
      <Snackbar
        open={snackOpen}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackMessage.severity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackMessage.message}
        </Alert>
      </Snackbar>
      <BackDrop backdropopen={backdropopen} />
    </Box>
  );
};

export default SignupUser;
