import { createSlice } from "@reduxjs/toolkit";

const tabularSlice = createSlice({
  name: "tabularData",
  initialState: null,
  reducers: {
    setTabularData: (state, action) => {
      state = action.payload;
      return action.payload;
    },
  },
});

export const { setTabularData } = tabularSlice.actions;

export default tabularSlice.reducer;
