import {
  Grid,
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
  Stack,
  Alert,
  Snackbar,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  awsListOfUsersForDashboard,
  awsAdminChangesRole,
} from "../utils/parentservices.js";
import BackDrop from "../components/common/BackDrop.js";
import ReCAPTCHA from "react-google-recaptcha";

const AdminDashboard = () => {
  const [recaptcha, setRecaptcha] = useState("");
  const [recaptchaFlag, setRecaptchaFlag] = useState(false);
  const [captchaToken, setCaptchaToken] = useState(null);
  const [recaptchaErr, setRecaptchaErr] = useState("");
  const [showUsers, setShowUsers] = useState(false);

  const [rows, setRows] = useState([]);
  const [fetchFlag, setfetchFlag] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [userPermfromText, setuserPermfromText] = useState("");
  const [userPermToText, setuserPermToText] = useState("");
  const [action, setAction] = useState({ action: null, rowData: null });
  const [backdropopen, setbackdropopen] = useState(false);
  const [snackOpen, setsnackOpen] = useState(false);
  const [snackMessage, setsnackMessage] = useState({
    message: "",
    severity: "success",
  });

  const columns = [
    {
      field: "id",
      headerName: "S.No",
      description: "This column has a value getter and is not sortable.",
      sortable: true,
      width: 100,
    },
    {
      field: "UserName",
      headerName: "User Name",
      description: "This column has a value getter and is not sortable.",
      sortable: true,
      flex: 0.2,
    },
    {
      field: "CurrentGroup",
      headerName: "Current Role",
      description: "This column has a value getter and is not sortable.",
      sortable: true,
      flex: 0.2,
    },
    {
      field: "ChangePerm",
      headerName: "Change User Permission",
      flex: 0.3,
      renderCell: (params) => {
        return params.row.CurrentGroup ===
          process.env.REACT_APP_ADMIN_POOL_NAME ? (
          <Button
            variant="contained"
            size="medium"
            disabled={localStorage.getItem("userName") === params.row.UserName}
            sx={{
              backgroundColor: "#FFF2D2",
              color: "#D19A01",
              "&:hover": {
                backgroundColor: "#F7B600", // Color on hover
                color: "#FFFFFF",
              },
              "&:active": {
                backgroundColor: "#F7B600", // Color on active
                color: "#FFFFFF",
              },
            }}
            onClick={() => handleToggel(params)}
            color="error"
          >
            <Typography color="inherit">{"Onsite-Team"}</Typography>
          </Button>
        ) : (
          <Button
            variant="contained"
            size="medium"
            disabled={localStorage.getItem("userName") === params.row.UserName}
            sx={{
              backgroundColor: "#C7CAF6",
              color: "#2A2E69",
              "&:hover": {
                backgroundColor: "#2A2E69", // Color on hover
                color: "#FFFFFF",
              },
              "&:active": {
                backgroundColor: "#2A2E69", // Color on active
                color: "#FFFFFF",
              },
            }}
            onClick={() => handleToggel(params)}
            color="success"
          >
            <Typography color="inherit">{"Commanding-Team"}</Typography>
          </Button>
        );
      },
    },
  ];

  const onRecaptchaChange = (token) => {
    setRecaptchaFlag(!recaptchaFlag);
    setCaptchaToken(token);
  };
  const handleClose = () => {
    setOpen(false);
    setAction({ action: null, rowData: null });
  };

  const handleClickOpen = (actionType, params) => {
    if (actionType === "add") {
      setuserPermToText(process.env.REACT_APP_ADMIN_POOL_NAME);
      setuserPermfromText(process.env.REACT_APP_VIEW_POOL_NAME);
    } else {
      setuserPermToText(process.env.REACT_APP_VIEW_POOL_NAME);
      setuserPermfromText(process.env.REACT_APP_ADMIN_POOL_NAME);
    }
    setAction({ action: actionType, rowData: params });
    setOpen(true);
  };

  const handleToggel = (params) => {
    if (params.row.CurrentGroup === process.env.REACT_APP_ADMIN_POOL_NAME) {
      handleClickOpen("remove", params);
    } else if (
      params.row.CurrentGroup === process.env.REACT_APP_VIEW_POOL_NAME
    ) {
      handleClickOpen("add", params);
    } else {
      console.error("User group not identified!");
    }
  };

  const handleAgree = () => {
    if (action.action === "add") {
      // Perform add user actions here
      setbackdropopen(true);
      changeToAdminOnClick(action.rowData);
      setbackdropopen(false);
    } else if (action.action === "remove") {
      // Perform remove user actions here
      setbackdropopen(true);
      removeFromAdminOnClick(action.rowData);
      setbackdropopen(false);
    }

    setOpen(false); // Close the dialog
    setAction({ action: null, rowData: null });
  };

  const changeToAdminOnClick = async (params) => {
    try {
      const parameter = {
        inputParam: {
          username: params.row.UserName,
          requestType: "changeToAdmin",
          currentGroup: process.env.REACT_APP_VIEW_POOL_NAME,
          nextGroup: process.env.REACT_APP_ADMIN_POOL_NAME,
        },
      };
      const token = localStorage.getItem("idToken");
      const response = await awsAdminChangesRole(parameter, token);
      if (
        response?.data?._result &&
        response?.data?._code === 200 &&
        !response?.data?._isError
      ) {
        const resp = response?.data?._result;
        if (resp && resp.statusCode && resp.success) {
          setsnackOpen(true);
          setsnackMessage({
            message: resp.message,
            severity: "success",
          });
        }
      } else {
        setsnackOpen(true);
        setsnackMessage({
          message: "Please try after sometime!!",
          severity: "error",
        });
      }

      setfetchFlag(true);
    } catch (error) {
      console.log("Permission change error : ", error);
    }
  };

  const removeFromAdminOnClick = async (params) => {
    try {
      const parameter = {
        inputParam: {
          username: params.row.UserName,
          requestType: "removeFromAdmin",
          currentGroup: process.env.REACT_APP_ADMIN_POOL_NAME,
          nextGroup: process.env.REACT_APP_VIEW_POOL_NAME,
        },
      };
      const token = localStorage.getItem("idToken");
      const response = await awsAdminChangesRole(parameter, token);
      if (
        response?.data?._result &&
        response?.data?._code === 200 &&
        !response?.data?._isError
      ) {
        const resp = response?.data?._result;
        if (resp && resp.statusCode && resp.success) {
          setsnackOpen(true);
          setsnackMessage({
            message: resp.message,
            severity: "success",
          });
        }
      } else {
        setsnackOpen(true);
        setsnackMessage({
          message: "Please try after sometime!!",
          severity: "error",
        });
      }
      setfetchFlag(true);
    } catch (error) {
      console.log("Permission change error : ", error);
    }
  };

  const validation = () => {
    return new Promise((resolve, reject) => {
      if (!recaptchaFlag) {
        setRecaptchaErr("Please select recaptcha");
        resolve({
          recaptcha: "Please select recaptcha",
        });
      } else {
        resolve({ recaptcha: "" });
      }
      reject("");
    });
  };

  async function getUsersList() {
    setRecaptchaErr("");
    const res = await validation();
    setbackdropopen(true);
    if (res.recaptcha === "") {
      try {
        let usersdata = [];
        const param = {
          captchaToken: captchaToken,
        };
        const token = localStorage.getItem("idToken");
        const response = await awsListOfUsersForDashboard(param, token);

        if (
          response?.data?._result &&
          response?.data?._code === 200 &&
          !response?.data?._isError
        ) {
          const resp = response?.data?._result;

          //if you have prevaligies
          if (resp && resp.statusCode && resp.success) {
            let indexCount = 0;
            usersdata = resp.userList.map((item, index) => {
              return {
                id: index + 1,
                UserName: item.Username,
                CurrentGroup: item.GroupName ? item.GroupName : "NA",
              };
            });
          }
          // if you don't have prevaligies
          else if (resp && resp.statusCode && resp.success === false) {
            setsnackOpen(true);
            setsnackMessage({
              message: resp?.message ? resp.message : "Error!",
              severity: "error",
            });
          } else {
            console.log("Something went wrong!!");
          }
        } else {
          console.log("Error in response : ", response);
        }

        setShowUsers(!showUsers);

        // Step 1: Sort the list based on the UserName property
        usersdata.sort((a, b) => a.UserName.localeCompare(b.UserName));

        // Step 2: Reassign the id properties
        usersdata.forEach((user, index) => {
          user.id = index + 1;
        });

        setRows(usersdata);
      } catch (error) {
        console.error("Error getUsersList : - ", error);
      }
    } else {
      setsnackOpen(true);
      setsnackMessage({
        message: "Please select recaptcha",
        severity: "info",
      });
    }
    setbackdropopen(false);
  }

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setsnackOpen(false);
  };

  useEffect(() => {
    try {
      //getUsersList();
      setRecaptcha("");
      setRecaptchaErr("");
      setCaptchaToken(null);
      setRecaptchaFlag(false);
      setShowUsers(false);
    } catch (error) {
      console.error("Error :- ", error);
    }
    // getUsersList();
    setfetchFlag(false);
  }, [fetchFlag]);

  return (
    <Box
      sx={{
        height: "80vh",
      }}
    >
      <Grid item>
        <Typography>User Permissions</Typography>
      </Grid>

      {showUsers === true && recaptchaFlag === true ? (
        <Grid item sx={{ width: "80%", height: "80vh" }}>
          <DataGrid
            rows={rows}
            autoWidth
            columns={columns}
            rowHeight={40}
            paginationMode="server"
            scrollbarSize={20}
            disableSelectionOnClick
            hideFooter
          />
        </Grid>
      ) : (
        <Stack direction="row">
          <Grid item sx={{ width: "100%" }}>
            <Grid container spacing={1}>
              <Grid item>
                <Typography variant="body1">
                  <ReCAPTCHA
                    sitekey={process.env.REACT_APP_RE_CAPTCHA_KEY}
                    onChange={onRecaptchaChange}
                  />
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sx={{ width: "100%" }}>
            <Button
              variant="contained"
              className="primary"
              disabled={!recaptchaFlag}
              sx={{ width: "88%" }}
              onClick={getUsersList}
            >
              SHOW ALL USERS
            </Button>
            {recaptchaErr && (
              <Typography sx={{ color: "red" }}>{recaptchaErr}</Typography>
            )}
            <Snackbar
              open={snackOpen}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              autoHideDuration={3000}
              onClose={handleSnackbarClose}
            >
              <Alert
                onClose={handleSnackbarClose}
                severity={snackMessage.severity}
                variant="filled"
                sx={{ width: "100%" }}
              >
                {snackMessage.message}
              </Alert>
            </Snackbar>
          </Grid>
          <Grid item sx={{ width: "100%" }}></Grid>
          <Grid item sx={{ width: "100%" }}></Grid>
          <Grid item sx={{ width: "100%" }}></Grid>
          <Grid item sx={{ width: "100%" }}></Grid>
          <Grid item sx={{ width: "100%" }}></Grid>
        </Stack>
      )}

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          <Typography fontWeight={"bold"}>
            {"Change User Permissions"}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography>
              Are you sure to change user permissions from {userPermfromText} to{" "}
              {userPermToText} ?
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            // variant="contained"
            sx={{ color: "white", backgroundColor: "#a9a7a7" }}
            onClick={handleClose}
          >
            <Typography>Disagree</Typography>
          </Button>
          <Button
            variant="contained"
            className="primary"
            onClick={handleAgree}
            autoFocus
          >
            <Typography>Agree</Typography>
          </Button>
        </DialogActions>
      </Dialog>

      {/* backdrop */}
      <BackDrop backdropopen={backdropopen} />
    </Box>
  );
};

export default AdminDashboard;
