import React from "react";
import { Backdrop, CircularProgress, Box, Typography } from "@mui/material";
const BackDrop = ({ backdropopen, backdropmessage }) => {
  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 2 }}
      open={backdropopen}
    >
      <Box display="flex" flexDirection="column" alignItems="center">
        <CircularProgress color="inherit" />
        <Typography
          variant="h5" // Increase the font size
          sx={{
            mt: 2,
            fontWeight: "bold", // Make the font bold
            textShadow: "2px 2px 4px rgba(0, 0, 0, 0.15)", // Add shadow for more prominence
            color: "#FFFFFF", // Use a different color for better contrast
          }}
        >
          {backdropmessage ? backdropmessage : "Loading, please wait..."}
        </Typography>
      </Box>
    </Backdrop>
  );
};

export default BackDrop;
