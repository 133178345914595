import React, { useEffect } from "react";
import {
  GoogleMap,
  Circle,
  MarkerF,
  MarkerClustererF,
  InfoWindow,
} from "@react-google-maps/api";
import { Typography, Box } from "@mui/material";
import markedMapIcon from "../../assets/images/markedMapIcon.png";
import hotelMapIcon from "../../assets/images/hotelMapIcon.png";
import eventMapIcon from "../../assets/images/eventMapIcon.png";
import BackDrop from "../common/BackDrop";
import SvgIcon from "@mui/material/SvgIcon";

const mapContainerStyle = {
  width: "100%",
  height: "100vh",
};

const NoDataFound = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "100vh",
        textAlign: "center",
        color: "#9E9E9E",
        fontFamily: "Arial, sans-serif",
        backgroundColor: "#f9f9f9",
        padding: "20px",
        borderRadius: "8px",
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Box sx={{ marginBottom: "16px" }}>
        <SvgIcon sx={{ fontSize: 64 }}>
          <path
            d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 13H7v-2h10v2z"
            fill="#9E9E9E"
          />
        </SvgIcon>
      </Box>
      <Typography
        sx={{
          fontSize: "18px",
          fontWeight: 500,
        }}
      >
        No Data Found
      </Typography>
    </Box>
  );
};

const InfoWindowDetail = ({ info, markerObj }) => {
  return markerObj.isCustomSearch ? (
    <Box>
      <Typography>Custom Search : {info.locationName}</Typography>
    </Box>
  ) : (
    <Box>
      <Typography>
        Event Name : {info.eventName} - {info.eventTypeName}
      </Typography>
      <Typography>StartDate : {info.startDateTime}</Typography>
      <Typography>EndDate : {info.endDateTime}</Typography>
      <hr></hr>
      <Typography>Total number of people : {info.totalPeople}</Typography>
      <Typography>
        Client : {info.peopleCount[0].Client} Consumer :{" "}
        {info.peopleCount[1].Consumer} Employee : {info.peopleCount[2].Employee}{" "}
        Contractor : {info.peopleCount[3].Contractor} Unknown :{" "}
        {info.peopleCount[4].Unknown} Staff : {info.peopleCount[5].Staff}
      </Typography>
      <hr></hr>
      <Typography>Location : {info.locationName}</Typography>
    </Box>
  );
};

const CustomMarker = ({ marker, handleMarkerClick, clusterer }) => {
  const { info, lat, lng, icon, circle } = marker;

  const [showInfoWindow, setShowInfoWindow] = React.useState(false);

  const handleMouseOver = () => setShowInfoWindow(true);
  const handleMouseOut = () => setShowInfoWindow(false);

  return (
    <>
      <MarkerF
        position={{ lat, lng }}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        // icon={icon}
        icon={icon?.icon}
        onClick={handleMarkerClick}
        clusterer={clusterer}
        // label={icon.color}
      >
        {showInfoWindow && (
          <InfoWindow sx={{ margin: 0, padding: 0 }}>
            <InfoWindowDetail info={info} markerObj={marker} />
          </InfoWindow>
        )}
      </MarkerF>
      <Circle
        center={{ lat: lat, lng: lng }}
        radius={circle.radius}
        options={circle.options}
      />
    </>
  );
};

const GoogleMapView = ({
  isLoaded,
  loadError,
  gMapMarkerList,
  setGMapMarkerList,
  filterParameters,
  setmarkersClickedSummary,
  backdropopen,
  mapRef,
  setMapRef,
  isPagechanged,
}) => {
  const [markerClicked, setmarkerClicked] = React.useState({});
  const [filteredMarkers, setFilteredMarkers] = React.useState([]);
  const [distance, setdistance] = React.useState(filterParameters?.radiusvalue);
  const [autocomplete, setAutocomplete] = React.useState(null);
  const [selectedPlace, setSelectedPlace] = React.useState(null);
  const [enableRadiusLogic, setEnableRadiusLogic] = React.useState(false);

  const MILESTOMETERS = 1609.34;

  // Paris
  const defaultcenter_map_center = React.useMemo(
    () => ({ lat: 48.856613, lng: 2.352222 }),
    []
  );

  // Paris center
  const defaultcenter_marker_center = React.useMemo(
    () => ({ lat: 48.856613, lng: 2.352222 }),
    []
  );

  const onLoad = (map) => {
    const bounds = new window.google.maps.LatLngBounds();
    gMapMarkerList?.forEach(({ lat, lng }) => bounds.extend({ lat, lng }));
    map.fitBounds(bounds);
    setMapRef(map);
    map.panTo(defaultcenter_map_center);
    map.setZoom(map?.getZoom());
  };

  const handleMarkerClick = (house) => () => {
    setmarkerClicked({
      lat: house.lat,
      lng: house.lng,
      color: house.icon.color,
    });

    const lat = house.lat;
    const lng = house.lng;
    //
    if (
      house.icon.color === "blue" ||
      house.icon.color === "purple" ||
      house.isCustomSearch === true
    ) {
      setEnableRadiusLogic(true);
    }
    mapRef?.panTo({ lat, lng });
    mapRef?.setZoom(mapRef?.getZoom());

    // Adding markers to generate summary and export csv

    setGMapMarkerList((prevHouses) => {
      const updatedHouses = prevHouses.map((item) => {
        const found = filteredMarkers.some(
          (marker) => lat === item.lat && lng === item.lng
        );

        if (found && item.icon.color.includes("green")) {
          let icon_info = null;
          if (
            item.info.locationTypeName === "Hotel" ||
            item.info.locationTypeName === "Restaurant"
          ) {
            icon_info = {
              icon: hotelMapIcon,
              color: "purple",
              radius_circle_options: {
                strokeColor: "#474B8D",
                fillColor: "#474B8D",
              },
            };
          } else {
            icon_info = {
              icon: eventMapIcon,
              color: "blue",
              radius_circle_options: {
                strokeColor: "#3D76FF",
                fillColor: "#3D76FF",
              },
            };
          }
          return {
            ...item,
            icon: icon_info,
            circle: {
              radius: item.circle.radius,
              options: {
                strokeColor: icon_info?.radius_circle_options?.strokeColor, // Border color of the circle
                strokeOpacity: 0.8, // Border opacity
                strokeWeight: 2, // Border width
                fillColor: icon_info?.radius_circle_options?.fillColor, // Fill color of the circle
                fillOpacity: 0.5, // Fill opacity
              },
            },
          };
        }
        return item;
      });
      return updatedHouses;
    });
  };

  useEffect(() => {
    // whenever Master list changes ,  need to filter all green markers to show on summary
    const temp = gMapMarkerList.filter((item) => {
      return item?.icon?.color.includes("green");
    });

    setmarkersClickedSummary(temp);
  }, [gMapMarkerList]);

  useEffect(() => {
    // whenever Master list changes ,  need to filter all green markers to show on summary
    const temp = gMapMarkerList.filter((item) => {
      return item?.icon?.color.includes("green");
    });

    setFilteredMarkers((old) => [...old, temp]);
  }, [isPagechanged]);

  React.useEffect(() => {
    const filteredDistanceMarkers = [];
    if (enableRadiusLogic) {
      gMapMarkerList.forEach((house) => {
        const currDist = calculateNearDistance(
          house.lat,
          house.lng,
          markerClicked.lat,
          markerClicked.lng,
          "M"
        );

        if (currDist <= filterParameters.radiusvalue) {
          filteredDistanceMarkers.push(house);
        }
      });
      setFilteredMarkers(filteredDistanceMarkers); // Store the result in state
      setEnableRadiusLogic(false);
    }
  }, [distance, markerClicked]);

  React.useEffect(() => {
    setGMapMarkerList((prevHouses) => {
      const updatedHouses = prevHouses.map((house) => {
        const found = filteredMarkers.some(
          (marker) => marker.lat === house.lat && marker.lng === house.lng
        );
        if (found) {
          // If the house is custom location : from google autocomplete -> keep the original color
          if (house.isCustomSearch === true) {
            return {
              ...house,
            };
          }

          // otherwise for all DB locations , change the color to green
          return {
            ...house,
            icon: {
              icon: markedMapIcon,
              color: "green",
              radius_circle_options: {
                strokeColor: "#037f51",
                fillColor: "#037f51",
              },
            },
            circle: {
              radius: house.circle.radius,
              options: {
                strokeColor: "#037f51", // Border color of the circle
                strokeOpacity: 0.8, // Border opacity
                strokeWeight: 2, // Border width
                fillColor: "#037f51", // Fill color of the circle
                fillOpacity: 0.5, // Fill opacity
              },
            },
          };
        }

        return house;
      });

      return updatedHouses;
    });
  }, [filteredMarkers]);

  const getGoogleClusterInlineSvg = function (color) {
    var encoded = window.btoa(
      '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="-100 -100 200 200"><defs><g id="a" transform="rotate(45)"><path d="M0 47A47 47 0 0 0 47 0L62 0A62 62 0 0 1 0 62Z" fill-opacity="0.7"/><path d="M0 67A67 67 0 0 0 67 0L81 0A81 81 0 0 1 0 81Z" fill-opacity="0.5"/><path d="M0 86A86 86 0 0 0 86 0L100 0A100 100 0 0 1 0 100Z" fill-opacity="0.3"/></g></defs><g fill="' +
        color +
        '"><circle r="42"/><use xlink:href="#a"/><g transform="rotate(120)"><use xlink:href="#a"/></g><g transform="rotate(240)"><use xlink:href="#a"/></g></g></svg>'
    );

    return "data:image/svg+xml;base64," + encoded;
  };

  const clusterStyles = [
    {
      url: getGoogleClusterInlineSvg("yellow"), //"https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png", // Change to the path of your green cluster image
      height: 60,
      width: 60,
      textColor: "black",
      textSize: 12,
    },
    {
      url: getGoogleClusterInlineSvg("green"), //"https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png", // Change to the path of your green cluster image
      height: 60,
      width: 60,
      textColor: "black",
      textSize: 12,
    },
  ];

  const clusterCalculator = (markers) => {
    let blueCount = 0;
    let greenCount = 0;

    markers.forEach((marker) => {
      const color = marker.getLabel(); // Access the color from the marker's label property

      if (color === "blue" || color === "purple") blueCount++;
      else if (color === "green") greenCount++;
    });

    let predominantColor = "blue";
    if (greenCount > blueCount) predominantColor = "green";

    let styleIndex = 0;
    if (predominantColor === "blue") styleIndex = 0;
    else if (predominantColor === "green") styleIndex = 1;

    return {
      text: markers.length.toString(),
      index: styleIndex,
    };
  };

  return (
    <Box>
      {
        loadError ? (
          <div>
            <p>Error loading maps</p>
          </div>
        ) : !isLoaded ? (
          <BackDrop backdropopen={backdropopen} />
        ) : // Display map only when there is data
        gMapMarkerList.length > 0 ? (
          <GoogleMap
            mapContainerStyle={mapContainerStyle}
            zoom={10}
            center={defaultcenter_map_center}
            onLoad={onLoad}
            options={{ mapId: "13226818eb72c747" }} // "5cc2fcba00313a5b" }} // Apply the Map Style ID
          >
            {/* Plotting markers  */}

            <>
              {/* Central marker */}
              <MarkerF
                position={defaultcenter_marker_center}
                icon="https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png"
              />

              {/* Markers according to generated Data 
                  Markers are clustered together for better View option
               */}
              {gMapMarkerList?.length > 0 && (
                <MarkerClustererF
                  // styles={clusterStyles}
                  // calculator={clusterCalculator}
                  onClick={(cluster) => {
                    const map = cluster.getMap();
                    map.panTo(cluster.getCenter());

                    map.setZoom(map?.getZoom() + 2);
                  }}
                  zoomOnClick={false}
                >
                  {(clusterer) =>
                    gMapMarkerList.map((house, index) => {
                      return (
                        <CustomMarker
                          key={index}
                          marker={house}
                          handleMarkerClick={handleMarkerClick(house)}
                          clusterer={clusterer}
                        />
                      );
                    })
                  }
                </MarkerClustererF>
              )}
            </>
          </GoogleMap>
        ) : backdropopen === true ? null : (
          <div>
            {/* <p>No Data Found</p> */}
            <NoDataFound />
          </div>
        )
        // <BackDrop backdropopen={backdropopen} />
      }
      <BackDrop backdropopen={backdropopen} />
    </Box>
  );
};

export default GoogleMapView;

// Circle Options
const defaultOptions = {
  strokeOpacity: 0.5,
  strokeWeight: 2,
  clickable: false,
  draggable: false,
  editable: false,
  visible: true,
};

//
function calculateNearDistance(lat1, lon1, lat2, lon2, unit) {
  /**
   * Helper function to calculate the distance between two lat/lng
   * lat1 , lon1 : lat/lng for point 1
   * lat2,lon2 : lat/lng for point 2
   * unit : distance metric
   *        Options : K,N
   */
  if (lat1 === lat2 && lon1 === lon2) {
    return 0;
  } else {
    var radlat1 = (Math.PI * lat1) / 180;
    var radlat2 = (Math.PI * lat2) / 180;
    var theta = lon1 - lon2;
    var radtheta = (Math.PI * theta) / 180;
    var dist =
      Math.sin(radlat1) * Math.sin(radlat2) +
      Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;
    if (unit === "K") {
      dist = dist * 1.609344;
    }
    if (unit === "N") {
      dist = dist * 0.8684;
    }
    return dist;
  }
}
