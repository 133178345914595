import React, { useState, useEffect, useRef } from "react";
import { styled } from "@mui/material/styles";
import {
  Drawer,
  Stack,
  Grid,
  Box,
  Button,
  Paper,
  TextField,
  Snackbar,
  Alert,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ClearSharpIcon from "@mui/icons-material/ClearSharp";
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  Slider,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  Chip,
  CircularProgress,
} from "@mui/material";
import { Autocomplete } from "@react-google-maps/api";
import {
  fetchFiltersList,
  fetchOverviewTabularDataByParameters,
  fetchEmergencyReport,
} from "../../utils/parentservices";
import { exportToCSV, exportToEmergencyCSV } from "../../utils/exportCSV";
import customMapIcon from "../../assets/images/customMapIcon.png";
import { generateHeadercolumns } from "../../utils/utils";
// import { useAuth } from "../../utils/AuthContext";
import { REACT_APP_ADMIN_POOL_NAME } from "../../config/config";

const drawerWidth = 390;

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  justifyContent: "flex-start",
}));

const FilterButtonsComponent = ({
  onApplyClick,
  toggleDrawer,
  onResetClick,
}) => {
  const onApplyFilter = () => {
    //console.log("Apply Button clicked in child component");

    // Call the function passed from the parent component
    if (onApplyClick) {
      onApplyClick("Data from child component");
    }
  };

  const onclickReset = () => {
    // Call the function passed from the parent component
    if (onResetClick) {
      onResetClick("Data from child component");
    }
  };
  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        marginTop: "20px !important",
        padding: "10px",
      }}
    >
      <Grid item>
        <Typography fontWeight="bold">Filter</Typography>
      </Grid>
      <Grid item>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Grid item>
            <Button
              variant="contained"
              sx={{ color: "white", backgroundColor: "#a9a7a7" }}
              onClick={onclickReset}
            >
              Reset
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              className="primary"
              onClick={onApplyFilter}
            >
              Apply
            </Button>
          </Grid>
          <Grid item>
            <IconButton onClick={toggleDrawer(false)}>
              <ClearSharpIcon sx={{ backgroundColor: "white" }} />
            </IconButton>
          </Grid>
        </Stack>
      </Grid>
    </Grid>
  );
};

const SummaryComponent = ({ markersClickedSummary }) => {
  const PaperContent = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    textAlign: "start",
    width: "100%",
    height: 100,
  }));

  // console.log("Clicked markers :- ", markersClickedSummary);
  const [finalTotalPeople, setfinalpeople] = useState(0);
  const [totallocationSelected, settotallocationSelected] = useState(0);
  const [peopleCount, setpeopleCount] = useState({
    Client: 0,
    Employee: 0,
    Consumer: 0,
    Contractor: 0,
    Unknown: 0,
    Staff: 0,
  });

  useEffect(() => {
    let temp1 = 0;
    let temp2 = {
      Client: 0,
      Employee: 0,
      Consumer: 0,
      Contractor: 0,
      Unknown: 0,
      Staff: 0,
      Principle: 0,
      Operations: 0,
      "Site Lead": 0,
      OperationsVVM: 0,
      "Exec Prot": 0,
      Hospitality: 0,
      "Tracked Phone": 0,
      GSS: 0,
    };

    markersClickedSummary.map((item) => {
      temp1 += item.info.totalPeople;
      item.info.peopleCount.forEach((element) => {
        for (const key in element) {
          temp2[key] += element[key];
        }
      });
    });
    settotallocationSelected(markersClickedSummary.length);
    setfinalpeople(temp1);
    setpeopleCount({
      ...peopleCount,
      Client: temp2.Client,
      Employee: temp2.Employee,
      Consumer: temp2.Consumer,
      Contractor: temp2.Contractor,
      Unknown: temp2.Unknown,
      Staff: temp2.Staff,
      Principle: temp2["Principle"],
      Operations: temp2["Operations"],
      "Site Lead": temp2["Site Lead"],
      OperationsVVM: temp2["OperationsVVM"],
      "Exec Prot": temp2["Exec Prot"],
      Hospitality: temp2["Hospitality"],
      "Tracked Phone": temp2["Tracked Phone"],
      GSS: temp2["GSS"],
    });
  }, [markersClickedSummary]);

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="flex-start"
      spacing={0.01}
      sx={{
        backgroundColor: "#F7F7FF",
        padding: "10px",
      }}
    >
      <Grid item sx={{ marginLeft: "5px", width: "100%" }}>
        <Typography fontWeight="bold">Summary</Typography>
      </Grid>
      <Grid item sx={{ marginLeft: "5px", width: "100%" }}>
        <Typography>Total number of people: {finalTotalPeople}</Typography>
      </Grid>
      <Grid item sx={{ marginLeft: "5px", width: "100%" }}>
        <Typography>
          Number of locations selected: {totallocationSelected}
        </Typography>
      </Grid>
      <Grid
        item
        sx={{
          width: "100%",
          // marginTop: "10px",
          height: "100%",
        }}
      >
        <PaperContent
          square={false}
          sx={{
            padding: "5px",
            margin: "5px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            backgroundColor: "#ffffff",
            boxShadow: 2,
          }}
        >
          <Table
            sx={{
              "& .MuiTableCell-root": { borderBottom: "none" },
              width: "100%",
            }}
            size="small"
          >
            <TableBody>
              <TableRow>
                <TableCell sx={{ padding: "8px" }}>
                  Client: {peopleCount.Client}
                </TableCell>
                <TableCell sx={{ padding: "8px" }}>
                  Employee: {peopleCount.Employee}
                </TableCell>
                <TableCell sx={{ padding: "8px" }}>
                  Staff: {peopleCount.Staff}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ padding: "8px" }}>
                  Consumer: {peopleCount.Consumer}
                </TableCell>
                <TableCell sx={{ padding: "8px" }}>
                  Contractor: {peopleCount.Contractor}
                </TableCell>
                <TableCell sx={{ padding: "8px" }}>
                  Unknown: {peopleCount.Unknown}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </PaperContent>
        {/* <hr /> */}
        <PaperContent
          square={false}
          sx={{
            padding: "5px",
            margin: "5px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
            backgroundColor: "#ffffff",
            boxShadow: 2,
            height: "150px",
          }}
        >
          <Table
            sx={{
              "& .MuiTableCell-root": { borderBottom: "none" },
              width: "100%",
            }}
            size="small"
          >
            <TableBody sx={{ height: "100%" }}>
              <TableRow>
                <TableCell sx={{ padding: "8px" }}>
                  Principle: {peopleCount["Principle"]}
                </TableCell>
                <TableCell sx={{ padding: "8px" }}>
                  Operations: {peopleCount["Operations"]}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell sx={{ padding: "8px" }}>
                  OperationsVVM: {peopleCount["OperationsVVM"]}
                </TableCell>
                <TableCell sx={{ padding: "8px" }}>
                  Site Lead: {peopleCount["Site Lead"]}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell sx={{ padding: "8px" }}>
                  Exec Prot: {peopleCount["Exec Prot"]}
                </TableCell>
                <TableCell sx={{ padding: "8px" }}>
                  Hospitality: {peopleCount["Hospitality"]}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell sx={{ padding: "8px" }}>
                  Tracked Phone: {peopleCount["Tracked Phone"]}
                </TableCell>
                <TableCell sx={{ padding: "8px" }}>
                  GSS: {peopleCount["GSS"]}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </PaperContent>
      </Grid>
    </Grid>
  );
};

const CustomSearchComponent = ({
  radiusValue,
  onRadiusChange,
  radiusvalueError,
  loadError,
  isLoaded,
  setGMapMarkerList,
  gMapMarkerList,
  autocomplete,
  setAutocomplete,
  inputRef,
  mapRef,
  setsnackOpen,
  setsnackMessage,
}) => {
  const [customMarkerId, setcustomMarkerId] = useState(0);
  const onLoadAutocomplete = (autoC) => {
    setAutocomplete(autoC);
  };

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();
      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();
      const location = place.formatted_address;

      const existingLocationOnMap = gMapMarkerList.filter((data) => {
        if (
          parseFloat(lat).toFixed(4) == parseFloat(data.lat).toFixed(4) &&
          lng.toFixed(4) == parseFloat(data.lng).toFixed(4)
        )
          return data;
      });
      if (!existingLocationOnMap.length) {
        mapRef?.panTo({ lat, lng });
        mapRef?.setZoom(mapRef?.getZoom() + 1);

        const marker = {
          isCustomSearch: true,
          empID: customMarkerId + 1,
          lat: lat,
          lng: lng,
          prId: null,

          icon: {
            icon: customMapIcon,
            color: "red",
            radius_circle_options: {
              strokeColor: "#F65858",
              fillColor: "#F65858",
              strokeOpacity: 0.8, // Border opacity
              strokeWeight: 2, // Border width
              fillOpacity: 0.5, // Fill opacity
            },
          },
          info: {
            locationTypeName: "Custom",
            locationName: location,
            eventTypeName: "Custom Location",
            eventName: "Custom Location",
            totalPeople: 0,

            peopleCount: [
              { Client: 0 },
              { Consumer: 0 },
              { Employee: 0 },
              { Contractor: 0 },
              { Unknown: 0 },
              { Staff: 0 },
            ],
          },
          circle: {
            radius: 50,
            options: {
              strokeColor: "#F65858", // Border color of the circle
              strokeOpacity: 0.8, // Border opacity
              strokeWeight: 2, // Border width
              fillColor: "#F65858", // Fill color of the circle
              fillOpacity: 0.5, // Fill opacity
            },
          },
        };
        //add condition here
        setcustomMarkerId(customMarkerId + 1);
        setGMapMarkerList((old) => [...old, marker]);
      } else {
        setsnackOpen(true);
        setsnackMessage({
          message: "Custom location already exists on the map!!",
          severity: "error",
        });
      }
    } else {
      console.log("Autocomplete is not loaded yet!");
    }
  };

  return (
    <Grid container>
      <Grid item sx={{ width: "100%", padding: "10px" }}>
        <Typography>Custom Search</Typography>

        {loadError ? (
          <p>Error loading maps</p>
        ) : !isLoaded ? (
          <p>Loading</p>
        ) : (
          <Autocomplete
            onLoad={onLoadAutocomplete}
            onPlaceChanged={onPlaceChanged}
            style={{ width: "100%" }}
            disablePortal={true}
          >
            <input
              type="text"
              placeholder="Enter a place"
              ref={inputRef}
              style={{
                width: "100%",
                height: "40px",
                zIndex: 2000,
                borderRadius: "5px",
                fontFamily:
                  "Visa Dialect, Inter, Roboto, Helvetica, Arial, sans-serif",
                // (theme) => theme.zIndex.drawer + 2,
                position: "relative",
              }}
            />
          </Autocomplete>
        )}
      </Grid>
      <Grid item sx={{ width: "100%", padding: "10px" }}>
        <TextField
          sx={{ width: "100%" }}
          error={radiusvalueError.error}
          id="outlined-number"
          label="Radius (in Miles)"
          size="small"
          type="number"
          value={radiusValue}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={onRadiusChange}
          helperText={radiusvalueError.errorMssg}
        />
      </Grid>
    </Grid>
  );
};

const CustomFilterComponent = ({
  eventlocationChecked,
  eventcheck,
  hotellocationChecked,
  hotelcheck,
  profiles,
  eventType,
  selectedValuesForEventType1,
  handleSelectionChangeForEventType1,
  selectedValuesForProfile1,
  handleSelectionChangeForProfile1,
  eventlocationCheckDisabled,
  hotellocationCheckDisabled,
}) => {
  const MultiSelectComponent = ({
    menuitems,
    selectedValues,
    onSelectionChange,
  }) => {
    const handleChange2 = (event) => {
      const selectedItems = event.target.value;
      const {
        target: { value },
      } = event;
      const dataValue = typeof value === "string" ? value.split(",") : value;
      onSelectionChange(dataValue);
    };

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
      PaperProps: {
        style: {
          maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
          width: 250,
        },
      },
    };

    return (
      <>
        <Select
          sx={{ width: "100%", maxHeight: "40px" }}
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={selectedValues}
          onChange={handleChange2}
          renderValue={(selected) => (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {selected.slice(0, 2).map((value) => (
                <Chip key={value} label={value} />
              ))}
              {selected.length > 2 && (
                <Chip key="more" label={`+${selected.length - 2}`} />
              )}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {menuitems?.length <= 0 ? (
            <CircularProgress />
          ) : (
            menuitems?.map((item, index) => (
              <MenuItem key={index} value={item}>
                <Checkbox checked={selectedValues.indexOf(item) > -1} />
                <ListItemText primary={item} />
              </MenuItem>
            ))
          )}
        </Select>
      </>
    );
  };
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    const dataValue = typeof value === "string" ? value.split(",") : value;
    handleSelectionChangeForProfile1(dataValue);
  };

  const handleEventTypeChange = (event) => {
    const {
      target: { value },
    } = event;
    const dataValue = typeof value === "string" ? value.split(",") : value;
    handleSelectionChangeForEventType1(dataValue);
  };
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="flex-start"
    >
      <Grid item sx={{ width: "100%", padding: "10px" }}>
        <Typography>Class</Typography>

        <Select
          sx={{ width: "100%", maxHeight: "40px" }}
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={selectedValuesForProfile1}
          onChange={handleChange}
          // input={<OutlinedInput label="Tag" />}
          renderValue={(selected) => (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {selected.slice(0, 2).map((value) => (
                <Chip key={value} label={value} />
              ))}
              {selected.length > 2 && (
                <Chip key="more" label={`+${selected.length - 2}`} />
              )}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {profiles?.length <= 0 ? (
            <CircularProgress />
          ) : (
            profiles?.map((item, index) => (
              <MenuItem key={index} value={item}>
                <Checkbox
                  checked={selectedValuesForProfile1.indexOf(item) > -1}
                />
                <ListItemText primary={item} />
              </MenuItem>
            ))
          )}
        </Select>
      </Grid>
      <Grid item sx={{ width: "100%", padding: "10px" }}>
        <Stack direction="column">
          <Grid container alignItems="center" spacing={1}>
            <Grid
              item
              sx={{
                cursor: eventlocationCheckDisabled
                  ? "not-allowed!important"
                  : "default",
              }}
            >
              <Checkbox
                checked={eventlocationChecked}
                onChange={eventcheck}
                inputProps={{ "aria-label": "controlled" }}
                disabled={eventlocationCheckDisabled}
              />
            </Grid>
            <Grid item>
              <Typography
                sx={{
                  color: eventlocationCheckDisabled ? "grey" : "black",
                }}
              >
                Event Location
              </Typography>
            </Grid>
          </Grid>
          <Grid container alignItems="center" spacing={1}>
            <Grid
              item
              sx={{
                cursor: hotellocationCheckDisabled
                  ? "not-allowed!important"
                  : "default",
              }}
            >
              <Checkbox
                checked={hotellocationChecked}
                onChange={hotelcheck}
                inputProps={{ "aria-label": "controlled" }}
                disabled={hotellocationCheckDisabled}
              />
            </Grid>
            <Grid item>
              <Typography
                sx={{
                  color: hotellocationCheckDisabled ? "grey" : "black",
                }}
              >
                Hotel Location
              </Typography>
            </Grid>
          </Grid>
        </Stack>
      </Grid>
      <Grid item sx={{ width: "100%", padding: "10px" }}>
        <Typography>By Event Type</Typography>

        <Select
          sx={{ width: "100%", maxHeight: "40px" }}
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={selectedValuesForEventType1}
          onChange={handleEventTypeChange}
          // input={<OutlinedInput label="Tag" />}
          renderValue={(selected) => (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {selected.slice(0, 2).map((value) => (
                <Chip key={value} label={value} />
              ))}
              {selected.length > 2 && (
                <Chip key="more" label={`+${selected.length - 2}`} />
              )}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {eventType?.length <= 0 ? (
            <CircularProgress />
          ) : (
            eventType?.map((item, index) => (
              <MenuItem key={index} value={item}>
                <Checkbox
                  checked={selectedValuesForEventType1.indexOf(item) > -1}
                />
                <ListItemText primary={item} />
              </MenuItem>
            ))
          )}
        </Select>
      </Grid>
    </Grid>
  );
};

export default function FilterDrawer({
  openRightDrawer,
  toggleDrawer,
  isLoaded,
  loadError,
  onApplyFnClick,
  onResetFnClick,
  onLoadAutocomplete,
  onPlaceChanged,
  markersClickedSummary,
  setGMapMarkerList,
  gMapMarkerList,
  filterParameters,
  setFilterParameters,
  mapRef,
  setMapRef,
}) {
  // const { user } = useAuth();

  // radius value
  const [radiusvalue, setRadiusvalue] = useState(
    filterParameters.radiusvalue || 0
  ); //new addition
  const [radiusvalueError, setRadiusvalueError] = useState({
    error: false,
    errorMssg: "",
  });
  const [snackOpen, setsnackOpen] = useState(false);
  const [snackMessage, setsnackMessage] = useState({
    message: "",
    severity: "success",
  });
  const inputRef = useRef(filterParameters.gmapLocation || null); //new addition

  const [filterParameter, setFilterParameter] = useState({
    radiusvalue: null,
    eventlocationChecked: false,
    hotellocationChecked: false,
    eventTypeValue: null,
    profileValue: null,
    gmapLocation: null,
  });

  let columnHeader = [];
  const isEditable = false;
  const list = generateHeadercolumns([], isEditable, [], [], [], []);
  columnHeader.push(...list);

  const [eventlocationChecked, setEventlocationChecked] = React.useState(
    filterParameters.eventlocationChecked
  ); //new addition
  const [hotellocationChecked, setHotellocationCheckedd] = React.useState(
    filterParameters.hotellocationChecked
  ); //new addition
  const [autocomplete, setAutocomplete] = useState(null);

  const [profiles, setprofiles] = useState([]);
  const [eventType, seteventType] = useState([]);

  let resetFlag = true;

  const [eventlocationCheckDisabled, setEventlocationCheckDisabled] = useState(
    filterParameters.hotellocationChecked ? true : false
  );
  const [hotellocationCheckDisabled, setHotellocationCheckDisabled] = useState(
    filterParameters.eventlocationChecked ? true : false
  );

  const eventcheck = (event) => {
    setEventlocationChecked(event.target.checked);
    setFilterParameter({
      ...filterParameter,
      eventlocationChecked: event.target.checked,
    });
    setHotellocationCheckDisabled(event.target.checked ? true : false);
  };

  const hotelcheck = (event) => {
    setHotellocationCheckedd(event.target.checked);
    setFilterParameter({
      ...filterParameter,
      hotellocationChecked: event.target.checked,
    });
    setEventlocationCheckDisabled(event.target.checked ? true : false);
  };

  const onRadiusChange = (event, newVal) => {
    if (event.target.value < 0) {
      setRadiusvalueError({
        error: true,
        errorMssg: "Minimum value should be 0",
      });
      return;
    }

    const value = event.target.value;
    const regex = /^\d*\.?\d{0,2}$/;

    if (regex.test(value)) {
      setRadiusvalue(value);
      setRadiusvalueError({
        error: false,
        errorMssg: "",
      });
    } else {
      setRadiusvalueError({
        error: true,
        errorMssg: "2 decimal points allowed",
      });
    }

    setFilterParameter({
      ...filterParameter,
      radiusvalue: value,
    });
  };

  useEffect(() => {
    setFilterParameter({
      ...filterParameter,
      hotellocationChecked: hotellocationChecked,
      eventlocationChecked: eventlocationChecked,
      radiusvalue: radiusvalue,
    });
  }, [radiusvalue]);

  async function fetchOverviewDataByFilters(parameters) {
    try {
      let shouldContinue = true;
      let dataList = [];
      const token = localStorage.getItem("idToken");
      let counter = 0;

      while (shouldContinue) {
        let response = null;
        if (counter === 0) {
          const param = {
            inputParam: {
              ...parameters,
            },
          };

          response = await fetchOverviewTabularDataByParameters(param, token);
          counter = counter + 1;
        } else if (counter < 30) {
          parameters.startLimit =
            counter === 1
              ? parameters.startLimit + 5000 + 1
              : parameters.startLimit + 5000;
          const param = {
            inputParam: {
              ...parameters,
            },
          };

          response = await fetchOverviewTabularDataByParameters(param, token);
          counter = counter + 1;
        }
        if (
          response?.data?._result &&
          response?.data?._code === 200 &&
          !response?.data?._isError
        ) {
          const { tabularList, count } = response?.data?._result;
          if (count && count === 0) {
            shouldContinue = false;
          } else if (count > 0 && count < 5000) {
            dataList.push(tabularList);
            shouldContinue = false;
          } else {
            dataList.push(tabularList);
          }
        } else {
          shouldContinue = false;
        }
      }
      let parentList = [];
      if (dataList && dataList.length > 0) {
        // Iterate over each sublist in dataList and push objects into parentList
        for (const key in dataList) {
          if (dataList.hasOwnProperty(key)) {
            parentList.push(...dataList[key]);
          }
        }
        return parentList;
      }
    } catch (error) {
      console.error("Error:", error);
      // Handle error as needed
    }
  }

  const onExportClicked = async () => {
    if (markersClickedSummary.length <= 0) {
      setsnackOpen(true);
      setsnackMessage({
        message: "Please apply atleast 1 filter!!",
        severity: "info",
      });
    } else {
      let lat = [];
      let lng = [];
      markersClickedSummary.forEach((item) => {
        lat.push(item.lat);
        lng.push(item.lng);
      });
      const paramter = {
        startDate: filterParameters.startDate,
        endDate: filterParameters.endDate,
        startTime: filterParameters.startTime,
        endTime: filterParameters.endTime,
        location: filterParameters.location,
        eventlocationChecked: filterParameters.eventlocationChecked,
        hotellocationChecked: filterParameters.hotellocationChecked,
        eventTypeValue: filterParameters.eventTypeValue,
        profileValue: filterParameters.profileValue,
        lat: lat && lat.length > 0 ? [...lat] : [],
        lng: lng && lng.length > 0 ? [...lng] : [],
        startLimit: 0,
      };

      try {
        const gMapData = await fetchOverviewDataByFilters(paramter);
        let exportPromise = new Promise((resolve, reject) => {
          resolve(exportToCSV(gMapData, columnHeader, "map_export_summary_"));
        });
        exportPromise
          .then((res) => {
            setTimeout(async function () {
              setsnackOpen(true);
              setsnackMessage({
                message: "Export has started, Please check the browser.",
                severity: "success",
              });
            }, 1000);
          })
          .catch((err) => {});
      } catch (error) {
        console.log("Error :- ", error);
      }
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setsnackOpen(false);
  };

  const onApplyFilterClick = (dataFromChild) => {
    // Call the function passed from the parent component
    if (onApplyFnClick && !radiusvalueError.error) {
      onApplyFnClick(filterParameter);
    } else {
      alert("Error!");
    }
  };

  const onResetFilterClick = (dataFromChild) => {
    // Call the function passed from the parent component
    if (onResetFnClick && !radiusvalueError.error) {
      setSelectedValuesForProfile2([]);
      setSelectedValuesForEventType2([]);
      setEventlocationChecked(false);
      setHotellocationCheckedd(false);
      setEventlocationCheckDisabled(false);
      setHotellocationCheckDisabled(false);
      setRadiusvalue(0);
      if (inputRef.current) {
        inputRef.current.value = "";
        // Optionally, reset any other state or perform additional actions
      }
      setFilterParameter({
        radiusvalue: 0,
        eventlocationChecked: false,
        hotellocationChecked: false,
        eventTypeValue: null,
        profileValue: null,
        gmapLocation: null,
      });

      setFilterParameters({
        ...filterParameters,
        radiusvalue: 0,
        eventlocationChecked: false,
        hotellocationChecked: false,
        eventTypeValue: null,
        profileValue: null,
        gmapLocation: null,
      });
      onResetFnClick();
      if (resetFlag) {
        onResetFnClick();
        resetFlag = false;
      }
    } else {
      alert("Error!");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("idToken");
        const response = await fetchFiltersList(token);
        if (
          response?.data?._result &&
          response?.data?._code === 200 &&
          !response?.data?._isError
        ) {
          const { eventTypeList, guestTypeList } = response?.data?._result;
          seteventType(eventTypeList.map((item) => item.label));
          setprofiles(guestTypeList.map((item) => item.label));
        }
      } catch (error) {
        console.error("Error:", error);
        // Handle error as needed
      }
    };

    fetchData(); // Call the function on component mount
  }, []);

  const [selectedValuesForEventType2, setSelectedValuesForEventType2] =
    useState(
      // []
      filterParameters.eventTypeValue || []
    ); //new addition

  const handleSelectionChangeForEventType2 = (selectedItems) => {
    setSelectedValuesForEventType2(selectedItems);
    setFilterParameter({
      ...filterParameter,
      eventTypeValue: selectedItems,
    });
  };
  const [selectedValuesForProfile2, setSelectedValuesForProfile2] = useState(
    // []
    filterParameters.profileValue || []
  ); //new addition

  const handleSelectionChangeForProfile2 = (selectedItems) => {
    setSelectedValuesForProfile2(selectedItems);
    setFilterParameter({
      ...filterParameter,
      profileValue: selectedItems,
    });
  };

  const onClickEmergencyReport = async () => {
    if (markersClickedSummary.length <= 0) {
      setsnackOpen(true);
      setsnackMessage({
        message: "Please apply atleast 1 filter!!",
        severity: "info",
      });
    } else {
      let lat = [];
      let lng = [];
      markersClickedSummary.forEach((item) => {
        lat.push(item.lat);
        lng.push(item.lng);
      });
      const parameter = {
        inputParam: {
          startDate: filterParameters.startDate,
          endDate: filterParameters.endDate,
          startTime: filterParameters.startTime,
          endTime: filterParameters.endTime,
          location: filterParameters.location,
          eventlocationChecked: filterParameters.eventlocationChecked,
          hotellocationChecked: filterParameters.hotellocationChecked,
          eventTypeValue: filterParameters.eventTypeValue,
          profileValue: filterParameters.profileValue,
          lat: lat && lat.length > 0 ? [...lat] : [],
          lng: lng && lng.length > 0 ? [...lng] : [],
        },
      };

      const token = localStorage.getItem("idToken");
      const response = await fetchEmergencyReport(parameter, token);

      if (
        response?.data?._result &&
        response?.data?._code === 200 &&
        !response?.data?._isError &&
        response?.data?._result.count > 0
      ) {
        const resp = response?.data?._result;

        // if you have privaligies
        if (resp && resp.statusCode && resp.success) {
          let exportPromise = new Promise((resolve, reject) => {
            resolve(
              exportToEmergencyCSV(resp.manualFeedData, "map_emergency_report_")
            );
          });
          exportPromise
            .then((res) => {
              setTimeout(async function () {
                setsnackOpen(true);
                setsnackMessage({
                  message: "Export has started, Please check the browser.",
                  severity: "success",
                });
              }, 1000);
            })
            .catch((err) => {});
        }
        // No right privaligies
        else if (resp && resp.statusCode && resp.success === false) {
          setsnackOpen(true);
          setsnackMessage({
            message: resp?.message ? resp.message : "Error!!",
            severity: "error",
          });
        }
      } else {
        setsnackOpen(true);
        setsnackMessage({
          message: "Something went wrong!!",
          severity: "error",
        });
      }
    }
  };

  return (
    <Drawer
      disablePortal
      sx={{
        position: "absolute",
        width: openRightDrawer === true ? drawerWidth : 0,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: openRightDrawer === true ? drawerWidth : 0,
          backgroundColor: "white",
        },
        // zIndex: (theme) => theme.zIndex.drawer - 1,
        zIndex: 1000,
      }}
      variant="persistent"
      anchor="right"
      open={openRightDrawer}
    >
      <DrawerHeader
        sx={{ justifyContent: "flex-end", backgroundColor: "#2A2E69" }}
      >
        <IconButton onClick={toggleDrawer(false)}>
          <ClearSharpIcon sx={{ backgroundColor: "white" }} />
        </IconButton>
      </DrawerHeader>

      <Box sx={{ display: "flex" }}>
        <Stack
          direction="column"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
          sx={{ width: "100%" }}
        >
          {/* ----------------------------- Apply Filter buttons ----------------------------- */}

          <FilterButtonsComponent
            onApplyClick={onApplyFilterClick}
            toggleDrawer={toggleDrawer}
            onResetClick={onResetFilterClick}
          />

          {/* ----------------------------- Summary Information ----------------------------- */}

          <SummaryComponent markersClickedSummary={markersClickedSummary} />

          {/* ----------------------------- Custom Search ----------------------------- */}

          <CustomSearchComponent
            isLoaded={isLoaded}
            loadError={loadError}
            radiusValue={radiusvalue}
            onRadiusChange={onRadiusChange}
            radiusvalueError={radiusvalueError}
            setGMapMarkerList={setGMapMarkerList}
            gMapMarkerList={gMapMarkerList}
            autocomplete={autocomplete}
            setAutocomplete={setAutocomplete}
            inputRef={inputRef}
            mapRef={mapRef}
            setsnackOpen={setsnackOpen}
            setsnackMessage={setsnackMessage}
          />

          {/* ----------------------------- Custom Filter Options ----------------------------- */}

          <CustomFilterComponent
            eventlocationChecked={eventlocationChecked}
            eventcheck={eventcheck}
            hotellocationChecked={hotellocationChecked}
            hotelcheck={hotelcheck}
            profiles={profiles}
            eventType={eventType}
            selectedValuesForEventType1={selectedValuesForEventType2}
            handleSelectionChangeForEventType1={
              handleSelectionChangeForEventType2
            }
            selectedValuesForProfile1={selectedValuesForProfile2}
            handleSelectionChangeForProfile1={handleSelectionChangeForProfile2}
            eventlocationCheckDisabled={eventlocationCheckDisabled}
            hotellocationCheckDisabled={hotellocationCheckDisabled}
          />

          {/* ----------------------------- Export Summary button ----------------------------- */}

          <Grid
            container
            justifyContent="center"
            alignItems="center"
            direction={"row"}
            spacing={1}
          >
            {localStorage.getItem("userRole") !== REACT_APP_ADMIN_POOL_NAME ? (
              <Grid item sx={{ width: "95%" }}>
                <Button
                  onClick={onExportClicked}
                  variant="contained"
                  className="primary"
                  sx={{ width: "100%", padding: "5px" }}
                >
                  Export Summary
                </Button>
              </Grid>
            ) : (
              <>
                <Grid item sx={{ width: "44%" }}>
                  <Button
                    onClick={onExportClicked}
                    variant="contained"
                    className="primary"
                    sx={{ width: "100%", padding: "5px" }}
                  >
                    Export Summary
                  </Button>
                </Grid>

                <Grid item sx={{ width: "55%" }}>
                  <Button
                    onClick={onClickEmergencyReport}
                    variant="contained"
                    className="primary"
                    sx={{ width: "100%" }}
                  >
                    Emergency Export
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        </Stack>
      </Box>
      <Snackbar
        open={snackOpen}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        sx={{ position: "fixed" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackMessage.severity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackMessage.message}
        </Alert>
      </Snackbar>
    </Drawer>
  );
}
