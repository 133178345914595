// redux/parametersSlice.js
import { createSlice } from "@reduxjs/toolkit";

const parameters2Slice = createSlice({
  name: "parameters2",
  initialState: null,
  reducers: {
    setParameters2: (state, action) => {
      state = action.payload;
      return action.payload;
    },
  },
});

export const { setParameters2 } = parameters2Slice.actions;

export default parameters2Slice.reducer;
