// Navbar.js

import React from "react";
import Typography from "@mui/material/Typography";
import { Box, Grid } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import logo from "../../assets/images/logo.png";
import poweredByFractalIcon from "../../assets/images/PoweredbyfractalIcon.png";
import pipeIcon from "../../assets/images/pipeIcon.png";
import { awsSignOut } from "../../utils/parentservices";
import BackDrop from "./BackDrop";

const Navbar = ({ drawerWidth, navigate }) => {
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const settings = ["Logout"];
  const [backdropopen, setbackdropopen] = React.useState(false);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = async (setting) => {
    if (setting === "Logout") {
      setbackdropopen(true);
      const parameter = {
        inputParam: {
          userName: localStorage.getItem("userName"),
        },
      };
      const token = localStorage.getItem("idToken");
      setbackdropopen(true);
      const resp = await awsSignOut(parameter, token);
      if (
        resp?.data?._result &&
        resp?.data?._code === 200 &&
        !resp?.data?._isError
      ) {
        const result = resp?.data?._result;

        if (result === "User signed out successfully") {
          localStorage.removeItem("idToken");

          localStorage.removeItem("userRole");
          localStorage.removeItem("userName");
          localStorage.removeItem("_grecaptcha");
          localStorage.removeItem("loginDateTime");
          navigate("/login");
        } else {
          localStorage.removeItem("idToken");
          // localStorage.removeItem("refreshToken");
          localStorage.removeItem("userRole");
          localStorage.removeItem("userName");
          localStorage.removeItem("_grecaptcha");
          localStorage.removeItem("loginDateTime");
          navigate("/login");
        }
      } else {
      }
      setbackdropopen(false);
    }
    setAnchorElUser(null);
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      {/* Grid Item 1 - Brand Name */}
      <Grid item>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box>
            <img src={logo} alt="Logo" />
          </Box>
        </Grid>
      </Grid>

      {/* Grid Item 2 - User Profile  */}

      <Grid item sx={{ flexGrow: 0 }}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Grid item>
            <img src={poweredByFractalIcon} alt="poweredByFractalIcon" />
          </Grid>
          <Grid item sx={{ paddingTop: "22px" }}>
            <img src={pipeIcon} alt="pipeIcon" />
          </Grid>
          <Grid item>
            <AccountCircleIcon
              fontSize="medium"
              sx={{ xs: "none", md: "flex", color: "whitesmoke" }}
            />
          </Grid>
          <Grid item>
            <Tooltip title={localStorage.getItem("userRole")}>
              <Typography
                // variant="h5"
                sx={{
                  display: { xs: "none", md: "flex", color: "white" },
                }}
              >
                {localStorage.getItem("userName")}
              </Typography>
            </Tooltip>
          </Grid>
          {/* Show menu options */}
          <Grid item>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <ExpandMoreIcon fontSize="large" sx={{ color: "whitesmoke" }} />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem
                  key={setting}
                  onClick={() => handleCloseUserMenu(setting)}
                >
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Grid>
        </Grid>
      </Grid>
      {/* backdrop */}
      <BackDrop
        backdropopen={backdropopen}
        backdropmessage={"Signing out, please wait..."}
      />
    </Grid>
  );
};

export default Navbar;
