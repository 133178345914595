import { Chip } from "@mui/material";
import moment from "moment";

export const generateCurrentDateTime = (
  currentDateObj,
  dateTimeBeforeObj,
  dateTimeAfterObj
) => {
  // Format dates and times
  const result = [
    helper(currentDateObj),
    helper(dateTimeBeforeObj),
    helper(dateTimeAfterObj),
  ];
  return result;
};

const helper = (dateObj) => {
  const year = dateObj.getFullYear();
  const month = String(dateObj.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
  const day = String(dateObj.getDate()).padStart(2, "0");
  const hours = dateObj.getHours();
  const minutes = dateObj.getMinutes();
  //const ampm = hours >= 12 ? "PM" : "AM";
  const ampm = "AM";
  const formattedHours = hours % 12 || 12;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  return `${year}-${month}-${day} ${formattedHours}:${formattedMinutes} ${ampm}`;
};

export const formatTime = (date) => {
  const time = new Date(date);
  const year = time.getFullYear();
  const month = String(time.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
  const day = String(time.getDate()).padStart(2, "0");
  const hours = String(time.getHours()).padStart(2, "0");
  const minutes = String(time.getMinutes()).padStart(2, "0");
  const seconds = String(time.getSeconds()).padStart(2, "0");

  // const [year, month, day] = parameterdata.split("-");

  const formattedTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  return formattedTime;
};

export const formatDate = (time) => {
  const date = new Date(time);

  const formattedTime = `${date.getFullYear()}-${String(
    date.getMonth() + 1
  ).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}`;

  return formattedTime;
};

export const generateAllDayDate = () => {
  const date = new Date();
  const formattedHours = 11;
  const formattedMinutes = 59;
  const formattedSecond = 59;

  const formattedHourEnd = 23;

  const startDate = `${date.getFullYear()}-${String(
    date.getMonth() + 1
  ).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}`;

  const endDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
    2,
    "0"
  )}-${String(date.getDate()).padStart(2, "0")}`;

  const startTime = `${date.getFullYear()}-${String(
    date.getMonth() + 1
  ).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")} 0:01:00`;

  const endTime = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
    2,
    "0"
  )}-${String(date.getDate()).padStart(
    2,
    "0"
  )} ${formattedHourEnd}:${formattedMinutes}:${formattedSecond}`;

  return { startDate, endDate, startTime, endTime };
};

export const convertDBDateTime = (updatedRow) => {
  // Create a new Date object from the date string
  const date = new Date(updatedRow.itineraryDate);

  // Extract year, month, and day from the date object
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Add leading zero if necessary
  const day = String(date.getDate()).padStart(2, "0"); // Add leading zero if necessary

  // Create the formatted date string in the format 'YYYY-MM-DD'
  const formattedDate = `${year}-${month}-${day}`;

  return formattedDate;
};

export const generateHeadercolumns = (
  data,
  isEditable,
  guestList,
  eventList,
  userClassList,
  specialStatusList
) => {
  let col = {
    field: null,
    headerName: null,
    sortable: true,
    width: 150,
    editable: false,
  };

  try {
    const guestListIds = guestList.map((item) => item.personID);
    const eventListIds = eventList.map((item) => item.eventId);
    const userClassListLables = userClassList.map((item) => item.class);
    const specialStatusListLabels = specialStatusList.map(
      (item) => item.specialStatus
    );

    let columnHeader = [];
    const colList = [
      "dataSource",
      "guestEventPublishedId",
      "class",
      "specialStatus",
      "personID",
      "firstName",
      "middleName",
      "lastName",
      "gender",
      "primaryMobilePhoneNumber",
      "primaryEmailAddress",
      "GSSFlag",
      "principleFlag",
      "hasDisability",
      // "localLanguageProficiency",
      "englishProficiency",
      "lastCheckedHotel",
      "lastCheckedHotelTime",
      "lastCheckedLocation",
      "lastCheckedLocationTime",
      "eventId",
      "guestEventType",
      "eventTypeName",
      "eventName",
      "sport",
      "fromLocation",
      "locationTypeName",
      "locationName",
      "geoLocationLat",
      "geoLocationLong",
      "toLocation",
      "toLocationTypeName",
      "toLocationName",
      "itineraryDate",
      "startDateTime",
      "endDateTime",
      "invitedById",
      "inviteeName",
      "inviteeContactdetails",
      "modifiedBy",
      "modifiedDateTime",
      "dataUpdatedDateTime",
    ];

    colList.forEach((item) => {
      if (isEditable) {
        if (item === "class") {
          col = {
            field: item,
            headerName: item,
            width: 150,
            editable: "true",
            type: "singleSelect",
            valueOptions: userClassListLables,
            renderCell: (params) => (
              <Chip
                label={params.value}
                sx={{ backgroundColor: "#E7F7ED", color: "#34C06D" }}
              />
            ),
          };
        } else if (item === "guestEventPublishedId") {
          col = {
            field: "id",
            headerName: "id",
            sortable: true,
            width: 150,
            editable: false,
          };
        } else if (item === "specialStatus") {
          col = {
            field: item,
            headerName: item,
            width: 150,
            editable: "true",
            type: "singleSelect",
            valueOptions: specialStatusListLabels,
            renderCell: (params) => (
              <Chip
                label={params.value}
                sx={{ backgroundColor: "#E7F7ED", color: "#34C06D" }}
              />
            ),
          };
        } else if (item === "personID") {
          col = {
            field: item,
            headerName: item,
            width: 150,
            editable: "true",
            type: "singleSelect",
            valueOptions: guestListIds,
            renderCell: (params) => (
              <Chip
                label={params.value}
                sx={{ backgroundColor: "#E7F7ED", color: "#34C06D" }}
              />
            ),
          };
        } else if (item === "eventId") {
          col = {
            field: item,
            headerName: item,
            width: 150,
            editable: "true",
            type: "singleSelect",
            valueOptions: eventListIds,
            renderCell: (params) => (
              <Chip
                label={params.value}
                sx={{ backgroundColor: "#E7F7ED", color: "#34C06D" }}
              />
            ),
          };
        } else {
          col = {
            field: item,
            headerName: item,
            sortable: true,
            width: 150,
            editable: false,
          };
        }
      } else {
        if (item === "guestEventPublishedId") {
          col = {
            field: "id",
            headerName: "id",
            sortable: true,
            width: 150,
            editable: false,
          };
        } else if (
          item === "class" ||
          item === "specialStatus" ||
          item === "personID" ||
          item === "eventId"
        ) {
          col = {
            field: item,
            headerName: item,
            sortable: true,
            width: 150,
            editable: false,
            renderCell: (params) => (
              <Chip
                label={params.value}
                sx={{ backgroundColor: "#E7F7ED", color: "#34C06D" }}
              />
            ),
          };
        } else {
          col = {
            field: item,
            headerName: item,
            sortable: true,
            width: 150,
            editable: false,
          };
        }
      }

      columnHeader.push(col);
    });

    return columnHeader;
  } catch (error) {
    console.log("error : -", error);
    return [];
  }
};

function convertToCustomFormat(input) {
  // Check if the input is in the correct format using a regular expression
  const regex = /^\d{2}-\d{2}-\d{4} \d{2}:\d{2}$/;
  const regex2 = /^\d{2}-\d{2}-\d{4} \d{2}:\d{2}:\d{2}$/;
  if (!regex.test(input) || !regex2.test(input)) {
    throw new Error(
      "Input format is incorrect. Expected format: DD-MM-YYYY HH:MM or DD-MM-YYYY HH:MM:SS "
    );
  }

  // Extract the date and time components
  const [datePart, timePart] = input.split(" ");
  const [day, month, year] = datePart.split("-");
  const [hours, minutes] = timePart.split(":");

  // Convert month number to month name abbreviation
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const monthName = monthNames[parseInt(month) - 1];

  // Format the output string
  const formattedTime = `${day}-${monthName}-${year} ${hours}:${minutes}:00`;
  return formattedTime;
}

function validateDateString(dateString) {
  const formats = [
    {
      regex: /^\d{2}-\d{2}-\d{4}\s+\d{2}:\d{2}$/,
      format: "DD-MM-YYYY HH:mm",
      error: "Expected format: DD-MM-YYYY HH:mm",
    }, // 24-07-2024 20:00
    {
      regex: /^\d{2}-\d{2}-\d{4}\s+\d{2}:\d{2}:\d{2}$/,
      format: "DD-MM-YYYY HH:mm:ss",
      error: "Expected format: DD-MM-YYYY HH:mm:ss",
    }, // 24-07-2024 20:00:00
    {
      regex:
        /^\d{2}-(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)-\d{4}\s+\d{2}:\d{2}:\d{2}$/,
      format: "DD-MMM-YYYY HH:mm:ss",
      error: "Expected format: DD-MMM-YYYY HH:mm:ss",
    }, // 24-Jul-2024 20:00:00
    {
      regex:
        /^\d{2}-(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)-\d{4}\s+\d{2}:\d{2}$/,
      format: "DD-MMM-YYYY HH:mm",
      error: "Expected format: DD-MMM-YYYY HH:mm",
    }, // 24-Jul-2024 20:00
    {
      regex:
        /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])\s+(0\d|1\d|2[0-3]):[0-5]\d:[0-5]\d$/,
      format: "YYYY-MM-DD HH:mm:ss",
      error: "Expected format: YYYY-MM-DD HH:mm:ss",
    }, // 2024-07-24 20:00:00
  ];

  for (const { regex, format, error } of formats) {
    if (regex.test(dateString)) {
      return { format, error: null };
    }
  }

  return {
    format: null,
    error:
      "Invalid date format. Expected formats are: DD-MM-YYYY HH:mm, DD-MM-YYYY HH:mm:ss, DD-MMM-YYYY HH:mm, DD-MMM-YYYY HH:mm:ss.",
  };
}

function validateInput(input) {
  // Define a regex for special characters
  const specialCharRegex = /[!@#\$%\^\&*\)\(+=._]+/g;

  // Check if the input is null
  if (input === null || input === undefined || input === "") {
    return { error: true, message: "cannot be null or undefined." };
  }

  // Check if the input only contains special characters
  if (input.replace(specialCharRegex, "").length === 0) {
    return { error: true, message: " can't contain special characters." };
  }

  // Remove special characters and preserve value
  const sanitizedInput = input.replace(specialCharRegex, "");

  return { error: false, sanitizedInput };
}

export const validateAllocationImportData = (importData, username) => {
  try {
    const errorrows = [];
    const message = [];
    const date_regex =
      /\b\d{2}-(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)-\d{2}(?:\d{2})?\b/;
    const time_regex = /^(\d{2})-(\d{2})-(\d{4}) (\d{2}):(\d{2})$/;
    const time_regex_full_month =
      /^\d{2}-(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)-\d{4} \d{2}:\d{2}:\d{2}$/;

    const integerRegex = /^-?\d+$/;
    const floatRegex = /^-?\d*\.\d+$/;

    const specialCharecterRegex = /[^A-Za-z0-9]/;

    const nonEmptyColumns = [
      // "id",
      "class",
      "specialStatus",
      "personID",
      "firstName",
      "primaryMobilePhoneNumber",
      "eventTypeName",
      "eventName",
      "GSSFlag",
      "principleFlag",
      "eventId",
      "guestEventType",
      // "fromLocation",
      "locationTypeName",
      "locationName",
      // "itineraryDate",
      // "startDateTime",
      // "endDateTime",
      // "dataUpdatedDateTime",
    ];

    const dateColumns = ["itineraryDate"];

    const dateTimeColumns = [
      "startDateTime",
      "endDateTime",
      //"dataUpdatedDateTime",
    ];

    const intColumns = ["invitedById", "toLocation", "fromLocation"];

    const floatColumns = ["geoLocationLat", "geoLocationLong"];

    const data = importData.filter((item) => {
      let error_flag = false;

      for (let [key, value] of Object.entries(item)) {
        // removing space for all data
        value =
          value != null && typeof value.trim === "function"
            ? value.trim()
            : null;

        // check if value has <script/> tag
        if (
          value.includes("<script>") ||
          value.includes("<script/>") ||
          value.includes("http://") ||
          value.includes("https://")
        ) {
          item["error_message"] = "Invalid value!";
          errorrows.push(item);
          return false;
        }

        // check for integer values
        if (intColumns.includes(key)) {
          const data =
            value != null ? value.replace(/[^a-zA-Z0-9]/g, "") : null;

          if (data !== null && data.trim() !== "" && !integerRegex.test(data)) {
            message.push(`${key} column expects integer or null values`);
            error_flag = true;
          }
        }

        // check for integer values
        if (floatColumns.includes(key)) {
          const data =
            value != null ? value.replace(/[^a-zA-Z0-9.]/g, "") : null;

          if (data !== null && data.trim() !== "" && !floatRegex.test(data)) {
            message.push(
              `${key} column expects floating numbers or null values`
            );
            error_flag = true;
          }
        }

        // Check if any value is empty
        if (nonEmptyColumns.includes(key)) {
          const data =
            value != null ? value.replace(/[^a-zA-Z0-9]/g, "") : null;
          if (data === null || data.trim() === "") {
            message.push(`${key} column can't be empty`);
            error_flag = true;
          }
        }

        // Check if date format is right
        if (dateColumns.includes(key)) {
          const result = validateInput(value);

          if (result.error) {
            message.push(`${key} column ${result.message}`);
            error_flag = true;
          } else {
            const match = value != null ? value.match(date_regex) : null;

            if (!match) {
              message.push(`${key} column is not in expected format`);
              error_flag = true;
            } else {
              // Parse the input date using moment
              const parsedDate = moment(value, "DD-MMMM-YYYY");

              // Format the parsed date to the desired output format
              const formattedDate = parsedDate.format("YYYY-MM-DD");

              item[key] = formattedDate;
            }
          }
        }

        if (dateTimeColumns.includes(key)) {
          const result = validateInput(value);

          if (result.error) {
            message.push(`${key} column ${result.message}`);
            error_flag = true;
          } else {
            const { format, error } = validateDateString(result.sanitizedInput);

            if (error) {
              message.push(`${key} column ${error}`);
              error_flag = true;
            } else {
              const date = moment(value, format);
              item[key] = date.format("DD-MMM-YYYY HH:mm:ss");
            }
          }
        }
      }

      // There are some errors , which will be recorded in Error logs
      if (error_flag) {
        item["error_message"] = message.join(",");
        errorrows.push(item);
        return false;
      }
      // All conditions are met
      item["modifiedBy"] = username;
      item["dataSource"] = "Manual";
      return true;
    });

    return [data, errorrows];
  } catch (error) {
    console.error(error);
  }
};

export const validationUIEditData = (importData, username) => {
  try {
    const errorrows = [];
    const message = [];

    const nonEmptyColumns = [
      "id",
      "class",
      "specialStatus",
      "personID",
      "eventId",
    ];

    const data = importData.filter((item) => {
      let error_flag = false;

      for (let [key, value] of Object.entries(item)) {
        // removing space for all data
        value =
          value != null && typeof value.trim === "function"
            ? value.trim()
            : null;

        // Check if any value is empty
        if (nonEmptyColumns.includes(key)) {
          const data =
            value != null ? value.replace(/[^a-zA-Z0-9]/g, "") : null;
          if (data === null || data.trim() === "") {
            message.push(`${key} column can't be empty`);
            error_flag = true;
          }
        }
      }

      // There are some errors , which will be recorded in Error logs
      if (error_flag) {
        item["error_message"] = message.join(",");
        errorrows.push(item);
        return false;
      }
      // All conditions are met
      item["modifiedBy"] = username;
      item["dataSource"] = "Manual";
      return true;
    });

    return [data, errorrows];
  } catch (error) {
    console.error(error);
  }
};

export const checkLoginStatus = (loginDateTime) => {
  const interval = setInterval(checkSession(loginDateTime), 1000 * 60);
};
function checkSession(loginDateTime) {
  const currentDateTime = new Date();
  if (currentDateTime > loginDateTime) {
    console.log("User is logged in");
    return true;
  } else {
    console.log("User is not logged in");
    return false;
  }
}
