// src/uDetailsSlice.js
import { createSlice } from "@reduxjs/toolkit";

const uDetailsSlice = createSlice({
  name: "uDetails",
  initialState: null,
  reducers: {
    setUDetails: (state, action) => {
      state = action.payload;
      return action.payload;
    },
  },
});

export const { setUDetails } = uDetailsSlice.actions;
export default uDetailsSlice.reducer;
