import moment from "moment"; //new addition

const convertToCSV = (data, columns) => {
  const csvCols = columns.map((item) => item.field);

  // Convert data to CSV format
  const csvrows = data
    .map((item) => {
      return csvCols
        .map((column) => {
          // Check if the value is a string and contains a comma
          const value =
            typeof item[column] === "string" && item[column].includes(",")
              ? `"${item[column]}"`
              : item[column];
          return value;
        })
        .join(",");
    })
    .join("\n");

  const csvData = csvCols + "\n" + csvrows;
  return "data:text/csv;charset=utf-8," + encodeURIComponent(csvData);
};

const convertToEmergencyCSV = (data) => {
  const csvCols = ["personID"];

  // Convert data to CSV format
  const csvrows = data
    .map((item) => {
      return csvCols
        .map((column) => {
          // Check if the value is a string and contains a comma
          const value =
            typeof item[column] === "string" && item[column].includes(",")
              ? `"${item[column]}"`
              : item[column];
          return value;
        })
        .join(",");
    })
    .join("\n");

  const csvData = csvCols + "\n" + csvrows;
  return "data:text/csv;charset=utf-8," + encodeURIComponent(csvData);
};

export function exportToCSV(selectRows, columns, fileName) {
  let timestamp = moment().format(); //new Date().toISOString();/moment().format("DD-MM-YYYY|HH:mm:ss")
  const csvData = convertToCSV(selectRows, columns);
  const link = document.createElement("a");
  link.href = csvData;
  link.setAttribute("download", fileName + "data_" + timestamp + ".csv");
  document.body.appendChild(link);
  link.click();
}

export function exportToEmergencyCSV(selectRows, fileName) {
  let timestamp = moment().format(); //new Date().toISOString();
  const csvData = convertToEmergencyCSV(selectRows);
  const link = document.createElement("a");
  link.href = csvData;
  link.setAttribute("download", fileName + "data_" + timestamp + ".csv");
  document.body.appendChild(link);
  link.click();
}
