// store.js
import { configureStore } from "@reduxjs/toolkit";
import parametersReducer from "./parametersSlice";
import parameters2Reducer from "./parameters2Slice";
import tabularDataReducer from "./tabularSlice";
import configReducer from "./configSlice";
import uDetailsReducer from "./uDetailsSlice";

const store = configureStore({
  reducer: {
    parameters: parametersReducer,
    config: configReducer,
    uDetails: uDetailsReducer,
    parameters2: parameters2Reducer,
    tabularData: tabularDataReducer,
    // Add other reducers here if needed
  },
});

export default store;
