import {
  Grid,
  Stack,
  Autocomplete,
  Checkbox,
  Typography,
  TextField,
  Box,
  useTheme,
  FormControlLabel,
  Switch,
  Button,
  Chip,
  Select,
  CircularProgress,
  MenuItem,
  ListItemText,
  FormControl,
  InputLabel,
  OutlinedInput,
  Snackbar,
  Alert,
  Tooltip,
} from "@mui/material";

import {
  LocalizationProvider,
  DatePicker,
  TimePicker,
  renderDigitalClockTimeView,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import {
  fetchAutocompleteLocationsData,
  trigeerGmrIntegrationOnRefresh,
} from "../../../utils/parentservices";
import {
  formatTime,
  formatDate,
  generateAllDayDate,
} from "../../../utils/utils";

const EventTypeListSelect = ({
  menuitems,
  selectedValues,
  setselectedEventType,
  onSelectionChange,
}) => {
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    const dataValue = typeof value === "string" ? value.split(",") : value;
    setselectedEventType(dataValue);
    onSelectionChange(dataValue);
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  return (
    <div>
      <FormControl
        sx={{
          width: "100%",
          flexWrap: "wrap",
          // marginTop: "8px",
          color: "black",
        }}
      >
        <InputLabel>Event Type</InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={selectedValues}
          input={
            <OutlinedInput sx={{ borderRadius: "30px" }} label="Event Type" />
          }
          onChange={handleChange}
          renderValue={(selected) => (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {selected.slice(0, 2).map((value) => (
                <Chip key={value} label={value} />
              ))}
              {selected.length > 2 && (
                <Chip key="more" label={`+${selected.length - 2}`} />
              )}
            </Box>
          )}
          sx={{
            borderRadius: "30px", // Add border radius to the Select component
          }}
        >
          {menuitems?.length <= 0 ? (
            <CircularProgress />
          ) : (
            menuitems?.map((item, index) => (
              <MenuItem key={index} value={item}>
                <Checkbox checked={selectedValues.indexOf(item) > -1} />
                <ListItemText primary={item} />
              </MenuItem>
            ))
          )}
        </Select>
      </FormControl>
    </div>
  );
};
const SubHeaderFilter = ({
  showDateTime = true,
  onDateSelectionChange,
  filterParameters,
  eventSchedule,
  eventTypeListData,
  isAutoRefresh,
}) => {
  // filter parameters - with data from parent page
  const [dateTimeFilters, setDateTimeFilters] = React.useState({
    ...filterParameters,
  });
  const [dateTimeFilters2, setDateTimeFilters2] = React.useState({
    ...filterParameters,
  });
  const [locationList, setLocationList] = useState([]); // State to store autocomplete options
  const [locationInputValue, setLocationInputValue] = useState(""); // State to store input value
  const [selectedLocationList, setSelectedLocationList] = useState([]);
  const [selectedEventType, setselectedEventType] = useState([]);
  const [eventTypeList, seteventTypeList] = useState([]);
  const DATE_FORMAT = "yyyy-MM-dd";
  const theme = useTheme();
  const [isAlldaychecked, setisAlldaychecked] = useState(
    filterParameters.isAllDay
    //false
  );

  const [snackOpen, setsnackOpen] = useState(false);
  const [snackMessage, setsnackMessage] = useState({
    message: "",
    severity: "success",
  });

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  useEffect(() => {
    setDateTimeFilters({ ...filterParameters });
  }, [filterParameters]);
  useEffect(() => {
    seteventTypeList(eventTypeListData);
  }, [eventTypeListData]);

  // useEffect to fetch eventType List for event schedule page on page onload
  // useEffect(() => {
  //   async function fetchEventTypeListData() {
  //     const resp = await fetchEventTypeFilters();
  //     if (
  //       resp?.data?._result &&
  //       resp?.data?._code === 200 &&
  //       !resp?.data?._isError
  //     ) {
  //       const { eventTypeList, refreshLogsDetails } = resp?.data?._result;
  //       console.log("fetchEventTypeFilters ->>>>>>", resp);
  //       if (refreshLogsDetails && refreshLogsDetails.length > 0) {
  //         const lastRefreshObj = refreshLogsDetails[0];
  //         setDateTimeFilters({
  //           ...dateTimeFilters,
  //           lastfreshDate: lastRefreshObj.lastRefreshDateTime,
  //         });
  //         setIsAutoRefresh(lastRefreshObj.isAutoRefresh === 1 ? true : false);
  //         filterParameters.lastfreshDate = lastRefreshObj.lastRefreshDateTime;
  //       }

  //       //const data = resp?.eventTypeList;
  //       seteventTypeList(eventTypeList?.map((item) => item.label));
  //       setDateTimeFilters({ ...filterParameters });
  //     }
  //   }

  //   fetchEventTypeListData();
  // }, []);

  // useEffect to fetch eventType List for event schedule page on page onload
  // useEffect(() => {
  //   async function fetchEventTypeListData() {
  //     const resp = await fetchEventTypeFilters();
  //     // console.log("fetchEventTypeFilters ->>>>>>", resp);
  //     const data = resp?.eventTypeList;
  //     seteventTypeList(data?.map((item) => item.label));
  //   }

  //   fetchEventTypeListData();
  // }, []);

  const handleChangeSetParameters = (newValue, tag) => {
    // Added second parameters to formatTime
    const parameterData = {
      ...filterParameters,
    };
    let { startDate, endDate, startTime, endTime } = {};

    if (tag === "isAllDay" && newValue) {
      ({ startDate, endDate, startTime, endTime } = generateAllDayDate());
      setDateTimeFilters2({ ...dateTimeFilters });
    } else if (tag === "isAllDay" && !newValue) {
      startDate = dateTimeFilters2.startDate;
      endDate = dateTimeFilters2.endDate;
      startTime = dateTimeFilters2.startTime;
      endTime = dateTimeFilters2.endTime;
    } else if (tag !== "isAllDay" && parameterData.isAllDay) {
      setDateTimeFilters({
        ...dateTimeFilters,
        isAllDay: false,
      });
      parameterData.isAllDay = false;
    }

    if (!startDate && !endDate && !startTime && !endTime) {
      startDate = filterParameters.startDate;
      endDate = filterParameters.endDate;
      startTime = filterParameters.startTime;
      endTime = filterParameters.endTime;
    }

    switch (tag) {
      case "startDate":
        const tempTime3 = new Date(formatTime(parameterData.startTime));
        //const dt = formatTime(newValue);
        const finalEndTime3 = tempTime3.setHours(tempTime3.getHours() + 1);
        setDateTimeFilters({
          ...dateTimeFilters,
          startDate: newValue,
          endDate:
            formatTime(parameterData.endTime) > formatDate(newValue)
              ? parameterData.endDate
              : newValue,
          endTime:
            formatTime(parameterData.endTime) <
              formatTime(parameterData.startTime) &&
            formatDate(newValue) === formatDate(parameterData.endDate)
              ? formatTime(finalEndTime3)
              : parameterData.endTime,
        });
        parameterData.startDate = formatDate(newValue);
        parameterData.endDate =
          formatTime(parameterData.endTime) > formatDate(newValue)
            ? formatDate(parameterData.endDate)
            : formatDate(newValue);

        parameterData.endTime =
          formatTime(parameterData.endTime) <
            formatTime(parameterData.startTime) &&
          formatDate(newValue) === formatDate(parameterData.endDate)
            ? formatTime(finalEndTime3)
            : parameterData.endTime;
        parameterData.startTime = parameterData.startTime
          ? formatTime(parameterData.startTime)
          : null;
        // code block
        break;
      case "endDate":
        const tempTime4 = new Date(formatTime(parameterData.endTime));

        const finalStartTime4 = tempTime4.setHours(tempTime4.getHours() - 1);

        setDateTimeFilters({
          ...dateTimeFilters,
          endDate: newValue,
          startTime:
            formatTime(parameterData.endTime) <
              formatTime(parameterData.startTime) &&
            formatDate(newValue) === formatDate(parameterData.startDate)
              ? formatTime(finalStartTime4)
              : parameterData.startTime,
        });
        parameterData.endDate = formatDate(newValue); //newValue.toISOString().split("T")[0];
        parameterData.startDate = parameterData.startDate
          ? formatDate(parameterData.startDate) //parameterData.startDate.toISOString().split("T")[0]
          : null;
        parameterData.endTime = parameterData.endTime
          ? formatTime(parameterData.endTime)
          : null;
        parameterData.startTime =
          formatTime(parameterData.endTime) <
            formatTime(parameterData.startTime) &&
          formatDate(newValue) === formatDate(parameterData.startDate)
            ? formatTime(finalStartTime4)
            : parameterData.startTime;
        // code block
        break;
      case "startTime":
        const tempTime = new Date(formatTime(newValue));

        const finalEndTime = tempTime.setHours(tempTime.getHours() + 1);

        setDateTimeFilters({
          ...dateTimeFilters,
          startTime: newValue,
          endTime:
            formatTime(parameterData.endTime) < formatTime(newValue) &&
            formatDate(parameterData.startDate) ===
              formatDate(parameterData.endDate)
              ? formatTime(finalEndTime)
              : parameterData.endTime,
        });
        parameterData.startTime = formatTime(newValue);
        parameterData.endTime =
          formatTime(parameterData.endTime) < formatTime(newValue) &&
          formatDate(parameterData.startDate) ===
            formatDate(parameterData.endDate)
            ? formatTime(finalEndTime)
            : parameterData.endTime;

        parameterData.endDate = parameterData.endDate
          ? formatDate(parameterData.endDate) //parameterData.endDate.toISOString().split("T")[0]
          : null;
        parameterData.startDate = parameterData.startDate
          ? formatDate(parameterData.startDate) //parameterData.startDate.toISOString().split("T")[0]
          : null;
        // code block
        break;
      case "endTime":
        const tempTime2 = new Date(formatTime(newValue));

        const finalStartTime = tempTime2.setHours(tempTime2.getHours() - 1);

        setDateTimeFilters({
          ...dateTimeFilters,
          endTime: newValue,
          startTime:
            formatTime(parameterData.startTime) > formatTime(newValue) &&
            formatDate(parameterData.startDate) ===
              formatDate(parameterData.endDate)
              ? formatTime(finalStartTime)
              : formatTime(parameterData.startTime),
        });
        parameterData.endTime = formatTime(newValue);
        parameterData.startTime =
          formatTime(parameterData.startTime) > formatTime(newValue) &&
          formatDate(parameterData.startDate) ===
            formatDate(parameterData.endDate)
            ? formatTime(finalStartTime)
            : formatTime(parameterData.startTime);
        parameterData.endDate = parameterData.endDate
          ? formatDate(parameterData.endDate) //parameterData.endDate.toISOString().split("T")[0]
          : null;
        parameterData.startDate = parameterData.startDate
          ? formatDate(parameterData.startDate) //parameterData.startDate.toISOString().split("T")[0]
          : null;
        // code block
        break;
      case "isAllDay":
        setDateTimeFilters({
          ...dateTimeFilters,
          isAllDay: newValue,
        });
        parameterData.isAllDay = newValue;
        parameterData.endTime = endTime;
        parameterData.startTime = startTime;

        parameterData.endDate = endDate; //parameterData.endDate.toISOString().split("T")[0]

        parameterData.startDate = startDate;

        // code block
        break;
      case "location":
        setDateTimeFilters({
          ...dateTimeFilters,
          location: newValue,
        });
        parameterData.location = newValue?.map((item) => item.label);
        parameterData.endTime = endTime;
        parameterData.startTime = startTime;

        parameterData.endDate = endDate; //parameterData.endDate.toISOString().split("T")[0]

        parameterData.startDate = startDate;

        // code block
        break;
      case "eventType":
        setDateTimeFilters({
          ...dateTimeFilters,
          eventType: newValue,
        });
        parameterData.eventType = newValue?.map((item) => item);
        parameterData.endTime = endTime;
        parameterData.startTime = startTime;

        parameterData.endDate = endDate; //parameterData.endDate.toISOString().split("T")[0]

        parameterData.startDate = startDate;

        // code block
        break;
      default:
      // code block
    }

    onDateSelectionChange(parameterData);
  };

  // Function to fetch autocomplete options from backend API
  const fetchLocationsData = async (searchValue) => {
    try {
      const paramter = {
        inputParam: {
          searchValue: searchValue,
        },
      };
      // Call your backend API to fetch autocomplete options fetchAutocompleteLocationsData
      const token = localStorage.getItem("idToken");
      const response = await fetchAutocompleteLocationsData(paramter, token);

      if (
        response?.data?._result &&
        response?.data?._code === 200 &&
        !response?.data?._isError
      ) {
        const { list } = response?.data?._result;
        setLocationList(list); // Update state with fetched options
        return list;
      }
    } catch (error) {
      console.error("Error fetching autocomplete options:", error);
    }
  };

  // Function to handle input change and fetch options after 3 characters
  const handleAutocompleteInputChange = async (event, newValue) => {
    setLocationInputValue(newValue);
    if (newValue.length >= 3) {
      const list = await fetchLocationsData(newValue);

      setLocationList(list); // Update state with fetched options
    }
  };

  const handleOptionSelected = (event, value) => {
    setSelectedLocationList([...selectedLocationList, value]);
    handleChangeSetParameters(value, "location");
  };

  const onSelectionEventTypeChange = (event, value) => {
    handleChangeSetParameters(event, "eventType");
  };

  const onhandleRefreshData = async () => {
    const inputParam = {
      isAutoRefresh: false,
    };
    const token = localStorage.getItem("idToken");
    const response = await trigeerGmrIntegrationOnRefresh(inputParam, token);

    if (
      response?.data?._result &&
      response?.data?._code === 200 &&
      !response?.data?._isError
    ) {
      const result = response?.data?._result;
      if (
        result &&
        result.statusCode &&
        result.statusCode === 200 &&
        result.success
      ) {
        setsnackOpen(true);
        setsnackMessage({
          message: result.message,
          severity: "success",
        });
      } else if (
        result &&
        result.statusCode &&
        result.statusCode === 200 &&
        !result.success
      ) {
        setsnackOpen(true);
        setsnackMessage({
          message: result.message,
          severity: "info",
        });
      }
    } else {
      setsnackOpen(true);
      setsnackMessage({
        message: response?._message,
        severity: "error",
      });
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setsnackOpen(false);
  };

  return (
    <Box
      sx={{
        display: { xs: "none", md: "flex" },
        flexDirection: "row",
        width: "100%",
        // height: "8%",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", // Bottom shadow
        // marginBottom: "-5px",
      }}
    >
      {/* First grid with 20% width - AutoComplete grid */}
      {eventSchedule === true ? (
        <Box
          sx={{
            width: "20%",
            marginRight: { xs: 0, sm: theme.spacing(0.2) },
          }}
        >
          <Grid container sx={{ width: "100%" }}>
            <Grid item sx={{ width: "100%" }}>
              <EventTypeListSelect
                menuitems={eventTypeList}
                selectedValues={selectedEventType}
                setselectedEventType={setselectedEventType}
                onSelectionChange={onSelectionEventTypeChange}
              />
            </Grid>
          </Grid>
        </Box>
      ) : (
        <Box
          sx={{
            width: "20%",
            marginRight: { xs: 0, sm: theme.spacing(0.2) },
          }}
        >
          <Grid
            container
            sx={{ width: "100%", height: "100%", borderRadius: "30px" }}
          >
            {/* <Grid item sx={{ width: "100%" }}> */}
            <Autocomplete
              sx={{
                mr: 1,
                display: { xs: "none", md: "flex" },
                width: "100%",
                flexWrap: "wrap",
                marginTop: "2px !important",
                marginLeft: "2px !important",
                height: "80%",
                borderRadius: "30px",
              }}
              // sx={{ minWidth: { xs: "150px", s: "250", md: "300px" } }}
              multiple
              fullWidth={false}
              id="checkboxes-tags-demo"
              inputValue={locationInputValue}
              options={locationList || []}
              disableCloseOnSelect
              getOptionLabel={(option) => option.label}
              onInputChange={handleAutocompleteInputChange}
              onChange={handleOptionSelected}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.label}
                </li>
              )}
              renderTags={(value, getTagProps) => {
                return (
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: 0.5,
                      mr: 1,
                      display: { xs: "none", md: "flex" },
                      borderRadius: "30px",
                    }}
                  >
                    {value.slice(0, 2).map((item, index) => (
                      <Chip
                        key={value}
                        label={item.label}
                        {...getTagProps({ index })}
                      />
                    ))}
                    {value.length > 2 && (
                      <Chip key="more" label={`+${value.length - 2}`} />
                    )}
                  </Box>
                );
              }}
              renderInput={(params) => {
                return (
                  <TextField
                    sx={{
                      width: "100%",
                      flexWrap: "wrap",
                      mr: 1,
                      display: { xs: "none", md: "flex" },
                      borderRadius: "30px",
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "30px", // Ensures the input field within the TextField has rounded corners
                      },
                    }}
                    {...params}
                    label="Location"
                    InputProps={{
                      ...params.InputProps,
                      type: "search",
                    }}
                  />
                );
              }}
            />
          </Grid>
        </Box>
      )}
      {/* Middle grid with 65% width - DateTime picker*/}
      {showDateTime === true ? (
        <Box
          sx={{
            width: "70%",
            marginRight: { xs: 0, sm: theme.spacing(0.2) },
          }}
        >
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            flexWrap={"nowrap"}
            spacing={2}
            sx={{ width: "100%" }}
          >
            <Grid item sx={{ paddingTop: "8px !important" }}>
              <Grid
                container
                spacing={0.1}
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                flexWrap={"nowrap"}
              >
                <Grid item sx={{ marginTop: "0.7rem" }}>
                  <Typography
                    sx={{
                      display: { xs: "none", md: "flex" },
                      flexGrow: 1,
                    }}
                  >
                    From
                  </Typography>
                </Grid>
                {/* <Grid item sx={{ width: "100%", height: "100%" }}> */}
                <Grid
                  item
                  sx={{
                    // width: "160px",
                    // height: "100%",
                    marginTop: "2px !important",
                    marginLeft: "2px !important",
                    height: "100%",
                  }}
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs} id="Test">
                    <DemoContainer components={["DatePicker", "DatePicker"]}>
                      <Box
                        // sx={{
                        //   width: "200px",
                        // }}
                        sx={{
                          flexGrow: 1,
                          display: { xs: "none", md: "flex" },
                        }}
                      >
                        <DatePicker
                          sx={{
                            flexGrow: 1,
                            display: { xs: "none", md: "flex" },
                            "& .MuiOutlinedInput-root": {
                              borderRadius: "20px", // Ensures the input field within the DatePicker has rounded corners
                              // fontSize: "12px",
                            },
                          }}
                          inputFormat={DATE_FORMAT}
                          value={dayjs(dateTimeFilters.startDate)}
                          onChange={(newValue) =>
                            handleChangeSetParameters(newValue, "startDate")
                          }
                        />
                      </Box>
                    </DemoContainer>
                  </LocalizationProvider>
                </Grid>
                {/* <Grid item sx={{ width: "100%", height: "100%" }}> */}
                <Grid item sx={{ height: "100%" }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["TimePicker", "TimePicker"]}>
                      <Box
                        // sx={{
                        //   width: "135px !important",
                        // }}
                        sx={{
                          flexGrow: 1,
                          display: { xs: "none", md: "flex" },
                        }}
                      >
                        <TimePicker
                          sx={{
                            flexGrow: 1,
                            display: { xs: "none", md: "flex" },
                            "& .MuiOutlinedInput-root": {
                              borderRadius: "20px", // Ensures the input field within the DatePicker has rounded corners
                              // fontSize: "12px",
                              // padding: "3px",
                            },
                          }}
                          timeSteps={{
                            minutes: 30,
                          }}
                          value={dayjs(dateTimeFilters.startTime)}
                          onChange={(newValue) =>
                            handleChangeSetParameters(newValue, "startTime")
                          }
                          viewRenderers={{
                            hours: renderDigitalClockTimeView,
                            minutes: renderDigitalClockTimeView,
                          }}
                        />
                      </Box>
                    </DemoContainer>
                  </LocalizationProvider>
                </Grid>
              </Grid>
            </Grid>

            <Grid item sx={{ paddingTop: "8px !important" }}>
              <Grid
                container
                spacing={0.1}
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                flexWrap={"nowrap"}
                // sx={{ width: "28%" }}
              >
                <Grid item sx={{ marginTop: "0.7rem" }}>
                  <Typography
                    sx={{
                      display: { xs: "none", md: "flex" },
                      flexGrow: 1,
                    }}
                  >
                    To
                  </Typography>
                </Grid>
                {/* <Grid item sx={{ width: "100%", height: "100%" }}> */}
                <Grid
                  item
                  sx={{ height: "100%", marginLeft: "2px !important" }}
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["DatePicker", "DatePicker"]}>
                      <Box
                        //sx={{ width: "200px" }}
                        sx={{
                          flexGrow: 1,
                          display: { xs: "none", md: "flex" },
                        }}
                      >
                        <DatePicker
                          sx={{
                            flexGrow: 1,
                            display: { xs: "none", md: "flex" },
                            "& .MuiOutlinedInput-root": {
                              borderRadius: "20px", // Ensures the input field within the DatePicker has rounded corners
                            },
                          }}
                          inputFormat={DATE_FORMAT}
                          value={dayjs(dateTimeFilters.endDate)}
                          minDate={dayjs(dateTimeFilters.startDate)} // Disable past dates
                          onChange={(newValue) =>
                            handleChangeSetParameters(newValue, "endDate")
                          }
                        />
                      </Box>
                    </DemoContainer>
                  </LocalizationProvider>
                </Grid>
                {/* <Grid item sx={{ width: "100%", height: "100%" }}> */}
                <Grid item sx={{ height: "100%" }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["TimePicker", "TimePicker"]}>
                      <Box
                        // sx={{ width: "135px !important" }}
                        sx={{
                          flexGrow: 1,
                          display: { xs: "none", md: "flex" },
                        }}
                      >
                        <TimePicker
                          sx={{
                            flexGrow: 1,
                            display: { xs: "none", md: "flex" },
                            "& .MuiOutlinedInput-root": {
                              borderRadius: "20px", // Ensures the input field within the DatePicker has rounded corners
                            },
                          }}
                          timeSteps={{
                            minutes: 30,
                          }}
                          value={dayjs(dateTimeFilters.endTime)}
                          onChange={(newValue) =>
                            handleChangeSetParameters(newValue, "endTime")
                          }
                          // minTime={dayjs(dateTimeFilters.startTime)}
                          viewRenderers={{
                            hours: renderDigitalClockTimeView,
                            minutes: renderDigitalClockTimeView,
                          }}
                        />
                      </Box>
                    </DemoContainer>
                  </LocalizationProvider>
                </Grid>
              </Grid>
            </Grid>

            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                // flexWrap={"nowrap"}
              >
                <Grid item>
                  <FormControlLabel
                    sx={{
                      display: { xs: "none", md: "flex" },
                      "& .MuiFormControlLabel-label": {
                        fontSize: "12px", // Adjust the font size as needed
                      },
                    }}
                    control={
                      <Switch
                        sx={{
                          flexGrow: 1,
                          display: { xs: "none", md: "flex" },
                        }}
                        checked={
                          dateTimeFilters?.isAllDay
                            ? dateTimeFilters?.isAllDay
                            : false
                        }
                        onChange={() => {
                          setisAlldaychecked(!dateTimeFilters.isAllDay);
                          handleChangeSetParameters(
                            !dateTimeFilters?.isAllDay,
                            "isAllDay"
                          );
                        }}
                        name="isAllDay"
                        color="primary"
                      />
                    }
                    label="All Day"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      ) : null}

      {/* Last grid with 15% width - Refresh button*/}

      {showDateTime === true ? (
        <Box sx={{ width: "10%", alignContent: "center" }}>
          <Grid container spacing={0.1} direction={"column"}>
            <Grid item>
              <Tooltip
                open={!isAutoRefresh}
                disableHoverListener={isAutoRefresh}
                disableFocusListener={isAutoRefresh}
                disableTouchListener={isAutoRefresh}
                title="Pulling GMR Source data (Running)"
                placement="top-start"
              >
                <span>
                  <Button
                    sx={{
                      flexGrow: 1,
                      display: { xs: "none", md: "flex" },
                      color: isAutoRefresh
                        ? "white !important"
                        : "black !important",
                      backgroundColor: isAutoRefresh ? "primary" : "#a9a7a7",
                    }}
                    variant="contained"
                    disabled={!isAutoRefresh}
                    onClick={onhandleRefreshData}
                  >
                    Refresh
                  </Button>
                </span>
              </Tooltip>
              {/* <Button
                sx={{
                  flexGrow: 1,
                  display: { xs: "none", md: "flex" },
                }}
                variant="contained"
                className="primary"
                onClick={onhandleRefreshData}
              >
                Refresh
              </Button> */}
            </Grid>
            <Grid item sx={{ alignContent: "center" }}>
              <Stack direction={"row"}>
                {/* <Grid item>
                  <Typography
                    fontSize="6"
                    sx={{
                      flexGrow: 1,
                      display: { xs: "none", md: "flex" },
                    }}
                  >
                    Last Refresh
                  </Typography>
                </Grid> */}
                <Grid item sx={{ alignContent: "center" }}>
                  <Typography
                    // fontSize="2"
                    sx={{
                      flexGrow: 1,
                      display: { xs: "none", md: "flex" },
                      fontSize: "12px",
                    }}
                  >
                    {dateTimeFilters.lastfreshDate}
                  </Typography>
                </Grid>
              </Stack>
            </Grid>
          </Grid>
          <Snackbar
            open={snackOpen}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            autoHideDuration={3000}
            onClose={handleSnackbarClose}
          >
            <Alert
              onClose={handleSnackbarClose}
              severity={snackMessage.severity}
              variant="filled"
              sx={{ width: "100%" }}
            >
              {snackMessage.message}
            </Alert>
          </Snackbar>
        </Box>
      ) : null}
    </Box>
  );
};

export default SubHeaderFilter;
