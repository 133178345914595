import { Outlet, Navigate } from "react-router-dom";
import React, { useState, useEffect } from "react";

const PrivateRoutes = () => {
  return localStorage &&
    localStorage.getItem("userName") &&
    localStorage.getItem("userRole") ? (
    <Outlet />
  ) : (
    <Navigate to="/login" />
  );
};

export default PrivateRoutes;
