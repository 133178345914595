export const REACT_APP_API_DATA_ENCRYPT_KEY =
  process.env.REACT_APP_API_DATA_ENCRYPT_KEY;
export const REACT_APP_BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
export const REACT_APP_ADMIN_POOL_NAME = process.env.REACT_APP_ADMIN_POOL_NAME;
export const REACT_APP_GOOGLE_MAPS_API_KEY =
  process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
export const REACT_APP_VIEW_POOL_NAME = process.env.REACT_APP_VIEW_POOL_NAME;
export const REACT_APP_RE_CAPTCHA_KEY = process.env.REACT_APP_RE_CAPTCHA_KEY;

//below example when we need to deploy our application through AWS container and pick env variables at run time.
//export const REACT_APP_VIEW_POOL_NAME = process.env.REACT_APP_VIEW_POOL_NAME;
