// src/configSlice.js
import { createSlice } from "@reduxjs/toolkit";

const configSlice = createSlice({
  name: "config",
  initialState: null,
  reducers: {
    setConfig: (state, action) => {
      state = action.payload;
      return action.payload;
    },
  },
});

export const { setConfig } = configSlice.actions;
export default configSlice.reducer;
