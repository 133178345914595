import {
  Box,
  Grid,
  Button,
  TextField,
  Typography,
  InputAdornment,
  Stack,
  Link,
  Snackbar,
  Alert,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import PersonIcon from "@mui/icons-material/Person";
import LockIcon from "@mui/icons-material/Lock";
import BackDrop from "../common/BackDrop.js";
import {
  awsforgetpassword,
  awsforgetpasswordconfirmation,
} from "../../utils/parentservices";
import ReCAPTCHA from "react-google-recaptcha";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [usernameErr, setusernameErr] = useState("");
  const [captchaToken, setCaptchaToken] = useState(null);
  const [verificationCodeErr, setverificationCodeErr] = useState("");
  const [snackOpen, setsnackOpen] = useState(false);
  const [recaptchaErr, setRecaptchaErr] = useState("");
  const [recaptchaFlag, setRecaptchaFlag] = useState(false);
  const [backdropopen, setbackdropopen] = useState(false);
  const [snackMessage, setsnackMessage] = useState({
    message: "",
    severity: "success",
  });
  const [showConfirmationInput, setshowConfirmationInput] = useState(false);
  const [code, setCode] = useState("");
  const [newPassword, setnewPassword] = useState("");

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setsnackOpen(false);
  };

  const handleConfirmUser = async (e) => {
    setverificationCodeErr("");
    if (code.length <= 0 || newPassword.length <= 0) {
      setsnackOpen(true);
      setsnackMessage({
        message: "Confirmation code or Password is missing!!",
        severity: "info",
      });
    } else {
      try {
        setbackdropopen(true);
        const param = {
          username: username,
          confirmationCode: code,
          password: newPassword,
        };
        const response = await awsforgetpasswordconfirmation(param);
        if (
          response?.data?._result &&
          response?.data?._code === 200 &&
          !response?.data?._isError
        ) {
          const resp = response?.data?._result;
          //console.log("resp", resp);
          if (resp && resp.statusCode && resp.success) {
            setsnackOpen(true);
            setsnackMessage({
              message:
                "Password reset successful!! Redirecting to login page...",
              severity: "success",
            });
            setverificationCodeErr("");
            setTimeout(() => {
              navigate("/login");
            }, 2000);
          } else if (resp && resp.statusCode && !resp.success) {
            setsnackOpen(true);
            setsnackMessage({
              message: resp.message,
              severity: "error",
            });
          } else {
            setsnackOpen(true);
            setsnackMessage({
              message: "Please try after sometime!!",
              severity: "error",
            });
          }
        }
      } catch (err) {
        setverificationCodeErr(err.message);
        //console.log(err.message);
      }
      setbackdropopen(false);
    }
  };

  const validation = () => {
    return new Promise((resolve, reject) => {
      if (username === "" && !recaptchaFlag) {
        setusernameErr("Username is Required!");
        setRecaptchaErr("Please select recaptcha");
        resolve({
          username: "Username is Required",
          recaptcha: "Please select recaptcha",
        });
      } else if (!recaptchaFlag) {
        setRecaptchaErr("Please select recaptcha");
        resolve({ recaptcha: "Please select recaptcha", username: "" });
      } else if (username === "") {
        setusernameErr("Username is Required!");
        resolve({ recaptcha: "", username: "Username is Required!" });
      } else {
        resolve({ username: "", recaptcha: "" });
      }
      reject("");
    });
  };

  const handleForgotpassword = async () => {
    setusernameErr("");
    setRecaptchaErr("");

    try {
      const res = await validation();
      if (res.username === "" && res.recaptcha === "") {
        try {
          const param = { username: username, captchaToken: captchaToken };
          setbackdropopen(true);
          const response = await awsforgetpassword(param);
          if (
            response?.data?._result &&
            response?.data?._code === 200 &&
            !response?.data?._isError
          ) {
            const resp = response?.data?._result;
            if (resp && resp.statusCode && resp.success) {
              setshowConfirmationInput(true);
              setsnackMessage({
                message: resp.message,
                severity: "success",
              });
            } else if (resp && resp.statusCode && !resp.success) {
              setusernameErr(resp.message);
              setsnackMessage({
                message: resp.message,
                severity: "error",
              });
            } else {
              setusernameErr("Please try after sometime!!");
              setsnackMessage({
                message: "Please try after sometime!!",
                severity: "error",
              });
            }
          }
        } catch (e) {
          console.log(e);
        }
        setbackdropopen(false);
      } else {
        setusernameErr(res.username || res.recaptcha);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent the default form submission
      handleForgotpassword();
    }
  };

  const handleKeyDownConfirm = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent the default form submission
      handleConfirmUser();
    }
  };

  const onRecaptchaChange = (token) => {
    setRecaptchaFlag(!recaptchaFlag);
    setCaptchaToken(token);
  };
  return (
    <Box sx={{ width: "350px", height: "350px", marginTop: "-90px" }}>
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={1}
        sx={{ width: "100%" }}
      >
        {/* Welcome text */}
        <Grid item sx={{ width: "100%" }}>
          <Typography variant="h5" fontWeight="bold">
            Retrieve Password
          </Typography>
        </Grid>

        {/*  Username */}
        <Grid item sx={{ width: "100%" }}>
          <Grid container spacing={1}>
            <Grid item>
              <Typography variant="body1" fontWeight="bold">
                Username
              </Typography>
            </Grid>
            <Grid item sx={{ width: "88%" }}>
              <TextField
                fullWidth
                id="fullWidth"
                placeholder="Username"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PersonIcon />
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => setUsername(e.target.value)}
                onKeyDown={handleKeyDown}
              ></TextField>
            </Grid>
          </Grid>
        </Grid>

        {/* ReCAPTCHA  */}
        <Grid item sx={{ width: "100%" }}>
          <Grid container spacing={1}>
            <Grid item>
              <Typography variant="body1">
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_RE_CAPTCHA_KEY}
                  onChange={onRecaptchaChange}
                />
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        {/* forgot password  Button */}
        <Grid item sx={{ width: "100%" }}>
          <Button
            variant="contained"
            className="primary"
            sx={{ width: "88%" }}
            disabled={!recaptchaFlag}
            onClick={handleForgotpassword}
          >
            Send Confirmation Code
          </Button>
        </Grid>

        <Grid
          item
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            typography: "body1",
            "& > :not(style) ~ :not(style)": {
              ml: 2,
            },
            width: "100%",
          }}
        ></Grid>

        {showConfirmationInput && (
          <Grid item sx={{ width: "100%" }}>
            <Grid
              container
              direction={"row"}
              spacing={1}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Grid item sx={{ width: "100%" }} component="form">
                <TextField
                  fullWidth
                  placeholder="Password"
                  id="fullWidth"
                  type="password"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockIcon />
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) => setnewPassword(e.target.value)}
                ></TextField>
              </Grid>
              <Grid item sx={{ width: "50%" }}>
                <TextField
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                  label="Confirmation code"
                  onKeyDown={handleKeyDownConfirm}
                />
              </Grid>
              <Grid item sx={{ width: "50%" }}>
                <Button
                  variant="contained"
                  className="primary"
                  sx={{ width: "88%" }}
                  onClick={handleConfirmUser}
                >
                  Confirm User
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
        <hr />
        {usernameErr && (
          <Typography sx={{ color: "red" }}>{usernameErr}</Typography>
        )}

        {verificationCodeErr && (
          <Typography sx={{ color: "red" }}>{verificationCodeErr}</Typography>
        )}
      </Grid>
      <Snackbar
        open={snackOpen}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackMessage.severity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackMessage.message}
        </Alert>
      </Snackbar>
      <BackDrop
        backdropopen={backdropopen}
        backdropmessage={"Please wait..."}
      />
    </Box>
  );
};

export default ForgotPassword;
