import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Tab,
  Tabs,
  Typography,
  Box,
  Grid,
  Stack,
  Snackbar,
  Alert,
} from "@mui/material";
import AllocationEvent from "../components/Allocation/AllocationEvent";
import ManualFeed from "../components/Allocation/ManualFeed";
import SubHeaderFilter from "../components/common/SubHeaderFilter/SubHeaderFilter";
import {
  fetchAllocationEventFeed,
  fetchAcLogReport,
  fetchConflictsReport,
  fetchAllocationManualFeed,
  fetchAllocationDropdownList,
  fetchEventTypeFilters,
  fetchFirstTimeAllocationEventFeed,
  fetchFirstTimeAllocationManualFeed,
} from "../utils/parentservices";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setParameters } from "../redux/parametersSlice";
import { setParameters2 } from "../redux/parameters2Slice";
import { setTabularData } from "../redux/tabularSlice";
import { encryptDataFn, decryptDataFn } from "../utils/parentservices";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}

const Allocation = () => {
  const dispatch = useDispatch();
  const [tabindex, setTabIndex] = useState(0);
  const [filterParameters, setFilterParameters] = useState({
    startDate: null,
    endDate: null,
    startTime: null,
    endTime: null,
    location: [],
    isAllDay: false,
    startLimit: 0,
  });

  const [feedData, setFeedData] = useState([]);
  const [feedCount, setFeedCount] = useState(null);
  const [manualFeedData, setmanualFeedData] = useState([]);
  const [manualfeedCount, setmanualfeedCount] = useState(null);
  const [backdropopen, setbackdropopen] = useState(false);

  const [guestList, setguestList] = useState([]);
  const [eventList, seteventList] = useState([]);
  const [userClassList, setuserClassList] = useState([]);
  const [specialStatusList, setspecialStatusList] = useState([]);
  const [isAutoRefresh, setIsAutoRefresh] = useState(true);
  const [eventTypeList, seteventTypeList] = useState([]);

  const [snackOpen, setsnackOpen] = useState(false);
  const [snackMessage, setsnackMessage] = useState({
    message: "",
    severity: "success",
  });

  const reduxParameter = useSelector((state) => state.parameters);
  const reduxTabularData = useSelector((state) => state.tabularData);
  const [progressPercentage, setProgressPercentage] = useState(0);

  const handleChange = async (event, newValue) => {
    setTabIndex(newValue);
    if (newValue === 0) {
      await fetchAllocationDataOnloadData(filterParameters);
    } else {
      await fetchAllocationManualFeedData(filterParameters);
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setsnackOpen(false);
  };

  // for first time - on load
  useEffect(() => {
    const getData = async () => {
      setbackdropopen(true);
      const token = localStorage.getItem("idToken");
      const resp = await fetchEventTypeFilters(token);
      let lastRefreshObj = null;
      if (
        resp?.data?._result &&
        resp?.data?._code === 200 &&
        !resp?.data?._isError
      ) {
        const { eventList, refreshLogsDetails } = resp?.data?._result;
        if (refreshLogsDetails && refreshLogsDetails.length > 0) {
          lastRefreshObj = refreshLogsDetails[0];

          setIsAutoRefresh(lastRefreshObj.isAutoRefresh === 1 ? true : false);
          filterParameters.lastfreshDate = lastRefreshObj.lastRefreshDateTime;
        }

        seteventTypeList(eventList?.map((item) => item.label));
        setFilterParameters({ ...filterParameters });
        // Get current date and time
        const currentDateObj = new Date();
        // Add 30 minutes
        const dateTimeAfterObj = new Date(
          currentDateObj.getTime() + 30 * 60 * 1000
        );

        // Subtract 30 minutes
        const dateTimeBeforeObj = new Date(
          currentDateObj.getTime() - 30 * 60 * 1000
        );

        // Format dates and times
        const formatDate = (dateObj) => {
          const year = dateObj.getFullYear();
          const month = String(dateObj.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
          const day = String(dateObj.getDate()).padStart(2, "0");
          const hours = String(dateObj.getHours()).padStart(2, "0");
          const minutes = String(dateObj.getMinutes()).padStart(2, "0");
          const seconds = String(dateObj.getSeconds()).padStart(2, "0");

          return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        };
        let parameters = null;
        if (reduxParameter) {
          setFilterParameters({
            ...filterParameters,
            startDate: reduxParameter.startDate,
            endDate: reduxParameter.endDate,
            startTime: reduxParameter.startTime,
            endTime: reduxParameter.endTime,
            lastfreshDate: lastRefreshObj.lastRefreshDateTime
              ? lastRefreshObj.lastRefreshDateTime
              : reduxParameter.lastfreshDate,
            isAllDay: reduxParameter.isAllDay,
            startLimit: 0,
          });
          parameters = {
            ...filterParameters,
          };
          parameters = {
            ...parameters,
            startDate: reduxParameter.startDate,
            endDate: reduxParameter.endDate,
            startTime: reduxParameter.startTime,
            endTime: reduxParameter.endTime,
            isAllDay: reduxParameter.isAllDay,
            startLimit: 0,
          };
        } else {
          setFilterParameters({
            ...filterParameters,
            startDate: formatDate(currentDateObj),
            endDate: formatDate(currentDateObj),
            startTime: formatDate(dateTimeBeforeObj),
            endTime: formatDate(dateTimeAfterObj),
            lastfreshDate: lastRefreshObj.lastRefreshDateTime
              ? lastRefreshObj.lastRefreshDateTime
              : formatDate(currentDateObj),
            isAllDay: false,
            startLimit: 0,
          });
          parameters = {
            ...filterParameters,
          };
          parameters = {
            ...parameters,
            startDate: formatDate(currentDateObj),
            endDate: formatDate(currentDateObj),
            startTime: formatDate(dateTimeBeforeObj),
            endTime: formatDate(dateTimeAfterObj),
            isAllDay: false,
            startLimit: 0,
          };
        }
        // Fixed here for destroy error :- We can't call async directoly on useEffect
        fetchAllocationDataOnloadData(parameters);
      }
    };
    getData();
  }, []);

  const fetchAllocationDataOnloadData = async (parameters) => {
    try {
      setbackdropopen(true);
      if (
        parameters &&
        parameters.startDate &&
        parameters.endDate &&
        parameters.startTime &&
        parameters.endTime
      ) {
        let shouldContinue = true;
        let dataList = [];
        const token = localStorage.getItem("idToken");
        let counter = 0;
        let totalCount = 0;
        let progressPerc = 0;
        let currentPerc = 0;
        setProgressPercentage(0);
        while (shouldContinue) {
          let response = null;
          if (counter === 0) {
            const parameter = {
              inputParam: {
                ...parameters,
              },
            };

            response = await fetchFirstTimeAllocationEventFeed(
              parameter,
              token
            );
            counter = counter + 1;
          } else if (counter < 30) {
            parameters.startLimit =
              counter === 1
                ? parameters.startLimit + 10000 + 1
                : parameters.startLimit + 10000;
            const parameter = {
              inputParam: {
                ...parameters,
              },
            };
            response = await fetchAllocationEventFeed(parameter, token);
            counter = counter + 1;
          }
          if (
            response?.data?._result &&
            response?.data?._code === 200 &&
            !response?.data?._isError
            // true
          ) {
            let manualFeedDataList = [];
            let chunkCount = 0;

            if (counter === 1 || counter === 0) {
              const { manualFeedData, count, manualFeedDataTotalCount } =
                response?.data?._result;
              chunkCount = count;
              totalCount = manualFeedDataTotalCount[0].total_count;

              manualFeedDataList = [...manualFeedData];
            } else {
              const { manualFeedData, count } = response?.data?._result;
              chunkCount = count;

              manualFeedDataList = [...manualFeedData];
            }
            if (totalCount <= 0) {
              currentPerc = 0;
            } else {
              currentPerc = (10000 / totalCount) * 100;
            }
            progressPerc =
              parseInt(progressPerc + currentPerc) > 100
                ? 100
                : parseInt(progressPerc + currentPerc);

            handlePercentChange(progressPerc);

            //cnt = cnt - 1;
            if (chunkCount === 0) {
              shouldContinue = false;
            } else if (chunkCount > 0 && chunkCount < 10000) {
              dataList.push(manualFeedDataList);
              shouldContinue = false;
            } else {
              dataList.push(manualFeedDataList);
            }
          } else {
            shouldContinue = false;
          }
        }

        let parentList = [];
        if (dataList && dataList.length > 0) {
          // Iterate over each sublist in dataList and push objects into parentList
          for (const key in dataList) {
            if (dataList.hasOwnProperty(key)) {
              parentList.push(...dataList[key]);
            }
          }
          const temp = parentList.map((item) => {
            const obj = {};
            for (const key in item) {
              obj[key] = item[key];
            }
            return obj;
          });

          setFeedData(temp);
          setFeedCount(parentList.length + 1);
        }
        //}
      }
    } catch (error) {
      console.error("Error:", error);
      // Handle error as needed
    }
    setbackdropopen(false);
  };
  const handlePercentChange = (val) => {
    setProgressPercentage(val);
  };
  const fetchAllocationManualFeedData = async (param) => {
    try {
      setbackdropopen(true);
      let parameters = {
        startDate: null,
        endDate: null,
        startTime: null,
        endTime: null,
        location: param.location,
        startLimit: 0,
      };
      // const token = localStorage.getItem("idToken");

      // adding fetch chunk + no enncryption
      let shouldContinue = true;
      let dataList = [];
      const token = localStorage.getItem("idToken");
      let counter = 0;
      let totalCount = 0;
      let progressPerc = 0;
      let currentPerc = 0;
      setProgressPercentage(0);

      while (shouldContinue) {
        let response = null;
        if (counter === 0) {
          const parameter = {
            inputParam: {
              ...parameters,
            },
          };

          response = await fetchFirstTimeAllocationManualFeed(parameter, token);
          counter = counter + 1;
        } else if (counter < 30) {
          parameters.startLimit =
            counter === 1
              ? parameters.startLimit + 10000 + 1
              : parameters.startLimit + 10000;
          const parameter = {
            inputParam: {
              ...parameters,
            },
          };
          response = await fetchAllocationManualFeed(parameter, token);
          counter = counter + 1;
        }
        if (
          response?.data?._result &&
          response?.data?._code === 200 &&
          !response?.data?._isError
          // true
        ) {
          let manualFeedDataList = [];
          let chunkCount = 0;

          if (counter === 1 || counter === 0) {
            const { manualFeedData, count, manualFeedDataTotalCount } =
              response?.data?._result;
            chunkCount = count;
            totalCount = manualFeedDataTotalCount[0].total_count;

            manualFeedDataList = [...manualFeedData];
          } else {
            const { manualFeedData, count } = response?.data?._result;
            chunkCount = count;

            manualFeedDataList = [...manualFeedData];
          }
          if (totalCount <= 0) {
            currentPerc = 0;
          } else {
            currentPerc = (10000 / totalCount) * 100;
          }
          progressPerc =
            parseInt(progressPerc + currentPerc) > 100
              ? 100
              : parseInt(progressPerc + currentPerc);

          handlePercentChange(progressPerc);

          //cnt = cnt - 1;
          if (chunkCount === 0) {
            shouldContinue = false;
          } else if (chunkCount > 0 && chunkCount < 10000) {
            dataList.push(manualFeedDataList);
            shouldContinue = false;
          } else {
            dataList.push(manualFeedDataList);
          }
        } else {
          shouldContinue = false;
        }
      }

      let parentList = [];
      if (dataList && dataList.length > 0) {
        // Iterate over each sublist in dataList and push objects into parentList
        for (const key in dataList) {
          if (dataList.hasOwnProperty(key)) {
            parentList.push(...dataList[key]);
          }
        }
        const temp = parentList.map((item) => {
          const obj = {};
          for (const key in item) {
            obj[key] = item[key];
          }
          return obj;
        });

        setmanualFeedData(temp);
        setmanualfeedCount(parentList.length + 1);
      }

      // const response = await fetchAllocationManualFeed(parameter, token);

      // if (
      //   response?.data?._result &&
      //   response?.data?._code === 200 &&
      //   !response?.data?._isError
      // ) {
      //   const { manualFeedData, count } = response?.data?._result;
      //   const temp = manualFeedData.map((item) => {
      //     const obj = {};
      //     for (const key in item) {
      //       obj[key] = item[key];
      //     }
      //     return obj;
      //   });

      //   setmanualFeedData(temp);
      //   setmanualfeedCount(count);
      // }
    } catch (error) {
      console.error("Error:", error);
      // Handle error as needed
    }
    setbackdropopen(false);
  };

  // Custom Filter logic , for any further change in date-time filter
  const onDetailsUpdateFetchLatestEventsData = async (dataFromChild) => {
    fetchAllocationDataOnloadData(dataFromChild);
  };

  // Custom Filter logic , for any further change in date-time filter
  const onDetailsUpdateFetchLatestManualEventsData = async (dataFromChild) => {
    fetchAllocationManualFeedData(dataFromChild);
  };

  // Custom Filter logic , for any further change in date-time filter
  const onDateSelectionChange = async (dataFromChild) => {
    let parameters = {
      ...filterParameters,
    };
    parameters = {
      ...parameters,
      startDate: dataFromChild.startDate,
      endDate: dataFromChild.endDate,
      startTime: dataFromChild.startTime,
      endTime: dataFromChild.endTime,
      location: dataFromChild.location,
      isAllDay: dataFromChild.isAllDay,
      startLimit: 0,
    };
    setFilterParameters({
      ...filterParameters,
      startDate: dataFromChild.startDate,
      endDate: dataFromChild.endDate,
      startTime: dataFromChild.startTime,
      endTime: dataFromChild.endTime,
      location: dataFromChild.location,
      isAllDay: dataFromChild.isAllDay,
      startLimit: 0,
    });
    dispatch(setParameters({ ...parameters }));
    dispatch(setParameters2({ ...parameters }));

    if (tabindex === 0) {
      await fetchAllocationDataOnloadData(parameters);
    } else {
      await fetchAllocationManualFeedData(parameters);
    }
    // await fetchOverviewDataByFilters(parameters);
  };

  const fetchAllocationfilters = async () => {
    try {
      const token = localStorage.getItem("idToken");
      const response = await fetchAllocationDropdownList(token);
      if (
        response?.data?._result &&
        response?.data?._code === 200 &&
        !response?.data?._isError
      ) {
        setguestList(response?.data?._result?.personList);
        seteventList(response?.data?._result?.eventList);
        setuserClassList(response?.data?._result?.userClassList);
        setspecialStatusList(response?.data?._result?.specialStatusList);
      } else {
      }
    } catch (error) {
      console.error("Error in fetching data : ", error);
    }
  };

  // Fetching GuestDetails , EventDetails , specialstatus and userclass
  useEffect(() => {
    fetchAllocationfilters();
  }, []);
  // console.log(progressPercentage, "total render")
  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      {/* Custom search component  */}

      <SubHeaderFilter
        showDateTime={tabindex === 0 ? true : false}
        onDateSelectionChange={onDateSelectionChange}
        filterParameters={filterParameters}
        eventTypeListData={eventTypeList}
        isAutoRefresh={isAutoRefresh}
      />

      {/* Tabs Stack */}
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={1}
        sx={{ borderBottom: 1, borderColor: "divider" }}
      >
        <Grid item>
          <Box>
            <Tabs
              value={tabindex}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Orchestrate" {...a11yProps(0)} />
              <Tab label="Manual Feed" {...a11yProps(1)} />
            </Tabs>
          </Box>
        </Grid>
      </Stack>

      <CustomTabPanel value={tabindex} index={0}>
        <AllocationEvent
          filterParameters={filterParameters}
          feedCount={feedCount}
          feedData={feedData}
          setFeedData={setFeedData}
          onDetailsUpdateFetchLatestEventsData={
            onDetailsUpdateFetchLatestEventsData
          }
          backdropopen={backdropopen}
          setbackdropopen={setbackdropopen}
          // dropdownliast data
          guestList={guestList}
          eventList={eventList}
          userClassList={userClassList}
          specialStatusList={specialStatusList}
          progressPercentage={progressPercentage}
        />
      </CustomTabPanel>
      <CustomTabPanel value={tabindex} index={1}>
        <ManualFeed
          manualFeedData={manualFeedData}
          manualfeedCount={manualfeedCount}
          setmanualFeedData={setmanualFeedData}
          filterParameters={filterParameters}
          onDetailsUpdateFetchLatestManualEventsData={
            onDetailsUpdateFetchLatestManualEventsData
          }
          backdropopen={backdropopen}
          setbackdropopen={setbackdropopen}
          // dropdownliast data
          guestList={guestList}
          eventList={eventList}
          userClassList={userClassList}
          specialStatusList={specialStatusList}
          progressPercentage={progressPercentage}
        />
      </CustomTabPanel>

      <Snackbar
        open={snackOpen}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackMessage.severity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackMessage.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Allocation;
