import React, { useState, useEffect } from "react";
import { Button, Grid, Stack, Snackbar, Alert } from "@mui/material";
import { exportToCSV } from "../../utils/exportCSV";
import { postAllocationFeed } from "../../utils/parentservices";
import {
  fetchAllocationEventFeed,
  fetchEmergencyReport,
  fetchConflictsReport,
} from "../../utils/parentservices";

import {
  convertDBDateTime,
  generateHeadercolumns,
  validationUIEditData,
} from "../../utils/utils";
import BackDrop from "../common/BackDrop";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import TabularDataEdit from "../common/TabularDataEdit";
import ProgressBackdrop from "../common/ProgressBackdrop";

const AllocationEvent = ({
  filterParameters,
  feedCount,
  feedData,
  setFeedData,
  onDetailsUpdateFetchLatestEventsData,
  backdropopen,
  setbackdropopen,
  guestList,
  eventList,
  userClassList,
  specialStatusList,
  progressPercentage,
}) => {
  const [rowModesModel, setRowModesModel] = React.useState({});
  const [errorRecords, seterrorRecords] = useState([]);
  //const [rows, setRows] = useState([...feedData]);
  const [snackOpen, setsnackOpen] = useState(false);
  const [snackMessage, setsnackMessage] = useState({
    message: "",
    severity: "success",
  });

  const [finalSubmission, setfinalSubmission] = useState(false);
  const [savedEditedRows, setsavedEditedRows] = useState([]);

  let columnHeader = [];
  const isEditable = true;
  const list = generateHeadercolumns(
    feedData,
    isEditable,
    guestList,
    eventList,
    userClassList,
    specialStatusList
  );
  columnHeader.push(...list);

  const [selectRows, setSelectedRows] = useState([]);

  const [selectRowsCSV, setSelectedRowsSCV] = useState([]);

  const onClickExportCSV = (clickType) => {
    if (clickType === "exportData") {
      if (selectRowsCSV.length <= 0) {
        setsnackOpen(true);
        setsnackMessage({
          message: "Please Select atleast 1 Row",
          severity: "info",
        });
      } else {
        let exportPromise = new Promise((resolve, reject) => {
          resolve(
            exportToCSV(
              selectRowsCSV,
              columnHeader,
              "allocation_export_summary_"
            )
          );
        });
        exportPromise
          .then((res) => {
            setTimeout(async function () {
              setsnackOpen(true);
              setsnackMessage({
                message: "Export has started, Please check the browser.",
                severity: "success",
              });
            }, 1000);
          })
          .catch((err) => {});
      }
    }

    if (clickType === "errorData") {
      const colHeader = columnHeader.concat([
        {
          field: "error_message",
          headerName: "error_message",
          width: 150,
          editable: "true",
        },
      ]);

      exportToCSV(errorRecords, colHeader, "import_error_");
      setsnackOpen(true);
      setsnackMessage({
        message: "Incorrect Import records exported!!",
        severity: "success",
      });
      seterrorRecords([]);
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setsnackOpen(false);
  };
  const processSavedRowUpdate = async (newRows) => {
    const validated_data = [];

    newRows.map((item) => {
      item.itineraryDate = convertDBDateTime(item);

      const data = validationUIEditData(
        [item],
        localStorage.getItem("userName")
      );

      const temp = data[0];
      const errorrows = data[1];
      seterrorRecords((prev) => [...prev, ...errorrows]);
      validated_data.push(...temp);
    });

    if (validated_data.length <= 0) {
      setsnackOpen(true);
      setsnackMessage({
        message: "Please check error logs!!",
        severity: "error",
      });
      return handleProcessRowUpdateError("Please check error logs!!");
    }
    const token = localStorage.getItem("idToken");
    const response = await postAllocationFeed(validated_data, token);

    if (
      response?.data?._result &&
      response?.data?._code === 200 &&
      !response?.data?._isError
    ) {
      //

      const resp = response?.data?._result;

      // if you have right privaligies
      if (resp && resp.statusCode && resp.success) {
        setsnackOpen(true);
        setsnackMessage({
          message: "Details Updated successfully!!",
          severity: "success",
        });
      }

      // No right privaligies
      else if (resp && resp.statusCode && resp.success === false) {
        setsnackOpen(true);
        setsnackMessage({
          message: resp?.message ? resp.message : "Error!!",
          severity: "error",
        });
      }
    } else {
      setsnackOpen(true);
      setsnackMessage({
        message: response?._message,
        severity: "error",
      });
    }

    onDetailsUpdateFetchLatestEventsData(filterParameters); // Call the function on component mount
    setsavedEditedRows([]);
  };

  const refreshGridData = async () => {
    onDetailsUpdateFetchLatestEventsData(filterParameters);
  };

  const onClickConflictsReport = async () => {
    const parameter = {
      inputParam: {
        ...filterParameters,
      },
    };
    const token = localStorage.getItem("idToken");
    const response = await fetchConflictsReport(parameter, token);
    if (
      response?.data?._result &&
      response?.data?._code === 200 &&
      !response?.data?._isError
    ) {
      if (response?.data?._result.count > 0) {
        const resp = response?.data?._result;

        if (resp && resp.statusCode && resp.success) {
          let exportPromise = new Promise((resolve, reject) => {
            resolve(
              exportToCSV(
                resp.manualFeedData,
                columnHeader,
                "allocation_conflicts_report_"
              )
            );
          });
          exportPromise
            .then((res) => {
              setTimeout(async function () {
                setsnackOpen(true);
                setsnackMessage({
                  message: "Export has started, Please check the browser.",
                  severity: "success",
                });
              }, 1000);
            })
            .catch((err) => {});
        }
        // No right privaligies
        else if (resp && resp.statusCode && resp.success === false) {
          setsnackOpen(true);
          setsnackMessage({
            message: resp?.message ? resp.message : "Error!",
            severity: "error",
          });
        }
      } else {
        setsnackOpen(true);
        setsnackMessage({
          message: "No data found!!",
          severity: "info",
        });
      }
    } else {
      setsnackOpen(true);
      setsnackMessage({
        message: "Something went wrong!!",
        severity: "error",
      });
    }
  };

  const onClickEmergencyReport = async () => {
    const columnHeaderForEmergency = [
      {
        field: "personID",
        headerName: "personID",
        sortable: true,
        width: 150,
        editable: false,
      },
    ];
    //("Selected rows ->> ", selectRowsCSV);

    if (selectRowsCSV.length <= 0) {
      setsnackOpen(true);
      setsnackMessage({
        message: "Please Select atleast 1 Row",
        severity: "info",
      });
    } else {
      // Create a Set of unique personId values
      const uniquePersonIdsSet = new Set(
        selectRowsCSV.map((item) => item.personID)
      );

      // Convert the Set to an array of objects with personId property
      const uniquePersonIds = Array.from(uniquePersonIdsSet).map((id) => ({
        personID: id,
      }));

      //console.log("uniquePersonIds", uniquePersonIds, columnHeaderForEmergency);

      let exportPromise = new Promise((resolve, reject) => {
        resolve(
          exportToCSV(
            uniquePersonIds,
            columnHeaderForEmergency,
            "allocation_emergency_report_"
          )
        );
      });
      exportPromise
        .then((res) => {
          setTimeout(async function () {
            setsnackOpen(true);
            setsnackMessage({
              message: "Export has started, Please check the browser.",
              severity: "success",
            });
          }, 1000);
        })
        .catch((err) => {});
    }
    // const parameter = {
    //   inputParam: {
    //     ...filterParameters,
    //   },
    // };
    // const response = await fetchEmergencyReport(parameter);
    // if (
    //   response?.data?._result &&
    //   response?.data?._code === 200 &&
    //   !response?.data?._isError &&
    //   response?.data?._result.count > 0
    // ) {
    //   const { manualFeedData, count } = response?.data?._result;
    //   exportToEmergencyCSV(manualFeedData);
    //   setsnackOpen(true);
    //   setsnackMessage({
    //     message: "Export has started, Please check the browser.",
    //     severity: "success",
    //   });
    // } else {
    //   setsnackOpen(true);
    //   setsnackMessage({
    //     message: "Something went wrong!",
    //     severity: "error",
    //   });
    // }
  };

  const handleProcessRowUpdateError = React.useCallback((error) => {
    console.log("error", error);
  }, []);

  return (
    <Grid
      container
      spacing={2}
      direction="column"
      sx={{ width: "100%", height: "100%" }}
    >
      <Grid
        item
        sx={{
          marginTop: "10px",
          width: "100%",
        }}
      >
        <Stack direction="row" spacing={2} justifyContent={"flex-end"}>
          {localStorage.getItem("userRole") ===
          process.env.REACT_APP_ADMIN_POOL_NAME ? (
            <Button
              variant="contained"
              className="primary"
              onClick={onClickConflictsReport}
            >
              Conflicts Report
            </Button>
          ) : null}

          {/* <Button
            variant="contained"
            className="primary"
            onClick={() => onClickExportCSV("exportData")}
          >
            {`Export CSV`}
          </Button> */}

          {/* {localStorage.getItem("userRole") ===
          process.env.REACT_APP_ADMIN_POOL_NAME ? (
            <Button
              variant="contained"
              className="primary"
              onClick={onClickEmergencyReport}
            >
              {`Emergency Export`}
            </Button>
          ) : null} */}

          {errorRecords.length > 0 ? (
            <DownloadForOfflineIcon
              style={{ color: "red", cursor: "pointer", fontSize: "30" }}
              onClick={() => onClickExportCSV("errorData")}
            />
          ) : null}
        </Stack>
      </Grid>
      <Grid item sx={{ flexGrow: 1, width: "-webkit-fill-available" }}>
        <TabularDataEdit
          rows={feedData}
          setRows={setFeedData}
          columns={columnHeader}
          selectedRows={selectRows}
          setSelectedRows={setSelectedRows}
          selectRowsCSV={selectRowsCSV}
          setSelectedRowsSCV={setSelectedRowsSCV}
          isGirdEditable={true}
          rowModesModel={rowModesModel}
          setRowModesModel={setRowModesModel}
          handleProcessRowUpdateError={handleProcessRowUpdateError}
          finalSubmission={finalSubmission}
          setfinalSubmission={setfinalSubmission}
          processSavedRowUpdate={processSavedRowUpdate}
          savedEditedRows={savedEditedRows}
          setsavedEditedRows={setsavedEditedRows}
          refreshGridData={refreshGridData}
          // allocation drop down filters
          guestList={guestList}
          eventList={eventList}
          userClassList={userClassList}
          specialStatusList={specialStatusList}
          columnHeader={columnHeader}
          setsnackOpen={setsnackOpen}
          setsnackMessage={setsnackMessage}
        />
      </Grid>
      <Snackbar
        open={snackOpen}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackMessage.severity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackMessage.message}
        </Alert>
      </Snackbar>

      {/* backdrop */}
      {/* <BackDrop backdropopen={backdropopen} /> */}
      <ProgressBackdrop
        progressPercentage={progressPercentage}
        backdropopen={backdropopen}
      />
    </Grid>
  );
};

export default AllocationEvent;
