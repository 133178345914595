import React, { useState, useEffect } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { styled, useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { Route, Routes, useLocation, Navigate } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import Navbar from "./Navbar";
import Overview from "../../pages/Overview";
import EventSchedule from "../../pages/EventSchedule";
import Allocation from "../../pages/Allocation";
import { Button, Stack, Toolbar, Drawer, Box } from "@mui/material";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import PrivateRoutes from "../../utils/PrivateRoutes";
import UserAuthentication from "../../pages/UserAuthentication";
import SignupUser from "../UserAuthentication/SignupUser";
import ForgotPassword from "../UserAuthentication/ForgotPassword";
import LoginUser from "../UserAuthentication/LoginUser";
import AdminDashboard from "../../pages/AdminDashboard";

const drawerWidth = 200;
const miniDrawerWidth = 56; // Width for the mini variant

export default function MainLayout({ pages }) {
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));
  const [open, setOpen] = useState(false);

  let showPages = [];

  showPages =
    pages[
      localStorage &&
      localStorage.getItem("userRole") &&
      localStorage.getItem("userName")
        ? localStorage.getItem("userRole")
        : "Onsite-Team"
    ];
  let activeTab = location.pathname;

  useEffect(() => {
    const checkLoginTime = () => {
      const loginTime = localStorage.getItem("loginDateTime");

      if (loginTime) {
        const loginDate = new Date(loginTime);
        const currentDate = new Date();
        const diffInMinutes = Math.floor((currentDate - loginDate) / 60000);

        if (diffInMinutes > 238) {
          // Logout the user
          // localStorage.removeItem("accessToken");
          localStorage.removeItem("idToken");
          // localStorage.removeItem("refreshToken");
          localStorage.removeItem("userRole");
          localStorage.removeItem("userName");
          localStorage.removeItem("_grecaptcha");
          localStorage.removeItem("loginDateTime"); // or any other auth info
          navigate("/login"); // Redirect to login page
        }
      }
    };

    // Check on initial load
    checkLoginTime();

    // Set interval to check every minute
    const intervalId = setInterval(checkLoginTime, 60000);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, [navigate]);

  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      width: `calc(100%)`,
      marginLeft: `${drawerWidth}px`,
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));

  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  }));

  const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
    ({ theme, open }) => ({
      flexGrow: 1,
      padding: theme.spacing(3),
      // width: "100%",
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      // marginLeft: `-${drawerWidth}px`,
      marginLeft: open ? `-${drawerWidth}px` : `-10px`,
      width: `calc(100% - ${open ? drawerWidth : miniDrawerWidth}px)`,
      ...(open && {
        transition: theme.transitions.create("margin", {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: "-10px",
      }),
    })
  );

  // handle drawer open/close
  const handleDrawerOpen = () => {
    if (!localStorage || !localStorage.getItem("userName")) {
      setOpen(false);
      return;
    }
    setOpen(isLargeScreen);
    // setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <>
      {localStorage && localStorage.getItem("userName") ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            height: "100%",
            width: "100%",
            margin: 0,
          }}
        >
          <CssBaseline />

          <AppBar position="fixed" open={open} id="main-app-bar">
            <Toolbar>
              <Navbar drawerWidth={drawerWidth} navigate={navigate} />
            </Toolbar>
          </AppBar>

          <Drawer
            sx={{
              width: open ? drawerWidth : miniDrawerWidth,
              flexShrink: 0,
              whiteSpace: "nowrap",
              marginTop: "65px",
              "& .MuiDrawer-paper": {
                width: open ? drawerWidth : miniDrawerWidth,
                boxSizing: "border-box",
                backgroundColor: "#2A2E69",
                overflowX: "hidden",
                marginTop: "65px",
                transition: theme.transitions.create("width", {
                  easing: theme.transitions.easing.sharp,
                  duration: theme.transitions.duration.enteringScreen,
                }),
              },
            }}
            variant="permanent" // Change to permanent for mini variant
            open={open}
            id="main-left-drawer"
          >
            <DrawerHeader>
              <IconButton onClick={open ? handleDrawerClose : handleDrawerOpen}>
                {open ? (
                  theme.direction === "ltr" ? (
                    <ChevronLeftIcon sx={{ color: "whitesmoke" }} />
                  ) : (
                    <ChevronRightIcon sx={{ color: "whitesmoke" }} />
                  )
                ) : (
                  <MenuIcon sx={{ color: "whitesmoke" }} />
                )}
              </IconButton>
            </DrawerHeader>

            <Stack spacing={3} direction="column" sx={{ marginTop: "20px" }}>
              {showPages.map((page, index) => (
                <Stack direction="row" spacing={2}>
                  <Button
                    key={index}
                    variant="contained"
                    className="secondary"
                    startIcon={
                      <img
                        src={page.icon}
                        alt={page.name}
                        style={{ width: "24px", height: "24px" }}
                      />
                    }
                    onClick={() => {
                      navigate(page.path);
                      activeTab = page.path;
                    }}
                    sx={{
                      backgroundColor:
                        activeTab === page.path ? "#F7B600" : "#1A1F71",
                      width: "100%",
                      color: activeTab === page.path ? "black" : "white",
                      justifyContent: open ? "flex-start" : "center",
                    }}
                  >
                    {open && page.pageName}
                  </Button>
                </Stack>
              ))}
            </Stack>
          </Drawer>

          {/* Main content component */}

          <Main
            open={open}
            id="main-routes-component"
            sx={{ minHeight: "10vh !important", paddingTop: "16px !important" }}
          >
            {/* sx={{ flexGrow: 1 }} */}
            <DrawerHeader />

            <Routes>
              {/* Protected by UserLogin */}
              <Route element={<PrivateRoutes />}>
                <Route
                  key="overview-componenet"
                  path="/"
                  exact
                  element={<Overview />}
                />
                <Route
                  key="event-componenet"
                  path="/event-schedule"
                  element={<EventSchedule />}
                />
                <Route
                  key="allocation-componenet"
                  path="/allocation"
                  element={<Allocation />}
                />
                <Route
                  key="dashboard"
                  path="/dashboard"
                  element={
                    localStorage.getItem("userRole") === "Command-Team" ? (
                      <AdminDashboard />
                    ) : (
                      <Navigate to="/" />
                    )
                  }
                />

                <Route
                  key="login"
                  path="/login"
                  element={
                    localStorage && localStorage.getItem("userName") ? (
                      <Navigate to="/" />
                    ) : (
                      <Navigate to="/login" />
                    )
                  }
                />
              </Route>
            </Routes>
          </Main>
        </Box>
      ) : (
        <Box sx={{ display: "flex", flexDirection: "row", height: "100vh" }}>
          <Routes>
            {/* Add the login route */}

            <Route
              path="/login"
              element={<UserAuthentication loginComponent={LoginUser} />}
            />
            <Route
              path="/signup"
              element={<UserAuthentication signupComponent={SignupUser} />}
            />
            <Route
              path="/forgot-password"
              element={
                <UserAuthentication forgotPasswordComponent={ForgotPassword} />
              }
            />

            {/* Protected by UserLogin */}
            <Route element={<PrivateRoutes />}>
              <Route
                key="overview-componenet"
                path="/"
                exact
                element={<Overview />}
              />
              <Route
                key="event-componenet"
                path="/event-schedule"
                element={<EventSchedule />}
              />
              <Route
                key="allocation-componenet"
                path="/allocation"
                element={<Allocation />}
              />

              <Route
                key="dashboard"
                path="/dashboard"
                element={
                  (localStorage && localStorage.getItem("userRole")) ===
                  "Command-Team" ? (
                    <AdminDashboard />
                  ) : (
                    <Navigate to="/" />
                  )
                }
              />
            </Route>
          </Routes>
        </Box>
      )}
    </>
  );
}
