// redux/parametersSlice.js
import { createSlice } from "@reduxjs/toolkit";

const parametersSlice = createSlice({
  name: "parameters",
  initialState: null,
  reducers: {
    setParameters: (state, action) => {
      state = action.payload;
      return action.payload;
    },
  },
});

export const { setParameters } = parametersSlice.actions;

export default parametersSlice.reducer;
